import { instance } from ".";

export const getAllForms = async () => {
  try {
    const response = await instance.get("form/all");
    console.log(response.status);
    if (response.status === 200) {
      return response.data.data;
    }
  } catch (error) {
    console.log(error);
  }
};
export const addQuestion = async (questionData, headingData) => {
  const body = {
    formName: headingData.title.value,
    formDescription: headingData.description.value,
    questions: questionData.map((item) => ({
      questionText: item.questionText,
      questionDescription: "null",
      questionType: item.questionType,
      options: item.questionOptions,
      isRequired: item.isRequired,
    })),
  };
  try {
    const response = await instance.post("question", body);
    console.log(response.status);
    if (response.data.status) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getQuestionsById = async (id) => {
  try {
    const response = await instance.get(`questionByFormId/${id}`);
    if (response.status === 200) {
      return response.data.data;
    }
  } catch (error) {
    console.log(error);
  }
};
