import { Button, Collapse, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, useTheme } from "@mui/material";
import { NavMenuItems, NavMenuRendererProps, Role, Platform } from "./types";
import {
  SwitchAccount,
  ExpandMore,
  ExpandLess,
  Circle,
  Star,
  Business,
  Handshake,
  Dashboard,
  Inbox,
  Send,
  StarOutline,
  InsertDriveFile,
  ScheduleSend,
  Delete,
  ReportGmailerrorred,
  LabelImportant,
  School,
  Lock,
  BusinessCenter,
  Api,
  // SnippetFolder,
  Home,
  DesignServices,
  InsertChartOutlined,
  Explore,
} from "@mui/icons-material";
import FeedIcon from "../../assets/icon-components/FeedIcon";
import CommandIcon from "../../assets/icon-components/CommandIcon";
import AdminIcon from "../../assets/icon-components/AdminIcon";
import CodeIcon from '@mui/icons-material/Code';
import BadgeIcon from '@mui/icons-material/Badge';
import { useState, useMemo, useRef, useEffect } from "react";
import { useAuthContext } from "../../contexts";
import {
  REACT_APP_VN_ADMIN_URL,
  REACT_APP_VN_MEDIAKIT_URL,
  REACT_APP_VN_PLATFORM_URL,
  REACT_APP_VN_SECURE_URL,
  REACT_APP_VN_TALENT_URL,
  REACT_APP_DOMAIN_ID,
} from "../../utilities/constants";
import { useLocation, useNavigate } from "react-router-dom";
import HandleAudit from "../../assets/icon-components/HandleAudit";
import { getReferrerContext } from "../../utilities";
import { useCreatorPlusContext } from "../../contexts/createPlus-context";
import CreatorPlusIcon from "../../assets/icon-components/CreatorPlusIcon";
import VNLogo from "../../assets/icon-components/VNLogo";
// import CustomTooltip from "../CustomTooltip";

// function checkMenuTitleOverflow(title: string): boolean {
//   try {
//     return !isOverflowing(
//       document.evaluate(`//span[text()= "${title}"]`, document.querySelector("#root")!, null, XPathResult.ANY_TYPE, null).iterateNext()! as HTMLElement
//     );
//   } catch (error) {
//     return true;
//   }
// }

const NavMenuRenderer = (props: NavMenuRendererProps): JSX.Element => {
  const [activeMenu, setActiveMenu] = useState<number | null>(null);
  const [activeSubMenu, setActiveSubMenu] = useState<number | null>(null);
  const currentMenuRouteIndexMapRef = useRef<Array<Record<string, any>>>([]);
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    authState: { user_id, auth_key, redirect_side_menu, role_id },
    authDispatch,
  } = useAuthContext();
  const { creatorPlusDispatch, creatorPlusState } = useCreatorPlusContext();
  const [role, setRole] = useState<number>(5);
  /**
   * `DOMAIN_ID - Platform` mapping for reference:
   *
   * `1 - Secure`
   * `2 - Plus`
   * `3 - Talent`
   * `4 - Admin`
   * `5 - Settings`
   * `6 - Vmessenger`
   * `7 - Mediakits`
   * `8 - AI-Toolbox`
   * `9 - Forms`
   *
   */
  const MenuMap: Readonly<Record<string, NavMenuItems>> = useMemo(
    () => ({
      // Secure Menus
      1: [
        {
          title: "Feed",
          icon: <FeedIcon />,
          workspaceVisibility: [1],
          roleVisibility: [7, 5],
          platformVisibility: ["2", "3", "4", "5", "6", "7"],
          subMenus: [
            { title: "Home", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/feed/home` },
            { title: "Collaborations", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/feed/collaborations` },
            { title: "User Profile", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/feed/profile` },
          ],
        },
        {
          title: "Academy",
          icon: <School />,
          workspaceVisibility: [1],
          roleVisibility: [7, 5],
          platformVisibility: ["2", "3", "4", "5", "6", "7"],
          subMenus: [
            { title: "Explore", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/academy/dashboard` },
            { title: "My List", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/academy/myList` },
            { title: "Library", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/academy/library ` },
          ],
        },
        {
          title: "Command",
          icon: <CommandIcon />,
          workspaceVisibility: [1],
          roleVisibility: [7, 5],
          platformVisibility: ["2", "3", "4", "5", "6", "7"],
          subMenus: [
            { title: "Analytics", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/command/analytics` },
            { title: "Scheduler", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/command/scheduler` },
            { title: "Engage", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/command/engage` },
          ],
        },
        {
          title: "Secure",
          icon: <Lock />,
          workspaceVisibility: [1],
          roleVisibility: [7, 5],
          subMenus: [
            { title: "Overview", route: "/overview" },
            { title: "Data Vault", route: "/secure/vault" },
          ],
        },
        {
          title: "Secure",
          icon: <Lock />,
          workspaceVisibility: [2],
          roleVisibility: [7, 5],
          subMenus: [
            { title: "Profiles", route: "/allprofiles" },
            { title: "Secure Templates", route: "/templates" },
          ],
        },
        {
          title: "Admin",
          icon: <AdminIcon />,
          workspaceVisibility: [1, 2],
          roleVisibility: [5],
          subMenus: [
            { title: "Platform", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/` },
            { title: "Academy", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/academy` },
            { title: "Communities", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/communities` },
            // { title: "Command", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/command` },
            { title: "Secure", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/secure` },
            { title: "Error Logs", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/error-logs` },
            { title: "User Roles", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/user-roles` },
            { title: "System Settings", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/system-settings` },
            // { title: "Widgets", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/widgets` },
            { title: "Global Tables", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/global-tables` },
            { title: "Page Interest", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/page-interest` },
            { title: "API Health Check", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/api-status` },
          ],
        },
      ],
      // Plus Menus
      2: [
        {
          title: "Home",
          icon: <Home />,
          workspaceVisibility: [1, 2],
          roleVisibility: [5],
          subMenus: [
            { title: "Home", route: "/feed/home" },
            { title: "Collaborations", route: "/feed/collaborations" },
            { title: "User Profile", route: "/feed/Profile" },
          ],
        },
        {
          title: "Home",
          icon: <Home />,
          workspaceVisibility: [1, 2],
          roleVisibility: [7],
          subMenus: [{ title: "Home", route: "/creator/Profile" }],
        },
        {
          title: "Business",
          icon: <BusinessCenter />,
          workspaceVisibility: [1, 2],
          roleVisibility: [5],
          subMenus: [],
        },
        {
          title: "Academy",
          icon: <School />,
          workspaceVisibility: [1, 2],
          roleVisibility: [5],
          subMenus: [
            { title: "Explore", route: "/academy/dashboard" },
            { title: "My List", route: "/academy/myList" },
            { title: "Library", route: "/academy/library" },
          ],
        },
        {
          title: "Discover",
          icon: <Explore />,
          workspaceVisibility: [1, 2],
          roleVisibility: [5],
          subMenus: [
            { title: "Users", route: "/discover/users" },
            { title: "News", route: "/discover/news" },
            { title: "Community", route: "/discover/community" },
          ],
        },
        {
          title: "Toolbox",
          icon: <DesignServices />,
          workspaceVisibility: [1, 2],
          roleVisibility: [5],
          subMenus: [
            { title: "Analytics", route: "/command/analytics" },
            { title: "Scheduler", route: "/command/scheduler" },
            { title: "Engage", route: "/command/engage" },
            { title: "Listen", route: "/command/listen" },
            { title: "Media kit", route: `${REACT_APP_VN_MEDIAKIT_URL}/${auth_key}/${user_id}` },
          ],
        },
        {
          title: "Accreditation New",
          route: "/AccreditationNew",
          workspaceVisibility: [1, 2],
          roleVisibility: [7],
          icon: <Home />,
        },
        {
          title: "Accreditation",
          route: "/Accreditation",
          workspaceVisibility: [1, 2],
          roleVisibility: [7],
          icon: <Home />,
        },
        {
          title: "Secure",
          icon: <Lock />,
          workspaceVisibility: [1],
          roleVisibility: [7, 5],
          subMenus: [
            { title: "Overview", route: `${REACT_APP_VN_SECURE_URL}/${auth_key}/${user_id}/overview` },
            { title: "Data Vault", route: `${REACT_APP_VN_SECURE_URL}/${auth_key}/${user_id}/secure/vault` },
          ],
        },
        {
          title: "Secure",
          icon: <Lock />,
          workspaceVisibility: [2],
          roleVisibility: [7, 5],
          subMenus: [
            { title: "Profiles", route: `${REACT_APP_VN_SECURE_URL}/${auth_key}/${user_id}/allprofiles` },
            { title: "Secure Templates", route: `${REACT_APP_VN_SECURE_URL}/${auth_key}/${user_id}/templates` },
          ],
        },
        {
          title: "Admin",
          icon: <AdminIcon />,
          workspaceVisibility: [1, 2],
          roleVisibility: [7, 5],
          subMenus: [
            { title: "Platform", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/` },
            { title: "Academy", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/academy` },
            { title: "Communities", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/communities` },
            // { title: "Command", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/command` },
            { title: "Secure", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/secure` },
            { title: "Error Logs", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/error-logs` },
            { title: "User Roles", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/user-roles` },
            { title: "System Settings", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/system-settings` },
            // { title: "Widgets", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/widgets` },
            { title: "Global Tables", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/global-tables` },
            { title: "Page Interest", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/page-interest` },
            { title: "API Health Check", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/api-status` },
          ],
        },
      ],
      // Talent Menus
      3: [
        {
          title: "Dashboard",
          route: "/",
          workspaceVisibility: [1, 2],
          roleVisibility: [7, 5],
          icon: <Dashboard width={20} height={30} htmlColor={"gray"} />,
        },
        {
          title: "Talent Management",
          route: "/talent-management",
          workspaceVisibility: [1, 2],
          roleVisibility: [7, 5],
          icon: <Star width={20} height={30} htmlColor={"gray"} />,
        },
        {
          title: "Buyer Management",
          route: "/buyer-management",
          workspaceVisibility: [1, 2],
          roleVisibility: [7, 5],
          icon: <SwitchAccount width={20} height={30} htmlColor={"gray"} />,
        },
        {
          title: "Deals",
          icon: <Handshake width={20} height={30} htmlColor={"grey"} />,
          workspaceVisibility: [1, 2],
          roleVisibility: [7, 5],
          subMenus: [
            { title: "Leads", route: "/deals/leads" },
            { title: "Pipeline", route: "/deals/pipeline" },
          ],
        },
        {
          title: "Teams",
          icon: <Business width={20} height={30} htmlColor={"grey"} />,
          workspaceVisibility: [1, 2],
          roleVisibility: [7, 5],
          subMenus: [
            { title: "Agent Profiles", route: "/teams/agent-profiles" },
            { title: "Resources", route: "/teams/resources" },
            { title: "Webforms", route: "/teams/webforms" },
          ],
        },
        {
          title: "Secure",
          icon: <Lock />,
          workspaceVisibility: [1],
          roleVisibility: [7, 5],
          subMenus: [
            { title: "Overview", route: `${REACT_APP_VN_SECURE_URL}/${auth_key}/${user_id}/overview` },
            { title: "Data Vault", route: `${REACT_APP_VN_SECURE_URL}/${auth_key}/${user_id}/secure/vault` },
          ],
        },
        {
          title: "Secure",
          icon: <Lock />,
          workspaceVisibility: [2],
          roleVisibility: [7, 5],
          subMenus: [
            { title: "Profiles", route: `${REACT_APP_VN_SECURE_URL}/${auth_key}/${user_id}/allprofiles` },
            { title: "Secure Templates", route: `${REACT_APP_VN_SECURE_URL}/${auth_key}/${user_id}/templates` },
          ],
        },
        {
          title: "Admin",
          icon: <AdminIcon />,
          workspaceVisibility: [1, 2],
          roleVisibility: [5],
          subMenus: [
            { title: "Platform", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/` },
            { title: "Academy", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/academy` },
            { title: "Communities", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/communities` },
            // { title: "Command", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/command` },
            { title: "Secure", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/secure` },
            { title: "Error Logs", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/error-logs` },
            { title: "User Roles", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/user-roles` },
            { title: "System Settings", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/system-settings` },
            // { title: "Widgets", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/widgets` },
            { title: "Global Tables", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/global-tables` },
            { title: "Page Interest", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/page-interest` },
            { title: "API Health Check", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/api-status` },
          ],
        },
      ],
      // Admin Menus
      4: [
        {
          title: "Feed",
          icon: <FeedIcon />,
          workspaceVisibility: [1],
          roleVisibility: [7, 5],
          subMenus: [
            { title: "Home", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/feed/home` },
            { title: "Collaborations", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/feed/collaborations` },
            { title: "User Profile", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/feed/profile` },
          ],
        },
        {
          title: "Academy",
          icon: <School />,
          workspaceVisibility: [1],
          roleVisibility: [7, 5],
          subMenus: [
            { title: "Explore", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/academy/dashboard` },
            { title: "My List", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/academy/myList` },
            { title: "Library", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/academy/library ` },
          ],
        },
        {
          title: "Command",
          icon: <CommandIcon />,
          workspaceVisibility: [1],
          roleVisibility: [7, 5],
          subMenus: [
            { title: "Analytics", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/command/analytics` },
            { title: "Scheduler", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/command/scheduler` },
            { title: "Engage", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/command/engage` },
          ],
        },
        {
          title: "Secure",
          icon: <Lock />,
          workspaceVisibility: [1],
          roleVisibility: [7, 5],
          subMenus: [
            { title: "Overview", route: `${REACT_APP_VN_SECURE_URL}/${auth_key}/${user_id}/overview` },
            { title: "Data Vault", route: `${REACT_APP_VN_SECURE_URL}/${auth_key}/${user_id}/secure/vault` },
          ],
        },
        {
          title: "Secure",
          icon: <Lock />,
          workspaceVisibility: [2],
          roleVisibility: [7, 5],
          subMenus: [
            { title: "Profiles", route: `${REACT_APP_VN_SECURE_URL}/${auth_key}/${user_id}/allprofiles` },
            { title: "Secure Templates", route: `${REACT_APP_VN_SECURE_URL}/${auth_key}/${user_id}/templates` },
          ],
        },
        {
          title: "Admin",
          icon: <AdminIcon />,
          workspaceVisibility: [1, 2],
          roleVisibility: [5],
          subMenus: [
            { title: "Platform", route: "/" },
            { title: "Academy", route: "/academy" },
            { title: "Communities", route: "/communities" },
            // { title: "Command", route: "/command" },
            { title: "Secure", route: "/secure" },
            { title: "Error Logs", route: "/error-logs" },
            { title: "User Roles", route: "/user-roles" },
            { title: "System Settings", route: "/system-settings" },
            // { title: "Widgets", route: "/widgets" },
            { title: "Global Tables", route: "/global-tables" },
            { title: "Page Interest", route: "/page-interest" },
            { title: "API Health Check", route: "/api-status" },
          ],
        },
      ],
      // Settings Menus
      5: [
        {
          title: "Feed",
          icon: <FeedIcon />,
          workspaceVisibility: [1],
          roleVisibility: [7, 5],
          platformVisibility: ["2", "3", "4", "5", "6", "7"],
          subMenus: [
            { title: "Home", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/feed/home` },
            { title: "Collaborations", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/feed/collaborations` },
            { title: "User Profile", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/feed/profile` },
          ],
        },
        {
          title: "Academy",
          icon: <School />,
          workspaceVisibility: [1],
          roleVisibility: [7, 5],
          platformVisibility: ["2", "3", "4", "5", "6", "7"],
          subMenus: [
            { title: "Explore", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/academy/dashboard` },
            { title: "My List", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/academy/myList` },
            { title: "Library", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/academy/library ` },
          ],
        },
        {
          title: "Command",
          icon: <CommandIcon />,
          workspaceVisibility: [1],
          roleVisibility: [7, 5],
          platformVisibility: ["2", "3", "4", "5", "6", "7"],
          subMenus: [
            { title: "Analytics", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/command/analytics` },
            { title: "Scheduler", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/command/scheduler` },
            { title: "Engage", route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}/command/engage` },
          ],
        },
        {
          title: "Secure",
          icon: <Lock />,
          workspaceVisibility: [1],
          roleVisibility: [7, 5],
          subMenus: [
            { title: "Overview", route: `${REACT_APP_VN_SECURE_URL}/${auth_key}/${user_id}/overview` },
            { title: "Data Vault", route: `${REACT_APP_VN_SECURE_URL}/${auth_key}/${user_id}/secure/vault` },
          ],
        },
        {
          title: "Secure",
          icon: <Lock />,
          workspaceVisibility: [2],
          roleVisibility: [7, 5],
          subMenus: [
            { title: "Profiles", route: `${REACT_APP_VN_SECURE_URL}/${auth_key}/${user_id}/allprofiles` },
            { title: "Secure Templates", route: `${REACT_APP_VN_SECURE_URL}/${auth_key}/${user_id}/templates` },
          ],
        },
        {
          title: "Admin",
          icon: <AdminIcon />,
          workspaceVisibility: [1, 2],
          roleVisibility: [5],
          subMenus: [
            { title: "Platform", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/` },
            { title: "Academy", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/academy` },
            { title: "Communities", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/communities` },
            // { title: "Command", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/command` },
            { title: "Secure", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/secure` },
            { title: "Error Logs", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/error-logs` },
            { title: "User Roles", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/user-roles` },
            { title: "System Settings", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/system-settings` },
            // { title: "Widgets", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/widgets` },
            { title: "Global Tables", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/global-tables` },
            { title: "Page Interest", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/page-interest` },
            { title: "API Health Check", route: `${REACT_APP_VN_ADMIN_URL}/${auth_key}/${user_id}/api-status` },
          ],
        },
      ],
      // VMessenger Menus
      6: [
        { title: "Inbox", icon: <Inbox />, route: "/inbox", workspaceVisibility: [1], roleVisibility: [7, 5] },
        { title: "Sent", icon: <Send />, route: "/sent", workspaceVisibility: [1], roleVisibility: [7, 5] },
        { title: "Scheduled", icon: <ScheduleSend />, route: "/scheduled", workspaceVisibility: [1], roleVisibility: [7, 5] },
        { title: "Drafts", icon: <InsertDriveFile />, route: "/drafts", workspaceVisibility: [1], roleVisibility: [7, 5] },
        { title: "Trash", icon: <Delete />, route: "/trash", workspaceVisibility: [1], roleVisibility: [7, 5] },
        { title: "Starred", icon: <StarOutline />, route: "/starred", workspaceVisibility: [1], roleVisibility: [7, 5] },
        { title: "Spam", icon: <ReportGmailerrorred />, route: "/spam", workspaceVisibility: [1], roleVisibility: [7, 5] },
        { title: "Labels", icon: <LabelImportant />, route: "/labels", workspaceVisibility: [1], roleVisibility: [7, 5] },
      ],
      // MediaKits Menus
      7: [
        {
          title: "Home",
          route: "/",
          workspaceVisibility: [1, 2],
          roleVisibility: [7, 5],
          icon: <Home />,
        },
        {
          title: "Talent",
          icon: <SwitchAccount />,
          workspaceVisibility: [1, 2],
          roleVisibility: [7, 5],
          subMenus: [{ title: "Home", route: `${REACT_APP_VN_TALENT_URL}/${auth_key}/${user_id}/talent-management` }],
        },
        {
          title: "Toolbox",
          icon: <DesignServices />,
          workspaceVisibility: [1, 2],
          roleVisibility: [7, 5],
          subMenus: [],
        },
        { title: "Business", icon: <BusinessCenter />, route: "/business", workspaceVisibility: [1, 2], roleVisibility: [7, 5] },
        { title: "Earnings", icon: <InsertChartOutlined />, route: "/earnings", workspaceVisibility: [1, 2], roleVisibility: [7, 5] },
        {
          title: "Media kit",
          icon: <BusinessCenter />,
          route: `${REACT_APP_VN_MEDIAKIT_URL}/${auth_key}/${user_id}`,
          workspaceVisibility: [1, 2],
          roleVisibility: [7, 5],
        },
      ],
      // AI-Toolbox / TechLabs
      8: [
        // COMMENTED OUT UNTIL APIS HAVE BEEN UPDATED, NONE ARE FUNCTIONAL AT THIS TIME (SSTOCKALL - FEB 2/23)
        // {
        //   title: "Content Management",
        //   icon: <SnippetFolder />,
        //   workspaceVisibility: [1],
        //   roleVisibility: [7, 5],
        //   subMenus: [
        //     { title: "Schedule Post", route: "/content-management/posts" },
        //     { title: "Drafts", route: "/content-management/drafts" },
        //     { title: "Stories", route: "/content-management/stories" },
        //     { title: "Scheduler", route: "/content-management/scheduler" },
        //     { title: "Queues", route: "/content-management/queues" },
        //     { title: "Bulk Import", route: "/content-management/bulk-import" },
        //     { title: "Generate Post Captions", route: "/content-management/gen-post-captions" },
        //   ],
        // },
        // {
        //   title: "Content Creation",
        //   icon: <ModeEdit />,
        //   workspaceVisibility: [1],
        //   roleVisibility: [7, 5],
        //   route: "/content-creation",
        //   // subMenus: [
        //   //   { title: "Generate Video Scripts", route: "/content-creation/gen-video-script" },
        //   //   { title: "Generate Blogs", route: "/content-creation/gen-blogs" },
        //   //   { title: "Generate Captions", route: "/content-creation/gen-captions" },
        //   //   { title: "Generate Text To Image", route: "/content-creation/gen-txt-to-img" },
        //   // ],
        // },
        // {
        //   title: "Analysis Tools",
        //   icon: <Analytics />,
        //   workspaceVisibility: [1],
        //   roleVisibility: [7, 5],
        //   route: "/analysis-tools",
          // subMenus: [
          //   { title: "Sentiment Analysis", route: "/analysis-tools/sentiment" },
          //   { title: "Keywords Extraction", route: "/analysis-tools/keywords" },
          //   { title: "Cyberbullying Detection", route: "/analysis-tools/cyberbullying" },
          //   { title: "Hate Speech Detection", route: "/analysis-tools/hate-speech" },
          //   { title: "Toxicity Detection", route: "/analysis-tools/toxicity" },
          //   { title: "Anxiety Detection", route: "/analysis-tools/anxiety" },
          //   // { title: "Topic Modeling", route: "/analysis-tools/topic-modeling" },
          //   // { title: "Content Analysis", route: "/analysis-tools/content" },
          //   { title: "Image Content Moderation", route: "/analysis-tools/image-moderation" },
          //   { title: "Video Content Moderation", route: "/analysis-tools/video-moderation" },
          //   // { title: "Post Analysis", route: "/analysis-tools/post" },
          //   { title: "Text Extraction", route: "/analysis-tools/text-extraction" },
          //   { title: "Emotion Detection", route: "/analysis-tools/emotion-detection" },
          //   { title: "Object Detection", route: "/analysis-tools/object-detection" },
          // ],
        // },
        // {
        //   title: "Recommendation Engines",
        //   icon: <Recommend />,
        //   route: "/recommendation-engine",
        //   workspaceVisibility: [1],
        //   roleVisibility: [7, 5],
        //   // subMenus: [
        //   //   { title: "Hashtags", route: "/recommendation-engine/hashtags" },
        //   //   { title: "Get Inspired", route: "/recommendation-engine/get-inspired" },
        //   // ],
        // },
        // { title: "Influencer Handle Audit", icon: <HandleAudit />, route: "/influencer-handle-audit", workspaceVisibility: [1], roleVisibility: [7, 5] },
        { title: "Customer Experience Portal", icon: <HandleAudit />, route: "/customer-experience-portal", workspaceVisibility: [1], roleVisibility: [7, 5] },
        { title: "Employee Experience Portal", icon: <BadgeIcon />, route: "/employee-experience-portal", workspaceVisibility: [1], roleVisibility: [7, 5] },
        { title: "Connect Embed Flow", icon: <CodeIcon />, route: "/connect-embed-flow", workspaceVisibility: [1], roleVisibility: [7, 5] },

        // { title: "Content Collection", icon: <BusinessCenter />, route: "/content-collection", workspaceVisibility: [1], roleVisibility: [7, 5] },
        { title: "API ToolBox", icon: <Api />, route: "/api-toolbox", workspaceVisibility: [1], roleVisibility: [7, 5] },
        // { title: "Profile Search", icon: <PersonSearch />, route: "/profile-search", workspaceVisibility: [1], roleVisibility: [7, 5] },
        // { title: "ShotList", icon: <Videocam />, route: "/shotlist", workspaceVisibility: [1], roleVisibility: [7, 5] },
        // { title: "API Docs", icon: <Api />, route: "/api-docs", workspaceVisibility: [1], roleVisibility: [7, 5] },
        // { title: "Transcribe", icon: <Article />, route: "/transcribe", workspaceVisibility: [1], roleVisibility: [7, 5] },
        // { title: "Identity Science Site", icon: <ExitToApp />, route: REACT_APP_IDENTITY_SCIENCE_URL, workspaceVisibility: [1], roleVisibility: [7, 5] },
      ],
      // Forms
      9: [
        {
          title: "Home",
          route: "/",
          workspaceVisibility: [1, 2],
          roleVisibility: [7, 5],
          icon: <Home />,
        },
      ],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth_key]
  );

  useEffect(() => {
    if (currentMenuRouteIndexMapRef.current.length && redirect_side_menu) {
      const index = currentMenuRouteIndexMapRef.current.findIndex((menu) => menu.route === redirect_side_menu);
      if (index !== -1) {
        if (currentMenuRouteIndexMapRef.current[index].hasOwnProperty("is")) {
          setActiveMenu(currentMenuRouteIndexMapRef.current[index].i);
          setActiveSubMenu(currentMenuRouteIndexMapRef.current[index].is);
          navigate(redirect_side_menu);
          authDispatch({ type: "SET_REDIRECT_SIDE_MENU", payload: null });
        } else {
          setActiveMenu(currentMenuRouteIndexMapRef.current[index].i);
          navigate(redirect_side_menu);
          authDispatch({ type: "SET_REDIRECT_SIDE_MENU", payload: null });
        }
      }
    } else {
      const index = currentMenuRouteIndexMapRef.current.findIndex((menu) => menu.route === location.pathname);
      if (index !== -1) {
        if (currentMenuRouteIndexMapRef.current[index].hasOwnProperty("is")) {
          setActiveMenu(currentMenuRouteIndexMapRef.current[index].i);
          setActiveSubMenu(currentMenuRouteIndexMapRef.current[index].is);
        } else {
          setActiveMenu(currentMenuRouteIndexMapRef.current[index].i);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (Object.keys(MenuMap).includes(props.domainId)) {
    return (
      <>
        {REACT_APP_DOMAIN_ID === "2" && (
          <Button
            fullWidth
            onClick={() => {
              creatorPlusDispatch({
                isCreatorPlus: !creatorPlusState.isCreatorPlus,
              });
              setRole(!creatorPlusState.isCreatorPlus ? 7 : 5);
              navigate(!creatorPlusState.isCreatorPlus ? "/creator/Profile" : "/feed/home");
            }}
          >
            {!creatorPlusState.isCreatorPlus ? (
              <CreatorPlusIcon width={40} height={40} />
            ) : (
              <>
                <VNLogo sx={{ color: theme.palette.card.main }} style={{ height: "28px", width: "28px" }} />{" "}
                <Typography color={theme.palette.card.main}>Plus</Typography>
              </>
            )}
          </Button>
        )}

        <List>
          {MenuMap[props.domainId].map((menu, index) => {
            if (!menu.workspaceVisibility.includes(props.activeWorkspaceType)) return null;
            if (props.domainId !== "2" && !menu.roleVisibility.includes(role_id as Role)) return null;
            if (props.domainId === "2" && !menu.roleVisibility.includes(role as Role)) return null;

            if (menu?.platformVisibility && !menu.platformVisibility.includes(getReferrerContext() as Platform)) return null;
            if (menu.route === undefined) {
              return (
                <ListItem key={menu.title} disableGutters sx={{ display: "flex", flexDirection: "column" }}>
                  <ListItemButton
                    className={`Side-Menu ${activeMenu === index && "expanded"}`}
                    onClick={() => {
                      if (activeMenu === index) {
                        setActiveMenu(null);
                        setActiveSubMenu(null);
                        return;
                      }
                      setActiveMenu(index);
                      if (/^\//.test(menu.subMenus[0]?.route)) {
                        setActiveSubMenu(0);
                        navigate(menu.subMenus[0].route);
                      } else {
                        setActiveSubMenu(null);
                      }
                    }}
                  >
                    <ListItemIcon className={`Side-Menu ${activeMenu === index && "active"}`} sx={{ minWidth: "40px" }}>
                      {menu.icon}
                    </ListItemIcon>
                    {/* <CustomTooltip
                    disableHoverListener={checkMenuTitleOverflow(menu.title)}
                    content={menu.title}
                    sx={{ backgroundColor: "black", borderRadius: "4px" }}
                    arrow={false}
                    placement={"top"}
                  > */}
                    <ListItemText
                      className={`Side-Menu ${activeMenu === index && "active"}`}
                      primary={menu.title}
                      sx={{ "&>span.MuiTypography-root": { overflow: "hidden", textOverflow: "ellipsis" } }}
                    />
                    {/* </CustomTooltip> */}
                    <IconButton className={`Side-Menu ${activeMenu === index && "expanded"}`}>
                      {activeMenu === index ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </ListItemButton>
                  <Collapse sx={{ width: "100%" }} in={props.isDrawerOpen && activeMenu === index}>
                    <List>
                      {menu.subMenus.map((subMenu, subMenuIndex) => {
                        currentMenuRouteIndexMapRef.current.push({ i: index, is: subMenuIndex, route: subMenu.route });
                        return (
                          <ListItemButton
                            className={`Side-Menu Sub-Menu ${activeSubMenu === subMenuIndex && "expanded"}`}
                            key={subMenuIndex}
                            onClick={() => {
                              setActiveSubMenu(subMenuIndex);
                              if (/^\//.test(subMenu?.route)) {
                                navigate(subMenu.route);
                              } else {
                                localStorage.setItem("auth_key_used", "used");
                                window.location.replace(subMenu.route);
                              }
                              if (props.isXsSize) {
                                props.setShowDrawer(false);
                              }
                            }}
                          >
                            <ListItemIcon sx={{ paddingLeft: "10px", minWidth: "40px" }}>
                              <Circle
                                sx={{ color: activeSubMenu === subMenuIndex ? theme.palette.primary.main : theme.palette.text.secondary, fontSize: "9px" }}
                              />
                            </ListItemIcon>
                            {/* <CustomTooltip
                            disableHoverListener={checkMenuTitleOverflow(subMenu.title)}
                            content={menu.title}
                            sx={{ backgroundColor: "black", borderRadius: "4px" }}
                            arrow={false}
                            placement={"top"}
                          > */}
                            <ListItemText
                              className={`Side-Menu Sub-Menu ${activeSubMenu === subMenuIndex && "active"}`}
                              primary={subMenu.title}
                              sx={{ "&>span.MuiTypography-root": { overflow: "hidden", textOverflow: "ellipsis" } }}
                            />
                            {/* </CustomTooltip> */}
                          </ListItemButton>
                        );
                      })}
                    </List>
                  </Collapse>
                </ListItem>
              );
            } else {
              currentMenuRouteIndexMapRef.current.push({ i: index, route: menu.route });
              return (
                <ListItem key={menu.title} disableGutters>
                  <ListItemButton
                    sx={{ minHeight: "56px" }}
                    className={`Side-Menu ${activeMenu === index && "expanded"}`}
                    onClick={() => {
                      setActiveMenu(index);
                      setActiveSubMenu(null);
                      navigate(menu.route);
                      if (props.isXsSize) {
                        props.setShowDrawer(false);
                      } else if (/^http/.test(menu.route)) {
                        window.location.replace(menu.route);
                      }
                    }}
                  >
                    <ListItemIcon className={`Side-Menu ${activeMenu === index && "active"}`} sx={{ minWidth: "40px" }}>
                      {menu.icon}
                    </ListItemIcon>
                    {/* <CustomTooltip
                    disableHoverListener={checkMenuTitleOverflow(menu.title)}
                    content={menu.title}
                    sx={{ backgroundColor: "black", borderRadius: "4px" }}
                    arrow={false}
                    placement={"top"}
                  > */}
                    <ListItemText
                      className={`Side-Menu ${activeMenu === index && "active"}`}
                      primary={menu.title}
                      sx={{ "&>span.MuiTypography-root": { overflow: "hidden", textOverflow: "ellipsis" } }}
                    />
                    {/* </CustomTooltip> */}
                  </ListItemButton>
                </ListItem>
              );
            }
          })}
        </List>
      </>
    );
  } else {
    return <></>;
  }
};

export default NavMenuRenderer;
