import { createContext } from "../hooks";
import React, { useReducer } from "react";
import { workspacesReducer } from "../context-reducers";

let initialState: Workspace.State = {
  list: [],
  active_workspace: {},
  is_workspace_admin: false,
  is_workspace_owner: false,
  user_legal_company: {},
  user_organizations: [],
  workspace_members: [],
  workspace_team_members: [],
};

export const [useWorkspacesContext, WorkspacesProvider] = createContext<Workspace.ContextType>();

export const WorkspacesContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [workspacesState, workspacesDispatch] = useReducer(workspacesReducer, initialState);

  return <WorkspacesProvider value={{ workspacesState, workspacesDispatch }}>{children}</WorkspacesProvider>;
};
