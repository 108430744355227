import { SvgIcon, SvgIconProps } from "@mui/material";

export default function HandleAudit(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 345.82 343.371" width="345.82" height="343.371" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(0.069233, 0, 0, -0.07447, 56.439861, 416.357452)" stroke="none">
        <path d="M 2723.811 2904.233 C 2556.952 2888.121 2416.757 2842.784 2291.106 2764.477 C 2071.725 2628.094 1931.933 2423.893 1890.724 2179.601 C 1879.814 2114.032 1881.027 1991.886 1893.148 1925.193 C 1919.814 1780.193 1984.456 1643.059 2078.592 1533.278 C 2111.723 1494.686 2181.214 1429.867 2222.827 1398.768 C 2476.147 1208.806 2823.604 1158.598 3125.809 1267.63 C 3151.263 1276.998 3194.898 1295.356 3222.37 1308.845 L 3272.873 1333.199 L 3440.542 1178.082 C 3621.945 1010.974 3628.41 1006.104 3683.76 993.74 C 3758.1 977.628 3830.824 997.111 3885.366 1048.067 C 3953.646 1111.388 3968.999 1202.811 3924.961 1281.869 C 3914.455 1300.602 3882.539 1332.076 3745.171 1460.59 L 3577.908 1616.083 L 3604.169 1662.917 C 3676.085 1790.684 3709.214 1915.077 3709.214 2057.831 C 3709.214 2197.961 3678.104 2317.483 3610.229 2441.878 C 3476.095 2687.667 3226.816 2854.4 2931.881 2897.113 C 2891.076 2902.734 2757.749 2907.605 2723.811 2904.233 Z M 2925.821 2738.249 C 3373.475 2661.44 3644.166 2242.172 3503.165 1842.763 C 3425.997 1623.951 3228.835 1451.223 2990.465 1392.773 C 2931.881 1378.535 2905.215 1374.414 2846.229 1370.667 C 2544.427 1350.809 2256.765 1505.552 2125.862 1758.087 C 2027.686 1947.299 2026.878 2164.989 2123.439 2353.828 C 2163.839 2432.51 2210.707 2492.085 2281.814 2556.155 C 2340.801 2608.984 2390.496 2641.581 2461.199 2674.179 C 2543.619 2712.396 2622.806 2734.502 2714.924 2745.368 C 2761.385 2750.613 2874.915 2746.866 2925.821 2738.249 Z M 3774.665 1208.43 C 3794.866 1172.836 3752.848 1131.621 3714.466 1149.606 C 3709.617 1151.855 3638.914 1215.55 3557.303 1290.86 L 3408.624 1428.367 L 3441.754 1459.092 L 3475.286 1490.19 L 3621.945 1354.556 C 3702.749 1279.62 3771.433 1214.052 3774.665 1208.43 Z" />
        <path d="M 3077.917 2297.148 C 3063.451 2292.926 3040.824 2274.416 2898.014 2149.393 C 2808.247 2070.807 2733.32 2006.51 2731.094 2006.51 C 2728.868 2006.51 2688.065 2040.933 2640.216 2083.148 C 2584.946 2131.533 2547.483 2162.058 2538.208 2165.955 C 2479.971 2189.985 2415.429 2140.626 2432.492 2085.096 C 2436.943 2071.132 2448.443 2059.766 2560.094 1961.373 C 2641.327 1889.93 2688.065 1850.962 2697.709 1847.065 C 2715.886 1839.596 2743.334 1839.273 2761.881 1845.767 C 2779.315 1852.261 3160.263 2184.14 3171.021 2202.65 C 3179.181 2216.289 3181.036 2242.592 3174.729 2257.205 C 3167.681 2273.767 3145.055 2291.951 3125.025 2297.148 C 3102.768 2302.993 3099.43 2302.993 3077.917 2297.148 Z" />
      </g>
      <path d="M 152.188 3.494 C 69.796 3.494 2.919 71.322 2.919 154.917 C 2.919 238.496 69.796 306.328 152.188 306.328 L 206.612 305.546 C 202.749 306.933 193.044 278.065 195.368 279.327 L 147.275 281.035 C 83.911 283.85 32.765 220.635 32.765 154.917 C 32.765 89.188 87.406 33.76 152.188 33.76 C 216.964 33.76 271.585 89.188 271.585 154.917 L 271.585 176.563 C 271.585 188.542 260.989 200.338 249.211 200.338 C 237.414 200.338 226.82 188.542 226.82 176.563 L 226.82 154.917 C 226.82 113.109 193.391 79.209 152.188 79.209 C 110.982 79.209 77.556 113.109 77.556 154.917 C 77.556 196.705 110.982 230.634 152.188 230.634 C 172.791 230.634 191.601 222.141 205.014 208.365 C 214.586 220.092 238.207 199.808 256.543 207.18 C 285.325 218.752 290.18 203.554 295.429 188.248 C 296.616 184.786 297.823 181.32 299.331 178.156 L 301.452 154.917 C 301.452 71.322 234.577 3.494 152.188 3.494 Z M 152.188 200.338 C 127.41 200.338 107.397 180.043 107.397 154.917 C 107.397 129.781 127.41 109.487 152.188 109.487 C 176.961 109.487 196.953 129.781 196.953 154.917 C 196.953 180.043 176.961 200.338 152.188 200.338 Z" />
    </SvgIcon>
  );
}
