import { createContext } from "../hooks";
import { authReducer } from "../context-reducers";
import React, { useReducer } from "react";

let initialState: Auth.State = {
  access_token_exp: null,
  access_token: null,
  role_id: null,
  user_id: null,
  auth_key: null,
  redirect_side_menu: null,
  last_active_workspace_id: null,
  workspace_pinned_status: null,
  profile_picture_url: null,
};

export const [useAuthContext, AuthProvider] = createContext<Auth.ContextType>();

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [authState, authDispatch] = useReducer(authReducer, initialState);

  return <AuthProvider value={{ authState, authDispatch }}>{children}</AuthProvider>;
};
