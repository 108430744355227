import { Box, Skeleton } from "@mui/material";

const CreateWorkspacePageSkeleton = () => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Skeleton variant="text" animation={"wave"} sx={{ height: "27px", width: "278px" }} />
      <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
        <Skeleton variant="text" animation={"wave"} sx={{ height: "20px", width: "150px" }} />
        <Skeleton variant={"rounded"} animation={"wave"} sx={{ height: "40px", width: "532px" }} />
        <Skeleton variant={"rounded"} animation={"wave"} sx={{ height: "40.5px", width: "115px" }} />
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
        <Skeleton variant="text" animation={"wave"} sx={{ height: "20px", width: "150px" }} />
        <Skeleton variant={"rounded"} animation={"wave"} sx={{ height: "40px", width: "532px" }} />
        <Skeleton variant={"rounded"} animation={"wave"} sx={{ height: "40.5px", width: "115px" }} />
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
        <Skeleton variant="text" animation={"wave"} sx={{ height: "20px", width: "150px" }} />
        <Skeleton variant={"rounded"} animation={"wave"} sx={{ height: "40px", width: "532px" }} />
      </Box>
      <Skeleton variant={"rounded"} animation={"wave"} sx={{ height: "36.5px", width: "532px", mt: "8px" }} />
    </Box>
  );
};

export default CreateWorkspacePageSkeleton;
