import { createClient } from "graphql-ws";
import storage from "redux-persist-indexeddb-storage";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { CachePersistor, AsyncStorageWrapper } from "apollo3-cache-persist";
import { ApolloClient, createHttpLink, DefaultOptions, InMemoryCache, split } from "@apollo/client";
import {
  REACT_APP_USER_API_URL_GRAPHQL,
  REACT_APP_AUTH_API_URL_GRAPHQL,
  REACT_APP_FEED_API_URL_GRAPHQL,
  REACT_APP_COMMAND_API_URL_GRAPHQL,
  REACT_APP_SECURE_API_URL_GRAPHQL,
  REACT_APP_ACADEMY_API_URL_GRAPHQL,
  REACT_APP_NOTIFICATION_API_URL_GRAPHQL,
  REACT_APP_USER_WEB_SOCKET_URL_GRAPHQL,
  REACT_APP_PAYMENT_GRAPHQL_URL,
  REACT_APP_TALENT_GRAPHQL_URL,
  REACT_APP_NOTIFICATION_WEB_SOCKET_URL_GRAPHQL,
  REACT_APP_CHAT_API_URL_GRAPHQL,
  REACT_APP_CHAT_WEB_SOCKET_URL_GRAPHQL,
  REACT_APP_COMMUNITY_API_URL_GRAPHQL,
  REACT_APP_NEWS_API_URL_GRAPHQL,
  REACT_APP_EMAIL_API_URL_GRAPHQL,
} from "../utilities/constants";
import { getAccessToken } from "../utilities/get-access-token";

const cache = new InMemoryCache();

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "network-only",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "all",
  },
};

export const persistor = new CachePersistor({ cache, storage: new AsyncStorageWrapper(storage("apollo")) });

(async () => {
  await persistor.restore();
})();

const authHttpLink = createHttpLink({ uri: REACT_APP_AUTH_API_URL_GRAPHQL, credentials: "include" });
const emailHttpLink = createHttpLink({ uri: REACT_APP_EMAIL_API_URL_GRAPHQL, credentials: "include" });
const userHttpLink = createHttpLink({ uri: REACT_APP_USER_API_URL_GRAPHQL, credentials: "include" });
const feedHttpLink = createHttpLink({ uri: REACT_APP_FEED_API_URL_GRAPHQL, credentials: "include" });
const commandHttpLink = createHttpLink({ uri: REACT_APP_COMMAND_API_URL_GRAPHQL, credentials: "include" });
const academyHttpLink = createHttpLink({ uri: REACT_APP_ACADEMY_API_URL_GRAPHQL, credentials: "include" });
const secureHttpLink = createHttpLink({ uri: REACT_APP_SECURE_API_URL_GRAPHQL, credentials: "include" });
const notificationHttpLink = createHttpLink({ uri: REACT_APP_NOTIFICATION_API_URL_GRAPHQL, credentials: "include" });
const paymentHttpLink = createHttpLink({ uri: REACT_APP_PAYMENT_GRAPHQL_URL, credentials: "include" });
const talentHttpLink = createHttpLink({ uri: REACT_APP_TALENT_GRAPHQL_URL, credentials: "include" });
const communityHttpLink = createHttpLink({ uri: REACT_APP_COMMUNITY_API_URL_GRAPHQL, credentials: "include" });
const newsHttpLink = createHttpLink({ uri: REACT_APP_NEWS_API_URL_GRAPHQL, credentials: "include" });

const withHeaders = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: getAccessToken(),
    },
  };
});

export const newsClient = new ApolloClient({
  cache,
  link: withHeaders.concat(newsHttpLink),
  defaultOptions: defaultOptions,
});

export const authClient = new ApolloClient({
  cache,
  link: withHeaders.concat(authHttpLink),
  defaultOptions: defaultOptions,
});

export const authClientWCredentials = new ApolloClient({
  cache,
  link: withHeaders.concat(authHttpLink),
  defaultOptions: defaultOptions,
});

export const userClient = new ApolloClient({
  cache,
  link: withHeaders.concat(userHttpLink),
  defaultOptions: defaultOptions,
});

export const feedClient = new ApolloClient({
  cache,
  link: withHeaders.concat(feedHttpLink),
  defaultOptions: defaultOptions,
});

export const commandClient = new ApolloClient({
  cache,
  link: withHeaders.concat(commandHttpLink),
  defaultOptions: defaultOptions,
});

export const secureClient = new ApolloClient({
  cache,
  link: withHeaders.concat(secureHttpLink),
  defaultOptions: defaultOptions,
});

export const notificationClient = new ApolloClient({
  cache,
  link: withHeaders.concat(notificationHttpLink),
  defaultOptions: defaultOptions,
});

export const emailClient = new ApolloClient({
  cache,
  link: withHeaders.concat(emailHttpLink),
  defaultOptions: defaultOptions,
});

export const academyClient = new ApolloClient({
  cache,
  link: withHeaders.concat(academyHttpLink),
  defaultOptions: defaultOptions,
});

export const paymentClient = new ApolloClient({
  cache,
  link: withHeaders.concat(paymentHttpLink),
  defaultOptions: defaultOptions,
});

export const talentClient = new ApolloClient({
  cache,
  link: withHeaders.concat(talentHttpLink),
  defaultOptions: defaultOptions,
});

export const communitiesClient = new ApolloClient({
  cache,
  link: withHeaders.concat(communityHttpLink),
  defaultOptions: defaultOptions,
});

//User Web Socket Client
const wsLink = new GraphQLWsLink(
  createClient({
    url: REACT_APP_USER_WEB_SOCKET_URL_GRAPHQL!,
    connectionParams: () => {
      return { Authorization: getAccessToken() };
    },
  })
);
const webSocketLink = split(({ query }) => {
  const def = getMainDefinition(query);
  return def.kind === "OperationDefinition" && def.operation === "subscription";
}, wsLink);
export const userSocketClient = new ApolloClient({
  cache,
  link: webSocketLink,
  defaultOptions: defaultOptions,
});

//Notfication Socket Client
const notificationhttpLink = createHttpLink({
  uri: REACT_APP_NOTIFICATION_API_URL_GRAPHQL,
});
const wsNotificationLink = new GraphQLWsLink(
  createClient({
    url: REACT_APP_NOTIFICATION_WEB_SOCKET_URL_GRAPHQL,
    // for auth token
    connectionParams: {
      Authorization: getAccessToken(),
    },
  })
);
export const webSocktNotificationLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsNotificationLink,
  notificationhttpLink
);
export const notificationSubscriptionClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: withHeaders.concat(webSocktNotificationLink),
  defaultOptions: defaultOptions,
});

const chatLink = createHttpLink({
  uri: REACT_APP_CHAT_API_URL_GRAPHQL,
});
const wsChatLink = new GraphQLWsLink(
  createClient({
    url: REACT_APP_CHAT_WEB_SOCKET_URL_GRAPHQL,
    // for auth token
    connectionParams: {
      Authorization: getAccessToken(),
    },
  })
);
export const chatWebSocketLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsChatLink,
  chatLink
);

export const subscriptionClient = new ApolloClient({
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
  link: withHeaders.concat(chatWebSocketLink),
});
