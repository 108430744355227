import { REACT_APP_DOMAIN_ID } from "./constants";

export default function getReferrerContext() {
  const context = document.referrer;
  if(REACT_APP_DOMAIN_ID !== "5"){
   return REACT_APP_DOMAIN_ID;
  } else{
    if (/https:\/\/secure\.?(dev|uat|sat)?\.(vnplatform|vntech)\.(com|io)/.test(context)) {
      return "1";
    }
    if (/https:\/\/(dev|uat|sat)?\.(vnplatform|vntech)\.(com|io)/.test(context)) {
      return "2";
    }
    if (/https:\/\/talent\.?(dev|uat|sat)?\.(vnplatform|vntech)\.(com|io)/.test(context)) {
      return "3";
    }
    if (/https:\/\/admin\.?(dev|uat|sat)?\.(vnplatform|vntech)\.(com|io)/.test(context)) {
      return "4";
    }
    if (/https:\/\/vmessenger\.?(dev|uat|sat)?\.(vnplatform|vntech)\.(com|io)/.test(context)) {
      return "6";
    }
    if (/https:\/\/mediakits\.?(dev|uat|sat)?\.(vnplatform|vntech)\.(com|io)/.test(context)) {
      return "7";
    }
    if (/https:\/\/techlabs\.?(dev|uat|sat)?\.(vnplatform|vntech)\.(com|io)/.test(context)) {
      return "8";
    } else {
      return "-1";
    }
  }
}
