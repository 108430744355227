/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from "react";
import { Dialog, Avatar, DialogProps, Grid, Typography, useTheme, Input, InputAdornment, Box, Divider } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { Search } from "@mui/icons-material";
import { userClient } from "../../../../main";
import CheckIcon from "@mui/icons-material/Check";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_ALL_USERS, GET_CONVERSATION_INFO } from "../../../../apollo/queries";
import InfiniteScroll from "react-infinite-scroll-component";
import { subscriptionClient } from "../../../../apollo/client";
import { INVITE_TO_GROUP } from "../../../../apollo/mutations";
import { toast } from "react-toastify";
import { TOASTIFY_THEME } from "../../../../utilities/constants";

type accountType = {
  user_id: number;
  user_name: string;
  user_profile_pic_url: string;
  is_admin?: boolean;
};

const InviteGroupMemberDialog = (
  props: Omit<DialogProps, "onClose" | "children"> & {
    onClose: Exclude<DialogProps["onClose"], undefined>;
    children?: never;
    groupId: string;
    setShowInviteMemberModal: Dispatch<SetStateAction<boolean>>;
    handleClose: () => void;
  }
) => {
  const { groupId, setShowInviteMemberModal, handleClose, ...dialogProps } = props;
  const [searchContact, setsearchContact] = useState<string>("");
  const [isGroupSelection, setGroupSelection] = useState<boolean>(false);
  const [groupSelectedArr, setGroupSelectedArr] = useState<accountType[]>([]);
  const [contactListItems, setContactListItems] = useState<accountType[]>([]);
  const [page, setPage] = useState<number>(1);
  const handleChange = (e: any) => {
    setsearchContact(e.target.value);
  };
  const [getAllUsersQuery, { data: allUsersData, loading: allUsersLoading, error: allUsersError }] = useLazyQuery(GET_ALL_USERS, {
    client: userClient,
    variables: {
      rows: 25,
      page: page,
      sort: {
        key: "first_name",
        sort: "asc",
      },
      search: searchContact ? searchContact : null,
      filter: null,
    },
    fetchPolicy: "network-only",
  });
  const [inviteMember, { data: inviteMemberData, error: inviteMemberError, loading: inviteMemberLoading }] = useMutation(INVITE_TO_GROUP, {
    client: subscriptionClient,
    refetchQueries: [{ query: GET_CONVERSATION_INFO, variables: { conversationId: groupId } }],
  });

  useEffect(() => {
    if (allUsersData && !allUsersLoading) {
      let userData = allUsersData?.getAllUsersData?.data?.users;
      if (searchContact) {
        setContactListItems([]);
        if (userData.length > 0) {
          userData.map((user: any) => {
            let userItem: accountType = {
              user_id: user.id,
              user_name: user.first_name + " " + user.last_name,
              user_profile_pic_url: user.profile_pic_url,
              is_admin: user?.signed_up_as === 5 && true,
            };
            return setContactListItems((prevState) => {
              return [...prevState, userItem];
            });
          });
        }
      } else {
        if (userData.length > 0) {
          userData.map((user: any) => {
            let userItem: accountType = {
              user_id: user.id,
              user_name: user.first_name + " " + user.last_name,
              user_profile_pic_url: user.profile_pic_url,
              is_admin: user?.signed_up_as === 5 && true,
            };
            return setContactListItems((prevState) => {
              return [...prevState, userItem];
            });
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [allUsersData, allUsersLoading, allUsersError]);

  useEffect(() => {
    if (inviteMemberData && !inviteMemberError && !inviteMemberLoading) {
      setShowInviteMemberModal(false);
      handleClose();
      toast.success(inviteMemberData?.inviteToGroup?.msg, TOASTIFY_THEME);
    }
  }, [inviteMemberData, inviteMemberError, inviteMemberLoading]);

  const fetchMore = () => {
    getAllUsersQuery({
      variables: {
        page: searchContact ? 1 : page,
        rows: 25,
        sort: {
          key: "first_name",
          sort: "asc",
        },
        filter: null,
        search: searchContact ? searchContact : null,
      },
    });
  };
  useEffect(() => {
    fetchMore();
    // eslint-disable-next-line
  }, [page, searchContact]);

  const onGroupSelection = (item: accountType) => {
    let tempArr = [...groupSelectedArr];
    let indexTemp: number = tempArr.findIndex((e: any) => e?.user_id === item?.user_id);
    if (indexTemp > -1) {
      tempArr.splice(indexTemp, 1);
    } else {
      tempArr.push(item);
    }
    setGroupSelectedArr(tempArr);
  };

  const addNewMember = (account: accountType[]) => {
    inviteMember({
      variables: {
        conversationId: groupId,
        invitedUsers: account,
      },
    });
  };

  const handleChatSelection = (item: any, type: string) => {
    if (isGroupSelection && type === "GROUP") {
      addNewMember(item);
    } else {
      setGroupSelection(true);
      onGroupSelection(item);
    }
  };

  const contactItem = contactListItems.map((item) => {
    return (
      <>
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{
            padding: "10px",
          }}
          onClick={() => {
            handleChatSelection(item, "CHAT");
          }}
        >
          <Grid item position={"relative"}>
            <Avatar alt="Remy Sharp" src={item.user_profile_pic_url} />
            {groupSelectedArr?.map((newItem) => {
              return (
                newItem?.user_id === item?.user_id && (
                  <CheckIcon
                    fontSize="small"
                    sx={{
                      bottom: 0,
                      right: 0,
                      left: 40,
                      position: "absolute",
                      color: "#3DACFF",
                      backgroundColor: "#000",
                      borderRadius: "50%",
                      padding: "1px",
                      opacity: 0.9,
                    }}
                  />
                )
              );
            })}
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: "opacity75",
              }}
            >
              {item.user_name}
            </Typography>
          </Grid>
        </Grid>
        <Divider light />
      </>
    );
  });

  return (
    <Dialog {...dialogProps} PaperProps={{ variant: "primary-p1rem" }}>
      <Grid container maxWidth={"28rem"} minWidth={"22rem"} flexDirection={"column"} gap={1}>
        <Grid item xs={12} mt={1} display={"flex"} justifyContent="space-between">
          <Typography fontWeight={600} variant="opacity95-20px">
            Add participants
          </Typography>
          {groupSelectedArr?.length > 0 && (
            <Grid item onClick={() => handleChatSelection(groupSelectedArr, "GROUP")}>
              <Typography fontSize={"20px"} fontWeight={600} color="#0B72F4">
                Done
              </Typography>
            </Grid>
          )}
        </Grid>
        <Input
          type="text"
          sx={{ width: " -webkit-fill-available" }}
          placeholder={"Search"}
          value={searchContact}
          onChange={handleChange}
          startAdornment={
            <InputAdornment position="start">
              <Search sx={{ color: "opacity70" }} />
            </InputAdornment>
          }
        />
        <InfiniteScroll
          dataLength={contactListItems.length}
          next={() => {
            setPage((prev) => prev + 1);
          }}
          style={{ display: "flex", flexDirection: "column", maxHeight: "calc(100vh - 220px)", overflow: "auto" }}
          hasMore={true}
          scrollableTarget="scrollableDiv"
          loader={<h4 style={{ color: "opacity75", textAlign: "center" }}>Loading...</h4>}
        >
          <Box>{contactItem}</Box>
        </InfiniteScroll>
      </Grid>
    </Dialog>
  );
};

export default InviteGroupMemberDialog;
