import { createContext as reactCreateContext, useContext as reactUseContext } from "react";

const createContext = <ContextType extends {} | null>() => {
  const context = reactCreateContext<ContextType | undefined>(undefined);

  const useContext = () => {
    const c = reactUseContext(context);
    if (c === undefined) {
      throw new Error("Error: Unable to find any context, make sure you're using this hook inside a Provider with a context value");
    }
    return c;
  };
  return [useContext, context.Provider] as const;
};

export default createContext;