/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import ListChats from "../ListChats";
import ListHeader from "../ListHeader";
import { GET_USER_CONVERSATION } from "../../../../apollo/queries";
import { subscriptionClient } from "../../../../apollo/client";
import { useLazyQuery } from "@apollo/client";
import { getAccessToken } from "../../../../utilities/get-access-token";

interface chatRoomType {
  id: string;
  name: string;
  conversationtype: "chat | group";
  groupName: "";
  lastMessage?: Object;
  profileUrl: any;
  timestamp: string;
  unreadCount: number;
}

interface chatListItemType {
  id: string;
  name: string;
  conversationtype: "chat | group";
  groupName?: "";
  lastMessage: string;
  profileUrl: any;
  timestamp?: string;
  unreadCount?: number;
}

const InboxList = (props: any) => {
  const [allMessage, setAllMessages] = useState<chatListItemType[]>([]);
  const [_, setUnreadArr] = useState<chatListItemType[]>([]);

  const [getUserConversation, { data: getUserConversationData, loading: getUserConversationLoading, error: getUserConversationError }] = useLazyQuery(
    GET_USER_CONVERSATION,
    {
      client: subscriptionClient,
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    //
    getUserConversation();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    let conversations = getUserConversationData?.getUserConversations?.data?.userConversation?.conversation;
    let unReadConv;
    const myId = getUserConversationData?.getUserConversations?.data?.userConversation?.user_id;
    props.setmyId(myId);

    if (conversations?.length > 0) {
      conversations = [...conversations];
      setAllMessages([]);
      conversations.map((item: any) => {
        const chatListItem = {
          id: item._id,
          name: item.name === "" ? "Conversation" : item.name,
          conversationtype: item.type,
          profileUrl: !item.profile_url ? "" : item.profile_url,
          lastMessage: item.last_message,
          unReadCount: item?.unread_count,
        };
        return setAllMessages((prev) => {
          return [...prev, chatListItem];
        });
      });
    }

    if (conversations?.length > 0) {
      unReadConv = [...conversations];
      unReadConv.filter((item) => {
        if (item?.unreadCount > 0) {
          const unReadList = {
            id: item._id,
            name: item.name === "" ? "DummyName" : item.name,
            conversationtype: item.type,
            profileUrl: !item.profile_url ? "" : item.profile_url,
            lastMessage: item.last_message,
            unreadCount: item?.unread_count,
          };
          return setUnreadArr((prev) => {
            return [...prev, unReadList];
          });
        } else {
          return [];
        }
      });
    }
    // eslint-disable-next-line
  }, [getUserConversationData, getUserConversationLoading, getUserConversationError]);

  return (
    <Grid>
      <ListHeader setOpenContactList={props.setOpenContactList} />
      <ListChats chatList={allMessage} handleChat={props.handleChat} />
    </Grid>
  );
};

export default InboxList;
