import { Box, Drawer as MuiDrawer, Typography, IconButton, Collapse, Grid, useMediaQuery, Avatar, Badge, Tooltip } from "@mui/material";
import { PushPin, ExpandMore, Add, Settings, PushPinOutlined } from "@mui/icons-material";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { Transition } from "react-transition-group";

import VNLogo from "../../assets/icons/logo.png";
import { MainLeftDrawerProps } from "./types";
import NavMenuRenderer from "../NavMenuRenderer";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext, useWorkspacesContext } from "../../contexts";
import { isOverflowing, noop } from "../../utilities";
import CustomTooltip from "../CustomTooltip";
import { useMutation } from "@apollo/client";
import { UPDATE_IS_PINNED, UPDATE_LAST_ACTIVE_WORKSPACE } from "../../apollo/mutations";
import { userClient } from "../../apollo/client";
import { useVMessengerContext } from "../../contexts";
import GmailLogo from "../../assets/icon-components/GmailLogo";
import OutlookLogo from "../../assets/icon-components/OutlookLogo";
import PopperWithArrow from "../PopperWithArrow";
import { useStyles } from "./styles";
import { REACT_APP_DOMAIN_ID } from "../../utilities/constants";

const defaultStyle = {
  transition: "width 195ms ease-in-out, height 195ms ease-in-out",
  width: "60px",
  height: "60px",
};
const transitionStyles = {
  entering: { width: "60px", height: "60px" },
  entered: { width: "50px", height: "50px" },
  exiting: { width: "50px", height: "50px" },
  exited: { width: "60px", height: "60px" },
  unmounted: { width: "50px", height: "50px" },
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: 280,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 5px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 5px)`,
  },
  [theme.breakpoints.down("sm")]: {
    // width: `calc(${theme.spacing(8)} + 5px)`,
    width: 0,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: 280,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MainLeftDrawer = (props: MainLeftDrawerProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const nodeRef = useRef(null);
  const expandMoreRef = useRef<SVGElement>();
  const [popperAnchorEl, setPopperAnchorEl] = useState<HTMLElement | null>(null);
  const isXsSize = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    authState: { user_id, workspace_pinned_status },
    authDispatch,
  } = useAuthContext();

  const {
    workspacesState: { active_workspace, list },
  } = useWorkspacesContext();

  if (props.injectWorkspaces) {
    var { workspacesDispatch } = useWorkspacesContext.call({});
  }
  var {
    vmessengerState: { connected_accounts, active_account },
  } = useVMessengerContext.call({});
  const [toggleWorkspacesList, setToggleWorkspacesList] = useState<boolean>(false);
  const [toggleAccountsList, setToggleAccountsList] = useState<boolean>(false);
  const [workspaceTitleOverflow, setWorkspaceTitleOverflow] = useState<boolean>(false);
  const workspaceTitleRef = useRef<HTMLParagraphElement>();
  const [updateLastActiveWorkspace, { data: updateLastActiveWorkspaceData, loading: updateLastActiveWorkspaceLoading, error: updateLastActiveWorkspaceError }] =
    useMutation(UPDATE_LAST_ACTIVE_WORKSPACE, { client: userClient });
  const [updateIsPinned, { data: updateIsPinnedData, loading: updateIsPinnedLoading, error: updateIsPinnedError }] = useMutation(UPDATE_IS_PINNED, {
    client: userClient,
  });

  const updateIsPinnedMemoized = props.injectWorkspaces
    ? useCallback.call(
        {},
        () => {
          updateIsPinned({
            variables: {
              isPinned: !workspace_pinned_status,
              userId: user_id,
            },
          });
          // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [workspace_pinned_status]
      )
    : noop;

  useEffect(() => {
    if (workspaceTitleRef.current) {
      setWorkspaceTitleOverflow(isOverflowing(workspaceTitleRef.current as HTMLParagraphElement));
    }
  }, []);

  useEffect(() => {
    if (!props.open) {
      expandMoreRef.current?.classList.toggle("expand");
      setToggleWorkspacesList(false);
      setToggleAccountsList(false);
    }
  }, [props.open]);

  useEffect(() => {
    if (updateIsPinnedData?.updateUserSettings?.status && updateIsPinnedData?.updateUserSettings?.data) {
      authDispatch({ type: "SET_WORKSPACE_PINNED_STATUS", payload: updateIsPinnedData?.updateUserSettings?.data?.is_pinned });
    }
  }, [updateIsPinnedData, updateIsPinnedLoading, updateIsPinnedError, authDispatch]);

  useEffect(() => {
    if (updateLastActiveWorkspaceData?.updateUserSettings?.status && updateLastActiveWorkspaceData?.updateUserSettings?.data) {
      workspacesDispatch({
        type: "SET_ACTIVE_WORKSPACE",
        payload: list.filter((workspace) => workspace.id === updateLastActiveWorkspaceData?.updateUserSettings?.data?.last_active_workspace_id)[0],
      });
      setToggleWorkspacesList(false);
      expandMoreRef.current?.classList.toggle("expand");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateLastActiveWorkspaceData, updateLastActiveWorkspaceError, updateLastActiveWorkspaceLoading]);

  useEffect(() => {
    if (workspace_pinned_status !== null) {
      props.setIsDrawerUnpinned(!workspace_pinned_status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace_pinned_status]);

  if (props.injectWorkspaces) {
    useEffect.call(
      {},
      () => {
        if (active_workspace) {
          if (REACT_APP_DOMAIN_ID === "1" && window.location.pathname === "/") {
            if (active_workspace?.is_personal) {
              navigate("/overview", { replace: true });
            } else {
              navigate("/allprofiles", { replace: true });
            }
          }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },
      [active_workspace]
    );
  }
  return props.isVMessenger && connected_accounts && !Object.keys(connected_accounts).length ? (
    <></>
  ) : (
    <Drawer
      PaperProps={{
        variant: "primary",
        sx: {
          borderRight: "none",
          overflow: "hidden",
          boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 8px 0px 0px rgb(0 0 0 / 12%)",
          height: "calc(100vh - 70px)",
          maxHeight: "calc(100vh - 70px)",
          marginTop: "70px",
          zIndex: 1200,
          background: theme.palette.card.main,
        },
      }}
      sx={{ zIndex: 1700 }}
      variant={"permanent"}
      open={props.open}
    >
      <Box
        onMouseEnter={() => {
          if (props.injectWorkspaces) {
            props.setShowDrawer(true);
          }
        }}
        onMouseLeave={() => {
          if (props.injectWorkspaces && !workspace_pinned_status) {
            props.setShowDrawer(false);
          }
        }}
        sx={{
          padding: "5px",
          // Firefox scroll-bar appearance fix. scrollbar-width is still expreimental
          scrollbarWidth: "none",
          overflowY: "scroll",
          overflowX: "hidden",
          height: "100%",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        {props.injectWorkspaces ? (
          <Box component={"div"} position={"relative"}>
            <Grid container component={"div"} display={"flex"} alignItems={"center"} spacing={1} mb={0.8} flexWrap={"nowrap"}>
              <Grid item xs={props.open && !isXsSize ? 10 : 12}>
                <Grid
                  onClick={() => {
                    if (props.open) {
                      expandMoreRef.current?.classList.toggle("expand");
                      setToggleWorkspacesList((prev) => !prev);
                    }
                  }}
                  className={"Workspaces-Box"}
                >
                  <img
                    src={active_workspace?.icon_url || VNLogo}
                    alt={"logo"}
                    style={{ objectFit: "cover", borderRadius: "100%", height: "30px", width: "30px", border: `1px solid ${theme.palette.divider}` }}
                  />
                  {props.open && (
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexGrow={1} overflow={"hidden"} gap={1}>
                      <CustomTooltip
                        disableHoverListener={!workspaceTitleOverflow}
                        sx={{ backgroundColor: "black", borderRadius: "4px" }}
                        content={active_workspace?.name ?? "Error Loading Workspace"}
                      >
                        <Grid container>
                          <Grid item xs={12}>                        
                            <Typography
                              ref={(instance: HTMLParagraphElement | null) => {
                                if (instance) workspaceTitleRef.current = instance;
                              }}
                              noWrap
                            >
                              {active_workspace?.name ?? "Error Loading Workspace"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CustomTooltip>
                      <IconButton sx={{ padding: 0 }}>
                        <ExpandMore
                          className={`${classes.expand} transition`}
                          ref={(instance) => {
                            if (instance) expandMoreRef.current = instance;
                          }}
                        />
                      </IconButton>
                    </Box>
                  )}
                </Grid>
              </Grid>
              {!isXsSize && props.open && (
                <Grid item xs={0.8}>
                  <IconButton
                    onClick={() => {
                      updateIsPinnedMemoized();
                    }}
                  >
                    {workspace_pinned_status ? <PushPin /> : <PushPinOutlined />}
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Collapse timeout={100} in={props.open && toggleWorkspacesList} sx={{ position: "absolute", zIndex: theme.zIndex.drawer + 1 }}>
              <Grid
                container
                display={"flex"}
                flexDirection={"column"}
                width={"100%"}
                alignItems={"flex-start"}
                className={"Workspaces-Box"}
                sx={{
                  minWidth: "270px",
                  maxWidth: "270px",
                  borderRadius: "4px",
                  padding: "8px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid item width={"100%"} sx={{ display: "flex", margin: "8px 0 4px 0", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography fontSize={"14px"}>Workspaces</Typography>
                  <IconButton
                    sx={{ p: 0, "&:hover": { backgroundColor: "#00000033" }, borderRadius: "5px" }}
                    onClick={() => {
                      navigate("/workspaces/createWorkspace");
                      setToggleWorkspacesList(false);
                      expandMoreRef.current?.classList.toggle("expand");
                      if (isXsSize) {
                        props.setShowDrawer(false);
                      }
                    }}
                  >
                    <Add sx={{ fontSize: "20px" }} />
                  </IconButton>
                </Grid>
                <Grid className="Divider" sx={{ height: "1px", width: "100%" }} />
                <Grid
                  item
                  width={"100%"}
                  sx={{
                    position: "relative",
                    maxHeight: "200px",
                    overflowY: "auto",
                    mt: "4px",
                    scrollbarWidth: "6px",
                  }}
                >
                  {list.map((workspace, index) => (
                    <Grid
                      container
                      key={index}
                      className={"Workspace-Item"}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        textAlign: "center",
                        fontSize: "14px",
                        margin: "5px 0 5px 0",
                        paddingLeft: "5px",
                        borderRadius: "4px",
                        cursor: "pointer",
                        color: "rgba(0, 0, 0, 0.7)",
                        "&:hover": { color: "rgba(0, 0, 0)" },
                      }}
                      onClick={() => {
                        workspacesDispatch({ type: "SET_ACTIVE_WORKSPACE", payload: list[index] });
                        authDispatch({ type: "SET_LAST_ACTIVE_WORKSPACE_ID", payload: list[index].id });
                        updateLastActiveWorkspace({
                          variables: {
                            userId: user_id,
                            lastActiveWorkspaceId: workspace?.id,
                          },
                        });
                        if (workspace?.is_personal) {
                          if (isXsSize) {
                            props.setShowDrawer(false);
                          }
                          if (REACT_APP_DOMAIN_ID === "1") {
                            navigate("/overview");
                          } else {
                            navigate("/");
                          }
                        }
                      }}
                    >
                      <Typography
                        fontSize="14px"
                        color={active_workspace?.id === workspace?.id ? theme.palette.text.primary : theme.palette.text.disabled}
                        sx={{ "&:hover": { color: theme.palette.text.primary } }}
                        onClick={() => {
                            if (REACT_APP_DOMAIN_ID === "1") {
                                navigate("/allprofiles");
                            if (isXsSize) {
                                props.setShowDrawer(false);
                              }
                            }
                        }}
                      >
                        {workspace.name}
                      </Typography>
                      {!workspace?.is_personal && active_workspace?.id === workspace?.id && (
                        <IconButton
                          sx={{ width: "20px", height: "20px", cursor: "pointer", "&:hover": { backgroundColor: "#00000033" }, borderRadius: "5px" }}
                          onClick={() => {
                            if (isXsSize) {
                              props.setShowDrawer(false);
                            }
                            navigate(`/workspaces/${workspace.id}/preferences`);
                          }}
                        >
                          <Settings sx={{ fontSize: "18px", cursor: "pointer" }} />
                        </IconButton>
                      )}
                    </Grid>
                  ))}
                </Grid>
                <Grid item width={"100%"} sx={{ display: "flex", margin: "8px 0 4px 0", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography fontSize={"14px"}>Teams</Typography>
                </Grid>
                <Grid className="Divider" sx={{ height: "1px", width: "100%" }} />
                <Grid
                  item
                  width={"100%"}
                  sx={{
                    position: "relative",
                    maxHeight: "200px",
                    overflowY: "auto",
                    mt: "4px",
                    scrollbarWidth: "6px",
                  }}
                >
                  {list.map((workspace, index) => (
                    <Grid
                      container
                      key={index}
                      className={"Workspace-Item"}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        textAlign: "center",
                        fontSize: "14px",
                        margin: "5px 0 5px 0",
                        paddingLeft: "5px",
                        borderRadius: "4px",
                        cursor: "pointer",
                        color: "rgba(0, 0, 0, 0.7)",
                        "&:hover": { color: "rgba(0, 0, 0)" },
                      }}
                      // onClick={() => {
                      //   workspacesDispatch({ type: "SET_ACTIVE_WORKSPACE", payload: list[index] });
                      //   authDispatch({ type: "SET_LAST_ACTIVE_WORKSPACE_ID", payload: list[index].id });
                      //   updateLastActiveWorkspace({
                      //     variables: {
                      //       userId: user_id,
                      //       lastActiveWorkspaceId: workspace?.id,
                      //     },
                      //   });
                      //   if (workspace?.is_personal) {
                      //     if (isXsSize) {
                      //       props.setShowDrawer(false);
                      //     }
                      //     if (REACT_APP_DOMAIN_ID === "1") {
                      //       navigate("/overview");
                      //     } else {
                      //       navigate("/");
                      //     }
                      //   }
                      // }}
                    >
                     <Tooltip 
                        title={
                        <Grid item>
                          <Typography
                          fontSize="12px"
                          color={theme.palette.text.secondary}
                        >
                          Team ID: {workspace.team_id}
                        </Typography>
                        </Grid>} 
                        placement="right"
                      >
                        <Typography
                          fontSize="14px"
                          color={active_workspace?.id === workspace?.id ? theme.palette.text.primary : theme.palette.text.disabled}
                          sx={{ "&:hover": { color: theme.palette.text.primary } }}
                          // onClick={() => {
                          //   if (REACT_APP_DOMAIN_ID === "1") {
                          //     navigate("/allprofiles");
                          //     if (isXsSize) {
                          //       props.setShowDrawer(false);
                          //     }
                          //   }
                          // }}
                        >
                          {workspace.team_name}
                        </Typography>
                      </Tooltip>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Collapse>
          </Box>
        ) : null}
        {props.isVMessenger ? (
          <Box minHeight={"60px"} maxHeight={"300px"}>
            <Grid
              className={"Accounts-Box"}
              component={"div"}
              sx={{ "&.Accounts-Box": { ...(!props.open && { borderColor: "transparent", backgroundColor: "transparent" }) } }}
            >
              <Box display={"flex"} sx={{ width: "100%" }} alignItems={"center"} gap={1} flexWrap={"nowrap"} component={"div"}>
                <Transition nodeRef={nodeRef} in={props.open} timeout={0}>
                  {(state) => (
                    <Badge
                      sx={{ right: 2 }}
                      overlap="circular"
                      badgeContent={<GmailLogo width={"15px"} height={"15px"} />}
                      color={"primary"}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                    >
                      <Avatar
                        onMouseOver={(event) => {
                          if (!props.open) setPopperAnchorEl(event.currentTarget);
                        }}
                        onMouseLeave={(event) => {
                          if (!props.open) setPopperAnchorEl(null);
                        }}
                        style={{
                          ...defaultStyle,
                          ...transitionStyles[state],
                          marginLeft: props.open ? "5px" : 0,
                        }}
                        ref={nodeRef}
                        src={active_account?.profile_pic_url ?? ""}
                      />
                    </Badge>
                  )}
                </Transition>
                {props.open && (
                  <Box display={"flex"} justifyContent={"space-between"} width={"100%"} alignItems={"center"}>
                    <Grid className={"On-Dark"} display={"flex"} height={"100%"} p={"2px"} flexDirection={"column"}>
                      <Typography sx={{ fontSize: "10px", color: "#fffffff2" }}>Calvin Smilga</Typography>
                      <Typography sx={{ fontSize: "10px", color: "#fffffff2" }}>calvin.smilga@gmail.com</Typography>
                      <Box display={"flex"} gap={"5px"} width={"100%"} alignItems={"center"}>
                        <Typography sx={{ fontSize: "10px", color: "#fffffff2" }}>Unread 23</Typography>
                        <Typography sx={{ color: "#fffffff2" }}>•</Typography>
                        <Typography sx={{ fontSize: "10px", color: "#fffffff2" }}>Total 500</Typography>
                      </Box>
                    </Grid>
                    <IconButton
                      onClick={() => {
                        expandMoreRef.current?.classList.toggle("expand");
                        setToggleAccountsList((prev) => !prev);
                      }}
                      sx={{ height: "40px", width: "40px", mr: "2px" }}
                    >
                      <ExpandMore
                        className={`${classes.expand} transition On-Dark`}
                        ref={(instance) => {
                          if (instance) expandMoreRef.current = instance;
                        }}
                      />
                    </IconButton>
                  </Box>
                )}
              </Box>
              <Collapse sx={{ alignSelf: "center", width: "95%" }} timeout={255} in={props.open && toggleAccountsList}>
                <Box mt={0.5} mb={0.8} sx={{ backgroundColor: "rgba(255, 255, 255, 0.2)", height: "1px", width: "100%", alignSelf: "center" }} />
                <Box display={"flex"} flexDirection={"column"} gap={1} mb={1} sx={{ overflowY: "scroll" }}>
                  {connected_accounts["gmail"].map((account, index) => (
                    <Box display={"flex"} gap={1} pl={"2px"} key={index}>
                      <Badge
                        sx={{ right: 2 }}
                        overlap="circular"
                        badgeContent={<GmailLogo width={"10px"} height={"10px"} />}
                        color={"primary"}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                      >
                        <Avatar src={account?.profile_pic_url ?? ""} />
                      </Badge>
                      <Box display={"flex"} ml={"2px"} flexDirection={"column"} justifyContent={"center"}>
                        <Typography sx={{ fontSize: "10px", color: "#fffffff2" }}>{account?.account_name}</Typography>
                        <Typography sx={{ fontSize: "10px", color: "#fffffff2" }}>{account?.email}</Typography>
                      </Box>
                    </Box>
                  ))}
                  {connected_accounts["outlook"].map((account: any, index: number) => (
                    <Box display={"flex"} gap={1} pl={"2px"} key={index}>
                      <Badge
                        sx={{ right: 2 }}
                        overlap="circular"
                        badgeContent={<OutlookLogo width={"10px"} height={"10px"} />}
                        color={"primary"}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                      >
                        <Avatar src={account?.profile_pic_url ?? ""} />
                      </Badge>
                      <Box display={"flex"} ml={"2px"} flexDirection={"column"} justifyContent={"center"}>
                        <Typography sx={{ fontSize: "10px", color: "#fffffff2" }}>{account.account_name}</Typography>
                        <Typography sx={{ fontSize: "10px", color: "#fffffff2" }}>{account?.email}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Collapse>
            </Grid>
          </Box>
        ) : null}
        <NavMenuRenderer
          activeWorkspaceType={props.injectWorkspaces ? (active_workspace?.is_personal ? 1 : 2) : 1}
          domainId={props.domainId}
          isDrawerOpen={props.open}
          isXsSize={isXsSize}
          setShowDrawer={props.setShowDrawer}
        />
        <PopperWithArrow PaperProps={{ variant: "elevation" }} placement="right" open={Boolean(popperAnchorEl) && !props.open} anchorEl={popperAnchorEl}>
          <Box sx={{ padding: "5px 16px", display: "flex", flexDirection: "column", borderRadius: "8px" }}>
            <Box display={"flex"} gap={1}>
              <Typography variant="opacity70-12px">Name:</Typography>
              <Typography variant="opacity70-12px">Calvin Smilga</Typography>
            </Box>
            <Box display={"flex"} gap={1}>
              <Typography variant="opacity70-12px">Email:</Typography>
              <Typography variant="opacity70-12px">calvin.smilga@gmail.com</Typography>
            </Box>
            <Box display={"flex"} gap={1}>
              <Typography variant="opacity70-12px">Total Emails:</Typography>
              <Typography variant="opacity70-12px">500</Typography>
            </Box>
            <Box display={"flex"} gap={1}>
              <Typography variant="opacity70-12px">Unread Emails:</Typography>
              <Typography variant="opacity70-12px">100</Typography>
            </Box>
          </Box>
        </PopperWithArrow>
      </Box>
    </Drawer>
  );
};

export default MainLeftDrawer;
