const authReducer = (state: Auth.State, action: Auth.Action): Auth.State => {
  const copy = { ...state };
  switch (action.type) {
    case "SET_ACCESS_TOKEN":
      copy.access_token = action.payload as string;
      return copy;
    case "SET_ACCESS_TOKEN_EXP":
      copy.access_token_exp = action.payload as number;
      return copy;
    case "SET_ROLE_ID":
      copy.role_id = action.payload as number;
      return copy;
    case "SET_USER_ID":
      copy.user_id = action.payload as number;
      return copy;
    case "SET_AUTH_KEY":
      copy.auth_key = action.payload as string;
      return copy;
    case "SET_REDIRECT_SIDE_MENU":
      copy.redirect_side_menu = action.payload as string;
      return copy;
    case "SET_LAST_ACTIVE_WORKSPACE_ID":
      copy.last_active_workspace_id = action.payload as number;
      return copy;
    case "SET_WORKSPACE_PINNED_STATUS":
      copy.workspace_pinned_status = action.payload as boolean;
      return copy;
    case "SET_PROFILE_PICTURE_URL":
      copy.profile_picture_url = action.payload as string;
      return copy;
    default:
      return state;
  }
};

export default authReducer;
