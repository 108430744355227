import { Box } from "@mui/material";
import React from "react";
import TemplateCard from "./TemplateCard";
import TemplateScratch from "./TemplateScratch";

const TemplateRow = () => {
  return (
    <Box
      p={2}
      maxWidth="100vw"
      display={"flex"}
      flexDirection="row"
      overflow={"scroll"}
    >
      <TemplateScratch />
      <TemplateCard template={"any"} />
      <TemplateCard template={"any"} />
      <TemplateCard template={"any"} />
      <TemplateCard template={"any"} />
      <TemplateCard template={"any"} />
      <TemplateCard template={"any"} />
      <TemplateCard template={"any"} />
      <TemplateCard template={"any"} />
      <TemplateCard template={"any"} />
      <TemplateCard template={"any"} />
      <TemplateCard template={"any"} />
      <TemplateCard template={"any"} />
      <TemplateCard template={"any"} />
    </Box>
  );
};

export default TemplateRow;
