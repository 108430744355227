import { gql } from "@apollo/client";

export const GET_SSO_KEY_LOGIN = gql`
  mutation AuthSSO($userId: Int!, $authKey: String) {
    authSSO(user_id: $userId, auth_key: $authKey) {
      code
      status
      msg
      data
    }
  }
`;

export const GET_SSO_AUTH_KEY = gql`
  mutation AuthKeyForSSO($userId: Int!) {
    authKeyForSSO(user_id: $userId) {
      code
      status
      msg
      data
    }
  }
`;

export const UPDATE_IS_PINNED = gql`
  mutation UpdateUserSettings($userId: Int!, $isPinned: Boolean) {
    updateUserSettings(user_id: $userId, is_pinned: $isPinned) {
      code
      status
      msg
      data
    }
  }
`;

export const UPDATE_THEME_PREFERENCE = gql`
  mutation UpdateUserSettings($userId: Int!, $themeId: Int) {
    updateUserSettings(user_id: $userId, theme_id: $themeId) {
      code
      status
      msg
      data
    }
  }
`;

export const UPDATE_LAST_ACTIVE_WORKSPACE = gql`
  mutation UpdateUserSettings($userId: Int!, $lastActiveWorkspaceId: Int) {
    updateUserSettings(user_id: $userId, last_active_workspace_id: $lastActiveWorkspaceId) {
      code
      status
      msg
      data
    }
  }
`;

export const ADD_ONE_MEMBER_TO_TEAM_WORKSPACE = gql`
  mutation AddOneMemberToTeamWorkspace($input: TeamMemberInput!) {
    addOneMemberToTeamWorkspace(input: $input) {
      code
      status
      msg
      data
    }
  }
`;

export const ADD_ONE_MEMBER_TO_WORKSPACE = gql`
  mutation Mutation($workspaceId: Int!, $userId: Int!, $createdByUserId: Int!) {
    addOneMemberToWorkspace(workspace_id: $workspaceId, user_id: $userId, created_by_user_id: $createdByUserId) {
      data
    }
  }
`;

export const REMOVE_ONE_MEMBER_FROM_TEAM_WORKSPACE = gql`
  mutation Mutation($input: TeamMemberInput!) {
    removeOneMemberFromTeamWorkspace(input: $input) {
      id
    }
  }
`;

export const UPDATE_ONE_MEMBER_FROM_TEAM_WORKSPACE = gql`
  mutation UpdateOneTeamMember($team_id: Int!, $member_id: Int!, $permission: String!) {
    updateOneTeamMember(team_id: $team_id, member_id: $member_id, permission: $permission) {
      code
      status
      msg
      data
    }
  }
`;

export const UPDATE_WORKSPACE = gql`
  mutation UpdateUserWorkspace($updateUserWorkspaceId: Int!, $name: String!, $iconUrl: String) {
    updateUserWorkspace(id: $updateUserWorkspaceId, name: $name, icon_url: $iconUrl) {
      code
      status
      msg
      data
    }
  }
`;

export const PURGE_USER_WORKSPACE = gql`
  mutation DeleteUserWorkspace($deleteUserWorkspaceId: Int!) {
    deleteUserWorkspace(id: $deleteUserWorkspaceId) {
      code
      status
      msg
      data
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($updateCompanyId: Int!, $userId: Int!, $legalName: String, $businessNumber: String, $address: String, $cityId: Int) {
    UpdateCompany(id: $updateCompanyId, user_id: $userId, legal_name: $legalName, business_number: $businessNumber, address: $address, city_id: $cityId) {
      code
      status
      msg
      data
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization($updateOrganizationId: Int!, $name: String, $domain: String) {
    UpdateOrganization(id: $updateOrganizationId, name: $name, domain: $domain) {
      code
      status
      msg
      data
    }
  }
`;

export const LEAVE_USER_WORKSPACE = gql`
  mutation LeaveUserWorkspace($team_id: Int!) {
    leaveUserWorkspace(team_id: $team_id) {
      code
      status
      msg
      data
    }
  }
`;

export const CREATE_ONE_WORKSPACE = gql`
  mutation Mutation($input: WorkspaceInput) {
    createOneWorkspace(input: $input) {
      team_ids
      id
      is_personal
      company_id
      created_by_user_id
      subscription_id
      payment_method_id
      credit_balance
      created_ts
      updated_ts
      icon_url
      name
      organization_id
      owner_id
      admin_id
      is_purged
    }
  }
`;

export const CREATE_ONE_ORGANIZATION = gql`
  mutation Mutation($name: String!, $domain: String!, $displayName: String!) {
    createOneOrganization(name: $name, domain: $domain, display_name: $displayName) {
      id
      name
      created_ts
      updated_ts
      domain
      created_by_user_id
      owner_id
      admin_id
      display_name
    }
  }
`;

export const CREATE_ONE_COMPANY = gql`
  mutation CreateOneCompany($input: CompanyInput) {
    createOneCompany(input: $input) {
      id
      legal_name
      owner_id
      created_by_user_id
      created_ts
      updated_ts
      business_number
      address
      city
      state
      country
    }
  }
`;

export const CREATE_API_KEY = gql`
  mutation CreateApiKey($expirationDate: String!, $teamId: Int!) {
    createApiKey(expiration_date: $expirationDate, team_id: $teamId) {
      code
      status
      msg
      data
    }
  }
`;

export const CREATE_CONVERSATION = gql`
  mutation CreateConversation($otherMembers: [Member], $name: String, $conversationPicUrl: String, $type: ConversationEnumType) {
    createConversation(other_members: $otherMembers, name: $name, conversation_pic_url: $conversationPicUrl, type: $type) {
      code
      message
      status
      data {
        newConv {
          users {
            user_id
            user_name
            user_profile_pic_url
            is_admin
          }
          name
          profile_url
          type
          unread_count
          _id
          updated_at
          created_at
          last_message {
            _id
            conversationId
            senderId
            senderName
            msg
            messageType
            url
            createdAt
            status
          }
        }
      }
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation SendMessage($conversationId: String!, $message: String!, $messageType: MessageTypeEnum, $url: String) {
    sendMessage(conversation_id: $conversationId, message: $message, message_type: $messageType, url: $url) {
      code
      message
      status
      data {
        row {
          _id
          conversationId
          senderId
          senderName
          msg
          messageType
          url
          createdAt
          status
        }
      }
    }
  }
`;

export const REINVITE_MEMBER_TO_WORKSPACE_MUTATION = gql`
  mutation ReInviteMemberToTeamWorkspace($input: ReInviteMemberInput!) {
    reInviteMemberToTeamWorkspace(input: $input) {
      code
      status
      msg
      data
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout($loggedIn: Boolean!) {
    logout(loggedIn: $loggedIn) {
      data
    }
  }
`;

export const DELETE_USER_NOTIFICATION = gql`
    mutation Delete_user_notification($deleteUserNotificationId: Int!) {
        delete_user_notification(id: $deleteUserNotificationId) {
            code
            status
            msg
            data
        }
    }
`;

export const UPDATE_USER_NOTIFICATION = gql`
    mutation Update_user_notification($updateUserNotificationId: Int!, $isRead: Boolean) {
        update_user_notification(id: $updateUserNotificationId, is_read: $isRead) {
            code
            data
            msg
            status
        }
    }
`;

export const LEAVE_GROUP = gql`
  mutation LeaveGroup($conversationId: String!) {
    leaveGroup(conversation_id: $conversationId)
  }
`;

export const REMOVE_MEMBER = gql`
  mutation RemoveUserFromGroup(
    $conversationId: String!, $user: Member
  ) {
    removeUserFromGroup(
      conversation_id: $conversationId, 
      user: $user
    )
  }
`;

export const INVITE_TO_GROUP = gql`
mutation InviteToGroup($conversationId: String!, $invitedUsers: [Member]) {
  inviteToGroup(conversation_id: $conversationId, invited_users: $invitedUsers)
}
`;

export const MUTE_CONVERSATION = gql`
mutation MuteConversation($conversationId: String!, $muteUntil: String, $isMute: Boolean) {
  muteConversation(conversation_id: $conversationId, mute_until: $muteUntil, is_mute: $isMute)
}
`;

export const UPDATE_USER_WORKSPACE_CONNECT_CONFIGS = gql`
mutation UpdateUserWorkspaceConnectConfigs($updateUserWorkspaceConnectConfigsId: Int!, $configDetails: WorkspaceConfigDetailsInput!) {
  updateUserWorkspaceConnectConfigs(id: $updateUserWorkspaceConnectConfigsId, config_details: $configDetails) {
    code
    data
    msg
    status
  }
}
`;