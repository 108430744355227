import { SvgIcon, SvgIconProps } from "@mui/material";

const VNLogo = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 33 32" fill="white" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5625 0V0.605966H10.3731L19.4005 24.0676L28.6008 0.605966H24.6723V0H33V0.605966H29.2536L16.9639 32H15.6992L3.66277 0.605969H0V0H14.5625Z"
        fill="inherit"
      />
    </SvgIcon>
  );
};

export default VNLogo;
