import React, { useReducer } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";

import { dark, light } from "../theme";
import { themePreferenceReducer } from "../context-reducers";
import { createContext, useSystemThemeDetector } from "../hooks";
import { REACT_APP_DOMAIN_ID } from "../utilities/constants";
import { SecureDark, SecureLight } from "../theme/SecureTheme";

let initialState: ThemePreference.State = {
  preference: useSystemThemeDetector.call({}),
};

export const [useThemePreferenceContext, ThemePreferenceProvider] = createContext<ThemePreference.ContextType>();

export const ThemePreferenceContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [themePreferenceState, themePreferenceDispatch] = useReducer(themePreferenceReducer, initialState);

  return (
    <ThemePreferenceProvider value={{ themePreferenceState, themePreferenceDispatch }}>
      <ThemeProvider
        theme={((theme) => {
          switch (theme) {
            case "dark":
              return REACT_APP_DOMAIN_ID === "1" ? SecureDark : dark;
            case "light":
              return REACT_APP_DOMAIN_ID === "1" ? SecureLight : light;
          }
        })(themePreferenceState.preference)}
      >
        <CssBaseline enableColorScheme />
        {children}
      </ThemeProvider>
    </ThemePreferenceProvider>
  );
};
