const vmessengerReducer = (state: VMessenger.State, action: VMessenger.Action) => {
  const copy = { ...state };
  switch (action.type) {
    case "SET_ACTIVE_ACCOUNT":
      copy.active_account = action.payload;
      return copy;
    case "SET_CONNECTED_ACCOUNTS":
      copy.connected_accounts = action.payload as Record<string, Array<any>>;
      return copy;
    default:
      return copy;
  }
};

export default vmessengerReducer;
