import { makeStyles } from "@mui/styles";

export const useStyles: any = makeStyles(() => ({
  card: {
    minWidth: "200px",
    height: "200px",
    background: "#1c1c1c",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginRight: "10px",
    marginTop: "10px",
    borderRadius: "5px",
    cursor: "pointer",
  },
}));
