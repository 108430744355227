import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";

export const useStyles = makeStyles(() => {
  const theme = useTheme();

  return {
    navbarContainerNav: {
      width: "100px",
      marginLeft: "65px",
    },
    navbarNav: {
      height: "70px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px",
      width: "100%",
      margin: 0,
      background: theme.palette.card.main,
      zIndex: 1300,
      position: "sticky",
      top: "0",
      boxShadow: "1px 4px 10px 0px #00000012",
    },
    searchNav: {
      display: "flex",
      width: "35vw",
      borderRadius: "5px",
      overflow: "hidden",
      background: theme.palette.background.default,
      // border: `1px solid ${theme.palette.divider}`,
    },
    searchIconNav: {
      width: "40px",
      background: "#282C3A",
      height: "100%",
    },
    searchInputNav: {
      "&.MuiInputBase-root": {
        border: "1px solid transparent !important",
        "&.Mui-focused": {
          border: "1px solid #3075cb !important",
          caretColor: "#3075cb",
          borderRadius: "4px",
          overflow: "hidden",
        },
      },
      // "&.Mui-focused": {
      //   border: "5px solid red !important",
      //   caretColor: "#3075cb",
      //   borderRadius: "4px",
      // },
      color: `${theme.palette.text.primary} !important`,
      borderRadius: "4px",
      height: "45px",
      padding: "10px 8px",
      width: "100%",
    },
    headerRightNav: {
      // width: "190px",
      justifyContent: "flex-end",
      display: "flex",
      alignItems: "center",
      marginRight: "10px",
    },
    headerRightNavMobile: {
      width: "170px",
      justifyContent: "flex-end",
      display: "flex",
      alignItems: "center",
      marginRight: "10px",
    },
    profileAvatarNav: {
      width: "30px !important",
      height: "30px !important",
      borderRadius: "50%",
      overflow: "hidden",
      cursor: "pointer",
      marginLeft: "10px",
    },
    navMenuNav: {
      "& .css-5v69oj-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper": {
        background: `${theme.palette.card.main} !important`,
      },
    },
    menuItemNav: {
      color: `${theme.palette.text.secondary} !important`,
    },
    navMenuIconNav: {
      marginRight: "5px",
      marginTop: "-2px",
      transform: "scale(0.7)",
      color: theme.palette.text.secondary,
    },
    navMenuSwitchNav: {
      marginLeft: "10px",
    },
    serachContainerNav: {
      position: "relative",
      width: "35vw",
    },
    searchSuggestionsNav: {
      position: "absolute",
      top: "45px",
      left: "0px",
      color: theme.palette.text.primary,
      minWidth: "100%",
      maxHeight: "200px",
      overflow: "auto",
      backgroundColor: theme.palette.card.main,
      width: "35vw",
    },
    serachOptionNav: {
      width: "100%",
      cursor: "pointer",
      padding: "10px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        fontWeight: "bold",
      },
      "& img": {
        width: "35px",
        height: "35px",
        objectFit: "cover",
        marginRight: "10px",
      },
    },
    sidebarToggleBtnNav: {
      position: "absolute",
      top: "15px",
      left: "15px",
    },

    // Mobile CSS

    navbarMobile: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      background: theme.palette.card.main,
      height: "70px",
      alignItems: "center",
      padding: "0px 20px",
      position: "sticky",
      top: 0,
      zIndex: 1300,
    },
    navbarContainerMobile: {},
    serachContainerNavMobile: {
      position: "relative",
      width: "100%",
      padding: "0 10px",
    },
    searchNavMobile: {
      display: "flex",
      width: "100%",
      borderRadius: "5px",
      overflow: "hidden",
      background: theme.palette.text.primary,
    },

    menuHoverColorDisable: {
      "& .MuiMenuItem-root:hover": {
        backgroundColor: "transparent",
      },
    },
    pointer: {
      cursor: "pointer",
    },
    icon: {
      cursor: "pointer",
      marginLeft: "0.20rem",
      marginRight: "0.27rem",
    },
    bellMenu: {
      width: "500px",
      maxWidth: "92vw",
    },

    bellMenuItem: {
      padding: "0 !important",
    },

    dividerColor: {
      backgroundColor: theme.palette.divider,
    },
    ImageCss: {
      width: "36px !important",
      height: "36px !important",
      objectFit: "cover",
      objectPosition: "center",
      borderRadius: "50%",
      border: "2px solid #3DACFF",
    },
    bellMenuContainer: {
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.card.main,
      borderRadius: "4px",
      boxShadow: "1px 2px 10px 0px #00000012",
      margin: "4px 0",
      padding: "8px 8px",
      width: "100%",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
    leftGrid: {
      width: "75%",
    },
    rightGrid: {
      width: "25%",
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "column",
    },
    deleteIcon: {
      color: theme.palette.text.secondary,
      fontSize: "16px",
    },
    iconGrey: {
      color: "#616161",
    },
    notificationMsz: {
      width: "20rem",
      overflowWrap: "break-word",
      wordWrap: "break-word",
      wordBreak: "break-word",
    },
    ProfileTransitionCard: {
      background: theme.palette.background.default,
      borderRadius: "8px",
      height: "36px",
      paddingLeft: "26px",
      paddingRight: "10px",
    },
  };
});
