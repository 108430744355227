const themePreferenceReducer = (state: ThemePreference.State, action: ThemePreference.Action): ThemePreference.State => {
  const copy = { ...state };
  switch (action.type) {
    case "SET_THEME_PREFERENCE":
      copy.preference = action.payload;
      return copy;
    default:
      return state;
  }
};

export default themePreferenceReducer;
