import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";

import { GET_USER_EMAIL_CONNECTS } from "../../apollo/queries";
import { userClient } from "../../apollo/client";
import { useVMessengerContext } from "../../contexts/vmessenger-context";
import { useAuthContext } from "../../contexts";
import ScaffoldSkeleton from "../Skeleton";
import { REACT_APP_DOMAIN_ID } from "../../utilities/constants";

const VMessengerInitializer = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const {
    authState: { user_id },
  } = useAuthContext();
  const {
    vmessengerState: { connected_accounts },
    vmessengerDispatch,
  } = useVMessengerContext();
  const {
    data: userEmailConnectsData,
    loading: userEmailConnectsLoading,
    error: userEmailConnectsError,
  } = useQuery(GET_USER_EMAIL_CONNECTS, { client: userClient, variables: { userId: user_id } });

  useEffect(() => {
    if (Object.keys(connected_accounts).length) {
      setLoading(false);
    }
  }, [connected_accounts]);

  useEffect(() => {
    if (userEmailConnectsData && !userEmailConnectsLoading && !userEmailConnectsError) {
      vmessengerDispatch({ type: "SET_CONNECTED_ACCOUNTS", payload: userEmailConnectsData?.getUserEmailConnects?.data });
    }
  }, [userEmailConnectsData, userEmailConnectsLoading, userEmailConnectsError, vmessengerDispatch]);
  return (loading && REACT_APP_DOMAIN_ID !=="7") ? <ScaffoldSkeleton /> : <Box component={"div"}>{children}</Box>;
};

export default VMessengerInitializer;
