const TechLabsLogo = (props: any) => (
  <svg
    width={props.width || "107"}
    height={props.height || "45"}
    viewBox="0 0 107 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 1.36165H2.73142L11.6005 24.8988H12.5324L21.5942 1.36165H24.3578V0.907769H18.2201V1.36165H21.1122L14.3319 18.9335L7.6801 1.36165H10.765V0.907769H0V1.36165Z"
      fill={props.fill || "black"}
    />
    <path
      d="M25.0223 1.36165H27.9787V23.5372H25.0223V23.991H35.5945V23.5372H32.6381V1.36165H35.5945V0.907769H25.0223V1.36165Z"
      fill={props.fill || "black"}
    />
    <path
      d="M40.9361 23.5372H37.9797V23.991H48.5519V23.5372H45.5956V11.8334H47.7164C51.7011 11.8334 51.8617 15.2375 52.0224 18.7714C52.1188 21.0408 52.2795 24.4773 57.0032 24.4773C59.349 24.4773 59.8953 23.8289 60.7629 22.9536L60.4416 22.6618C60.0238 23.0833 59.5097 23.7317 58.6421 23.7317C57.1318 23.7317 57.1639 22.8239 56.7462 17.4097C56.3927 12.7412 53.8862 12.2873 49.7087 11.5416V11.3795C52.6008 11.1526 56.0392 9.82336 56.0392 6.35438C56.0392 2.04248 51.5404 0.810507 48.1663 0.810507C46.3989 0.810507 44.5994 0.907769 42.832 0.907769H37.9797V1.36165H40.9361V23.5372ZM45.5956 1.36165C46.0776 1.32923 46.5274 1.26439 47.0095 1.26439C50.2229 1.26439 51.0905 2.91783 51.0905 6.711C51.0905 9.07769 50.7692 11.3795 46.9131 11.3795H45.5956V1.36165Z"
      fill={props.fill || "black"}
    />
    <path
      d="M59.2102 23.991H65.3157V23.5372H62.4558L66.0227 14.8485H73.7349L77.4625 23.5372H74.474V23.991H85.2711V23.5372H82.5076L72.3853 0H71.5176L61.9416 23.5372H59.2102V23.991ZM73.5742 14.3946H66.1833L69.7824 5.54387L73.5742 14.3946Z"
      fill={props.fill || "black"}
    />
    <path
      d="M85.907 1.36165H88.8634V23.5372H85.907V23.991H106.28V16.664H105.862C104.77 21.7216 101.621 23.5372 98.825 23.5372H93.5228V1.36165H96.4792V0.907769H85.907V1.36165Z"
      fill={props.fill || "black"}
    />
    <path
      d="M1.66827 30.5984H10.7179V31.5212H6.65044V44.5391H5.69682V31.5212H1.66827V30.5984Z"
      fill={props.fill || "black"}
    />
    <path
      d="M14.2361 30.5984H22.7603V31.5212H15.1897V37.0582H22.0013V37.9811H15.1897V43.6163H23.0328V44.5391H14.2361V30.5984Z"
      fill={props.fill || "black"}
    />
    <path
      d="M36.8174 43.6163C36.519 43.7995 36.1752 43.9763 35.786 44.1464C35.3967 44.3166 34.9686 44.4475 34.5015 44.5391C34.0344 44.6438 33.5284 44.6962 32.9835 44.6962C31.9326 44.6962 30.9789 44.5129 30.1226 44.1464C29.2663 43.7668 28.5333 43.2563 27.9235 42.6149C27.3137 41.9604 26.8401 41.2143 26.5028 40.3765C26.1784 39.5257 26.0162 38.6225 26.0162 37.6669C26.0162 36.6459 26.1914 35.6969 26.5417 34.8199C26.892 33.9429 27.385 33.1771 28.0208 32.5226C28.6565 31.8681 29.3961 31.3576 30.2394 30.9911C31.0827 30.6246 31.9974 30.4413 32.9835 30.4413C33.7101 30.4413 34.3912 30.5395 35.027 30.7358C35.6627 30.9322 36.2401 31.1874 36.759 31.5016L36.3503 32.3852C35.8962 32.0972 35.3838 31.855 34.8129 31.6587C34.242 31.4623 33.6387 31.3641 33.0029 31.3641C32.1856 31.3641 31.4136 31.5278 30.687 31.855C29.9605 32.1692 29.3182 32.6142 28.7603 33.1902C28.2154 33.753 27.7872 34.4141 27.4759 35.1733C27.1645 35.9325 27.0088 36.7506 27.0088 37.6277C27.0088 38.4654 27.145 39.2574 27.4175 40.0035C27.7029 40.7496 28.1051 41.4041 28.6241 41.967C29.156 42.5298 29.7918 42.9749 30.5313 43.3021C31.2709 43.6163 32.0947 43.7734 33.0029 43.7734C33.6776 43.7734 34.2939 43.6817 34.8518 43.4985C35.4227 43.3021 35.9287 43.06 36.3698 42.772L36.8174 43.6163Z"
      fill={props.fill || "black"}
    />
    <path
      d="M50.811 30.5984V44.5391H49.8574V37.9811H41.5278V44.5391H40.5742V30.5984H41.5278V37.0779H49.8574V30.5984H50.811Z"
      fill={props.fill || "black"}
    />
    <path
      d="M61.6287 30.5984H62.5823V43.6163H70.1529V44.5391H61.6287V30.5984Z"
      fill={props.fill || "black"}
    />
    <path
      d="M72.3748 44.5391L78.2133 29.9897H78.2911L84.1296 44.5391H83.0592L77.9797 31.5212L78.6414 31.1482L73.3284 44.5391H72.3748ZM75.1967 39.0217H81.3271L81.5996 39.9249H74.9632L75.1967 39.0217Z"
      fill={props.fill || "black"}
    />
    <path
      d="M90.6041 30.5984C91.9405 30.5984 92.972 30.8798 93.6985 31.4427C94.4381 31.9925 94.8078 32.8891 94.8078 34.1327C94.8078 34.7479 94.6911 35.3107 94.4575 35.8213C94.224 36.3318 93.8737 36.7506 93.4066 37.0779C92.9525 37.4051 92.3816 37.6146 91.694 37.7062L91.5967 37.235C92.3103 37.2873 92.959 37.4706 93.5428 37.7847C94.1397 38.0858 94.6132 38.4981 94.9635 39.0217C95.3268 39.5322 95.5085 40.1344 95.5085 40.8281C95.5085 41.4565 95.3852 42.0062 95.1387 42.4775C94.9052 42.9356 94.5808 43.3218 94.1656 43.6359C93.7634 43.937 93.3028 44.1661 92.7838 44.3231C92.2649 44.4671 91.7199 44.5391 91.1491 44.5391H87.5487V30.5984H90.6041ZM91.0518 37.1172C92.0378 37.1172 92.7449 36.8488 93.1731 36.3121C93.6142 35.7754 93.8348 35.1079 93.8348 34.3094C93.8348 33.3145 93.5428 32.6011 92.959 32.1692C92.3881 31.7372 91.6161 31.5212 90.6431 31.5212H88.5023V37.1172H91.0518ZM91.1101 43.6556C91.7329 43.6556 92.3038 43.5574 92.8228 43.361C93.3417 43.1516 93.7569 42.8374 94.0683 42.4186C94.3797 41.9997 94.5354 41.4826 94.5354 40.8674C94.5354 40.1867 94.3602 39.6435 94.0099 39.2377C93.6596 38.8188 93.2185 38.5178 92.6865 38.3345C92.1546 38.1382 91.6096 38.04 91.0518 38.04H88.5023V43.6556H91.1101Z"
      fill={props.fill || "black"}
    />
    <path
      d="M106.163 32.1692C105.761 31.9728 105.268 31.7896 104.684 31.6194C104.1 31.4492 103.516 31.3641 102.933 31.3641C102.024 31.3641 101.298 31.5932 100.753 32.0514C100.208 32.5095 99.9354 33.0855 99.9354 33.7792C99.9354 34.3028 100.091 34.7413 100.403 35.0948C100.714 35.4351 101.123 35.7296 101.629 35.9783C102.135 36.214 102.673 36.443 103.244 36.6656C103.698 36.8357 104.146 37.0255 104.587 37.235C105.041 37.4444 105.45 37.6997 105.813 38.0007C106.176 38.3018 106.462 38.6749 106.669 39.1199C106.89 39.565 107 40.1147 107 40.7692C107 41.5415 106.818 42.2288 106.455 42.8309C106.092 43.4199 105.586 43.8781 104.937 44.2053C104.288 44.5326 103.536 44.6962 102.68 44.6962C101.992 44.6962 101.363 44.6111 100.792 44.4409C100.234 44.2708 99.7408 44.0744 99.3127 43.8519C98.8975 43.6163 98.5666 43.4199 98.3201 43.2629L98.7677 42.4775C99.0532 42.6869 99.397 42.8963 99.7992 43.1058C100.214 43.3152 100.662 43.4919 101.142 43.6359C101.622 43.7799 102.102 43.8519 102.582 43.8519C103.14 43.8519 103.679 43.7406 104.198 43.5181C104.729 43.2956 105.164 42.9618 105.501 42.5167C105.852 42.0586 106.027 41.4826 106.027 40.7889C106.027 40.1213 105.865 39.5846 105.54 39.1788C105.216 38.7599 104.801 38.4196 104.295 38.1578C103.789 37.896 103.25 37.6604 102.68 37.4509C102.238 37.2808 101.797 37.1041 101.356 36.9208C100.915 36.7245 100.513 36.4954 100.15 36.2336C99.7862 35.9587 99.4943 35.6314 99.2737 35.2518C99.0661 34.8722 98.9623 34.4141 98.9623 33.8774C98.9623 33.2098 99.131 32.6273 99.4684 32.1299C99.8057 31.6325 100.26 31.2463 100.831 30.9714C101.415 30.6835 102.076 30.5329 102.816 30.5198C103.464 30.5198 104.12 30.6049 104.781 30.7751C105.456 30.9453 106.04 31.1613 106.533 31.423L106.163 32.1692Z"
      fill={props.fill || "black"}
    />
  </svg>
);

export default TechLabsLogo;
