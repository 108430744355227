import { ToastOptions } from "react-toastify";
import { HmacSHA1 } from "crypto-js";

// GraphQl env constants
export const REACT_APP_USER_API_URL_GRAPHQL = process.env.REACT_APP_USER_API_URL_GRAPHQL;
export const REACT_APP_EMAIL_API_URL_GRAPHQL = process.env.REACT_APP_EMAIL_API_URL_GRAPHQL;
export const REACT_APP_AUTH_API_URL_GRAPHQL = process.env.REACT_APP_AUTH_API_URL_GRAPHQL;
export const REACT_APP_COMMAND_API_URL_GRAPHQL = process.env.REACT_APP_COMMAND_API_URL_GRAPHQL;
export const REACT_APP_FEED_API_URL_GRAPHQL = process.env.REACT_APP_FEED_API_URL_GRAPHQL;
export const REACT_APP_ACADEMY_API_URL_GRAPHQL = process.env.REACT_APP_ACADEMY_API_URL_GRAPHQL;
export const REACT_APP_SECURE_API_URL_GRAPHQL = process.env.REACT_APP_SECURE_API_URL_GRAPHQL;
export const REACT_APP_NOTIFICATION_API_URL_GRAPHQL = process.env.REACT_APP_NOTIFICATION_API_URL_GRAPHQL;
export const REACT_APP_USER_WEB_SOCKET_URL_GRAPHQL = process.env.REACT_APP_USER_WEB_SOCKET_URL_GRAPHQL;
export const REACT_APP_CHAT_API_URL_GRAPHQL = process.env.REACT_APP_CHAT_API_URL_GRAPHQL;
export const REACT_APP_CHAT_WEB_SOCKET_URL_GRAPHQL: string = process.env.REACT_APP_CHAT_WEB_SOCKET_URL_GRAPHQL as string;
export const REACT_APP_PAYMENT_GRAPHQL_URL = process.env.REACT_APP_PAYMENT_GRAPHQL_URL;
export const REACT_APP_TALENT_GRAPHQL_URL = process.env.REACT_APP_TALENT_GRAPHQL_URL;
export const REACT_APP_NEWS_API_URL_GRAPHQL: string = "https://api.poc.graphql.dev.vnplatform.com/graphql";
export const REACT_APP_NOTIFICATION_WEB_SOCKET_URL_GRAPHQL: string = process.env.REACT_APP_NOTIFICATION_WEB_SOCKET_URL_GRAPHQL as string;
// export const REACT_APP_COMMUNITY_API_URL_GRAPHQL = process.env.REACT_APP_COMMUNITY_API_URL_GRAPHQL;

// ENV vars
export const REACT_APP_DATAGRID_PRO_LICENSE_KEY = process.env.REACT_APP_DATAGRID_PRO_LICENSE_KEY;
export const REACT_APP_DOMAIN_ID = process.env.REACT_APP_DOMAIN_ID;
export const REACT_APP_AUTH_PLATFORM_URL = process.env.REACT_APP_AUTH_PLATFORM_URL;
export const REACT_APP_VN_ADMIN_URL = process.env.REACT_APP_VN_ADMIN_URL;
export const REACT_APP_VN_PLATFORM_URL = process.env.REACT_APP_VN_PLATFORM_URL;
export const REACT_APP_VN_SECURE_URL = process.env.REACT_APP_VN_SECURE_URL;
export const REACT_APP_VN_TALENT_URL = process.env.REACT_APP_VN_TALENT_URL;
export const REACT_APP_VN_SETTINGS_URL = process.env.REACT_APP_VN_SETTINGS_URL;
export const REACT_APP_VN_MEDIAKIT_URL = process.env.REACT_APP_VN_MEDIAKIT_URL;
export const REACT_APP_CONNECT_EMBED_URL = process.env.REACT_APP_CONNECT_EMBED_URL;
export const ENV = process.env.REACT_APP_ENV;
export const REACT_APP_MEDIA_UPLOAD_API_URL = process.env.REACT_APP_MEDIA_UPLOAD_API_URL;
export const PERSIST_SECRET_KEY = process.env.REACT_APP_REDUX_SECRET_KEY;
export const TOASTIFY_THEME: ToastOptions = { theme: "colored", hideProgressBar: true };
export const AUTH_STORE: string = HmacSHA1("Auth", PERSIST_SECRET_KEY!).toString();
export const WORKSPACE_STORE: string = HmacSHA1("Workspaces", PERSIST_SECRET_KEY!).toString();
export const VMESSENGER_STORE: string = HmacSHA1("VMessenger", PERSIST_SECRET_KEY!).toString();
export const THEME_STORE: string = HmacSHA1("ThemePreference", PERSIST_SECRET_KEY!).toString();
// Domains that make use of workspaces
export const WORKSPACE_DOMAINS = ["1", "2", "3", "4", "5", "7"];
export const DOMAINS_MAP: Record<string, string> = {
  "1": "Secure",
  "2": "Plus",
  "3": "Talent Agency",
  "4": "Admin",
  "5": "Platform",
  "6": "Messenger",
  "7": "Mediakits",
  "8": "TechLabs",
  "9": "Forms",
  "-1": "",
};
// TODO: Add platform descriptions
export const PLATFORM_DESCRIPTION_MAP: Record<string, string> = {
  "1": "Secure",
  "2": "Plus",
  "3": "Talent Agency",
  "4": "Admin",
  "5": "Platform",
  "6": "Messenger",
  "7": "Mediakits",
  "8": "TechLabs",
  "9": "Forms",
};

export const THEME_MAP = { 1: "dark" as "dark", 2: "light" as "light", dark: 1 as 1, light: 2 as 2 };
export const THEME_UPDATE_MAP = { dark: "light" as "light", light: "dark" as "dark" };

export const REACT_APP_IDENTITY_SCIENCE_URL: string = "https://baea9a8fd6-is.identityscience.ai/";
export const REACT_APP_COMMUNITY_API_URL_GRAPHQL: string = "https://api.community.graphql.dev.vnplatform.com/graphql";
