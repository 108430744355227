import { gql } from "@apollo/client";

//Subscribtion
export const CONVERSATION_UPDATED = gql`
  subscription ConversationUpdated {
    conversationUpdated {
      code
      message
      status
      data
    }
  }
`;

export const CHAT_ROOM = gql`
  subscription MessageAdded($conversationId: String) {
    messageAdded(conversation_id: $conversationId) {
      code
      message
      status
      data {
        _id
        conversationId
        senderId
        senderName
        msg
        messageType
        url
        createdAt
        status
      }
    }
  }
`;

export const CHAT_LIST = gql`
  subscription ConversationUpdated {
    conversationUpdated {
      code
      message
      status
      data {
        userConversation {
          _id
          user_id
          name
          profile_picture
          conversation {
            users {
              user_id
              user_name
              user_profile_pic_url
              is_admin
            }
            name
            profile_url
            type
            unread_count
            _id
            updated_at
            created_at
            last_message {
              _id
              conversationId
              senderId
              senderName
              msg
              messageType
              url
              createdAt
              status
            }
          }
        }
      }
    }
  }
`;

export const ACTIVE_LIVE_STREAMS = gql`
  subscription SubscribeActiveLiveStreams {
    subscribeActiveLiveStreams {
      code
      data
      msg
      status
    }
  }
`;

export const NEW_NOTIFICATION = gql`
  subscription NewNotification {
    newNotification {
      code
      status
      msg
      data {
        id
      }
    }
  }
`;
