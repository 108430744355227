import { useEffect, useState } from "react";
import { Collapse, Fade, Grid, Typography } from "@mui/material";
import { DOMAINS_MAP, REACT_APP_DOMAIN_ID } from "../../utilities/constants";
import "./index.css";

const LoginLoader = () => {
  const [InitialLoad, setInitialLoad] = useState<0 | 1 | 2>(0);

  useEffect(() => {
    setTimeout(() => {
      setInitialLoad(1);
    }, 1000);
    setTimeout(() => {
      setInitialLoad(2);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return InitialLoad < 2 ? (
    <Grid container height="100vh" width={"100vw"} justifyContent="center" alignItems="center" sx={{ backgroundColor: "#121212" }}>
      <Grid item mr={1}>
        <Fade in={true} timeout={2000}>
          <Typography color="rgba(255,255,255,0.9)" fontSize="36px" fontWeight={500}>
            VN
          </Typography>
        </Fade>
      </Grid>
      <Grid item>
        <Grid container>
          <Collapse orientation="horizontal" in={Boolean(InitialLoad)} timeout={1000}>
            <Grid item py={1}>
              <Typography color="rgba(20,132,216)" fontSize="36px" fontWeight={500}>
                {DOMAINS_MAP[REACT_APP_DOMAIN_ID!]}
              </Typography>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid container height="100vh" justifyContent="center" alignItems="center" sx={{ backgroundColor: "#121212" }}>
      <Grid item>
        <div className="shineText" style={{ fontSize: "36px" }}>
          VN {DOMAINS_MAP[REACT_APP_DOMAIN_ID!]}
        </div>
      </Grid>
    </Grid>
  );
}

export default LoginLoader;