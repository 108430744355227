/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLazyQuery, useMutation, useSubscription } from "@apollo/client";
import { Apps, ExitToApp, Settings, Telegram } from "@mui/icons-material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import RefreshIcon from "@mui/icons-material/Refresh";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Waypoint } from "react-waypoint";
import Moment from "react-moment";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Chip,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { authClient, notificationClient, notificationSubscriptionClient, userClient } from "../../apollo/client";
import { DELETE_USER_NOTIFICATION, LOGOUT, UPDATE_THEME_PREFERENCE, UPDATE_USER_NOTIFICATION } from "../../apollo/mutations";
import { GET_NOTIFICATIONS } from "../../apollo/queries";
import { NEW_NOTIFICATION } from "../../apollo/subscriptions";
import { useAuthContext, useThemePreferenceContext, useWorkspacesContext } from "../../contexts";
import { useVMessengerContext } from "../../contexts/vmessenger-context";
import { getAccessToken } from "../../main";
import {
  ENV,
  REACT_APP_AUTH_PLATFORM_URL,
  REACT_APP_DOMAIN_ID,
  REACT_APP_VN_SETTINGS_URL,
  THEME_MAP,
  THEME_UPDATE_MAP,
  TOASTIFY_THEME,
  WORKSPACE_DOMAINS,
} from "../../utilities/constants";
import HamburgerButton from "../HamburgerButton";
import LogoRenderer from "../LogoRenderer";
import ProductsDrawer from "../ProductsDrawer";
import ThemeSwitchBtn from "../ThemeSwitchBtn";
import { AppBarProps } from "./types";
import { useStyles } from "./styles";
import { camelize, stringAvatar } from "../../utilities/validationLibrary";
import { getReferrerContext } from "../../utilities";
import ReportStatusChip from "../ReportStatusChip/ReportStatusChip";

const VNAppBar = (props: AppBarProps) => {
  const { setShowDrawer, showDrawer } = props;
  const theme = useTheme();
  const classes = useStyles();
  const appBarRef = useRef<HTMLDivElement>();
  const hamBtnRef = useRef<HTMLDivElement>();
  const isSmSize = useMediaQuery(theme.breakpoints.down("md"));
  const [desktopMenuAnchorEl, setDesktopMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [showProductsDrawer, setShowProductsDrawer] = useState<boolean>(false);
  const [refreshingData, setRefreshingData] = React.useState<Boolean>(false);
  const [loader, setLoader] = React.useState<Boolean>(false);
  const [pageNum, setPageNum] = React.useState(1);
  const [notificationSearchquery] = React.useState("");
  const [id, setId] = React.useState<Number>(0);
  const [hoverProfileID, setHoverProfileID] = React.useState<any>(-1);
  const [showRead, setShowRead] = React.useState<Boolean>(false);
  const [notificationCount, setNotificationCount] = React.useState(0);
  const [showMessage, setShowMessage] = React.useState(-1);
  const [unreadNotificationCount, setunreadNotificationCount] = React.useState(0);
  const [notificationData, setNotificationData] = React.useState<Array<any>>([]);
  const [anchorEle, setAnchorEle] = React.useState<null | HTMLElement>(null);
  const isopen = Boolean(anchorEle);
  const pageLimit = 10;
  const redirectPlatform = getReferrerContext();

  const [getNotifications] = useLazyQuery(GET_NOTIFICATIONS, { client: notificationClient });

  const navigate = useNavigate();
  const [updateThemePreference, { loading: updateThemePreferenceLoading }] = useMutation(UPDATE_THEME_PREFERENCE, {
    client: userClient,
    fetchPolicy: "network-only",
  });

  const {
    themePreferenceState: { preference },
    themePreferenceDispatch,
  } = useThemePreferenceContext();

  const {
    authState: { auth_key, user_id, role_id, profile_picture_url },
  } = useAuthContext();

  var {
    vmessengerState: { connected_accounts },
  } = useVMessengerContext.call({});

  const [
    logout,
    {
      data: logoutdata,
      loading: logoutloading,
      // error: logouterror
    },
  ] = useMutation(LOGOUT, {
    client: authClient,
  });

  useEffect(() => {
    if (logoutdata && !logoutloading) {
      sessionStorage.clear();
      localStorage.clear();
      try {
        let decoded: any = jwtDecode(getAccessToken());
        if (ENV !== "prod" && decoded?.aud) {
          Cookies.remove("access_token_key", { domain: decoded?.aud });
        } else if (decoded?.aud) {
          Cookies.remove("access_token", { domain: decoded?.aud });
        }
      } catch (e) {}
      handleMenuClose(setDesktopMenuAnchorEl);
      window.location.replace(`${REACT_APP_AUTH_PLATFORM_URL!}/${redirectPlatform}`);
    }
  }, [logoutdata, logoutloading, redirectPlatform]);

  const isDesktopMenuOpen = Boolean(desktopMenuAnchorEl);
  const isMobileMenuOpen = Boolean(mobileMenuAnchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, setMenuOpen: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => {
    setMenuOpen(event.currentTarget);
  };

  const handleMenuClose = (setMenuClose: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => {
    setMenuClose(null);
  };

  const SubscriptionData = useSubscription(NEW_NOTIFICATION, {
    fetchPolicy: "network-only",
    client: notificationSubscriptionClient,
  });

  useEffect(() => {
    getNotificationData();
    // eslint-disable-next-line
  }, [SubscriptionData]);

  useEffect(() => {
    if (((notificationSearchquery || pageNum > 1) && notificationData?.length < notificationCount) || (pageNum === 1 && (id || refreshingData))) {
      if (notificationSearchquery) {
        if (pageNum !== 1) {
          setPageNum(1);
        }

        setNotificationData([]);
      }
      getNotificationData();
    } // eslint-disable-next-line
  }, [notificationSearchquery, pageNum, refreshingData, id]);

  useEffect(() => {
    if (!showDrawer && hamBtnRef.current?.classList.contains("open")) {
      hamBtnRef.current?.classList.toggle("open");
    }
  }, [showDrawer]);

  const getNotificationData = async () => {
    setLoader(true);
    const { data } = await getNotifications({
      variables: {
        page: refreshingData ? 1 : pageNum,
        rows: pageLimit,
        search: {
          notification_message: notificationSearchquery ? notificationSearchquery : null,
          notification_header: notificationSearchquery ? notificationSearchquery : null,
        },
        filterBy: {
          is_read: showRead,
        },
      },
      fetchPolicy: "network-only",
    });
    setLoader(false);
    if (data?.get_all_user_notification?.status) {
      if (!showRead) {
        setunreadNotificationCount(data?.get_all_user_notification?.data?.size);
        setNotificationCount(data?.get_all_user_notification?.data?.size);
      } else {
        setNotificationCount(data?.get_all_user_notification?.data?.size);
      }
      if (pageNum === 1) {
        setNotificationData([...data?.get_all_user_notification?.data?.notifications]);
      } else {
        setNotificationData([...notificationData, ...data?.get_all_user_notification?.data?.notifications]);
      }
      setRefreshingData(false);
    } else {
      toast.error(data?.get_all_user_notification?.msg, TOASTIFY_THEME);
    }
  };

  const [deleteNotification] = useMutation(DELETE_USER_NOTIFICATION, {
    client: notificationClient,
  });
  const [updateNotification] = useMutation(UPDATE_USER_NOTIFICATION, {
    client: notificationClient,
  });

  if (WORKSPACE_DOMAINS.includes(REACT_APP_DOMAIN_ID!)) {
    var {
      workspacesState: { active_workspace },
    } = useWorkspacesContext.call({});
  }

  const handleDeleteNotification = async (id: Number) => {
    const { data } = await deleteNotification({
      variables: { deleteUserNotificationId: id },
    });
    if (data) {
      setNotificationData([]);
      setId(id);
      if (pageNum !== 1) {
        setPageNum(1);
      } else {
        getNotificationData();
      }
    } else {
      toast.error("Error deleting notification!", TOASTIFY_THEME);
    }
  };
  const handleUpdateNotification = async (id: Number) => {
    const { data } = await updateNotification({
      variables: { updateUserNotificationId: id, isRead: true },
    });
    if (data) {
      setNotificationData([]);
      setId(id);
      if (pageNum !== 1) {
        setPageNum(1);
      } else {
        getNotificationData();
      }
    } else {
      toast.error("Error marking read!", TOASTIFY_THEME);
    }
  };

  useEffect(() => {
    handleRefresh();
    // eslint-disable-next-line
  }, [showRead]);

  const handleRefresh = () => {
    setPageNum(1);
    setNotificationData([]);
    setRefreshingData(true);
  };
  const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEle) {
      setAnchorEle(null);
    } else {
      setAnchorEle(event?.currentTarget);
      setShowRead(false);
      // handleRefresh();
    }
    // Navigate("/alerts");
  };
  const handleClose = () => {
    setShowMessage(-1);
    setAnchorEle(null);
  };
  const bellId = "bell-menu";

  const bellMenu = (
    <Menu id={bellId} anchorEl={anchorEle} open={isopen} onClose={handleClose} className={classes.menuHoverColorDisable}>
      <Grid className={classes.bellMenu}>
        <Grid item xs={12} px={2}>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography fontSize="16px" color={theme.palette.text.primary}>
                Notifications
              </Typography>
            </Grid>
            <Grid item>
              <Grid container alignItems={"center"} spacing={1}>
                <Grid item>
                  <Link component="button" onClick={() => setShowRead(!showRead)} underline="hover">
                    {showRead ? "Hide read" : "Show read"}
                  </Link>
                </Grid>
                <Grid item>
                  <IconButton onClick={handleRefresh}>
                    <RefreshIcon
                      sx={{
                        color: theme.palette.text.secondary,
                        fontSize: "20px",
                        animation: refreshingData ? "spin infinite 1s linear" : null,
                        "@keyframes spin": {
                          "0%": {
                            transform: "rotate(-360deg)",
                          },
                          "100%": {
                            transform: "rotate(-0deg)",
                          },
                        },
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} px={2}>
          <Grid container sx={{ maxHeight: "300px", overflowY: "auto", overflowX: "hidden" }}>
            <Grid item xs={12}>
              {notificationData.length > 0
                ? notificationData.map((item, i) => {
                    return (
                      notificationCount > 0 && (
                        <Box
                          key={i}
                          className={classes.bellMenuItem}
                          onClick={() => {
                            if (
                              REACT_APP_DOMAIN_ID === "1" &&
                              item?.additional_details?.report_id &&
                              (item?.additional_details?.status === "Completed" || role_id === 5) &&
                              !active_workspace?.is_personal
                            ) {
                              navigate("/profile/" + item?.additional_details?.profile_id + "/report/" + item?.additional_details?.report_id);
                              handleClose();
                            } else if (REACT_APP_DOMAIN_ID === "1" && item?.additional_details?.profile_id && !active_workspace?.is_personal) {
                              navigate("/profile/view/" + item?.additional_details?.profile_id);
                              handleClose();
                            } else {
                              setShowMessage(item.id === showMessage ? -1 : item.id);
                            }
                          }}
                        >
                          <Box
                            className={classes.bellMenuContainer}
                            sx={{
                              backgroundColor: showMessage === item.id ? "rgba(64, 118, 184, 0.15)" : "auto",
                            }}
                            key={item?.id}
                          >
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              sx={{
                                position: "relative",
                              }}
                            >
                              <Grid
                                item
                                sx={{ position: "absolute" }}
                                onMouseOver={() => {
                                  setHoverProfileID(item?.id);
                                }}
                                onMouseLeave={() => {
                                  setHoverProfileID(-1);
                                }}
                                onClick={(e) => {
                                  if (REACT_APP_DOMAIN_ID === "1" && item?.additional_details?.profile_id) {
                                    e.stopPropagation();
                                    navigate("/profile/view/" + item?.additional_details?.profile_id);
                                    setHoverProfileID(-1);
                                    handleClose();
                                  }
                                }}
                              >
                                <Grid container>
                                  <Grid item mr={-2}>
                                    <Avatar
                                      src={item?.additional_details?.profile_pic_url}
                                      {...stringAvatar(
                                        item?.additional_details?.first_name && item?.additional_details?.last_name
                                          ? item?.additional_details?.first_name + " " + item?.additional_details?.last_name
                                          : item?.notification_header + " " + item?.notification_message
                                      )}
                                      className={classes.ImageCss}
                                    />
                                  </Grid>
                                  {item?.additional_details?.profile_id && (
                                    <Collapse
                                      orientation="horizontal"
                                      in={hoverProfileID === item?.id}
                                      sx={{
                                        boxShadow: "1px 2px 10px 0px #00000012",
                                      }}
                                      timeout={500}
                                    >
                                      <Grid item width="150px">
                                        <Grid container justifyContent="center" className={classes.ProfileTransitionCard}>
                                          <Grid item xs={12}>
                                            <Typography color={theme.palette.text.primary} fontSize="14px" noWrap>
                                              {item?.additional_details.first_name + " " + item?.additional_details.last_name}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} mt={-0.5}>
                                            <Typography color={theme.palette.text.disabled} fontSize="10px" noWrap>
                                              {item?.additional_details?.profile_id && "Profile ID: " + item?.additional_details?.profile_id}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Collapse>
                                  )}
                                </Grid>
                              </Grid>

                              <Grid item xs zeroMinWidth>
                                <Grid container pl={"46px"}>
                                  <Grid item xs={12}>
                                    <Typography noWrap color={theme.palette.text.primary}>
                                      {item.notification_header}
                                    </Typography>
                                  </Grid>
                                  {showMessage !== item.id && (
                                    <Grid item xs={12}>
                                      <Typography noWrap fontSize="12px" color={theme.palette.text.secondary}>
                                        {item?.notification_message}
                                      </Typography>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Grid container justifyContent={"end"} flexDirection="column">
                                  {item?.additional_details?.status && (
                                    <Grid item>
                                      <ReportStatusChip size="small" status={item?.additional_details?.status} />
                                    </Grid>
                                  )}
                                  <Grid item>
                                    <Typography fontSize="12px" color={theme.palette.text.secondary} textAlign={"right"}>
                                      <Moment format="MM/DD hh:mmA">{item?.created_ts}</Moment>
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              {item?.is_read ? (
                                <Grid item>
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteNotification(item?.id);
                                    }}
                                    size="small"
                                  >
                                    <Tooltip
                                      title={
                                        <Typography fontSize="12px" color={theme.palette.error.main}>
                                          delete
                                        </Typography>
                                      }
                                      placement="top"
                                    >
                                      <RemoveCircleOutlineIcon
                                        sx={{
                                          color: theme.palette.text.disabled,
                                          fontSize: "18px",
                                          "&:hover": {
                                            color: theme.palette.error.main,
                                          },
                                        }}
                                      />
                                    </Tooltip>
                                  </IconButton>
                                </Grid>
                              ) : (
                                <Grid item>
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleUpdateNotification(item?.id);
                                    }}
                                    size="small"
                                  >
                                    <Tooltip
                                      title={
                                        <Typography fontSize="12px" color={theme.palette.primary.main}>
                                          mark read
                                        </Typography>
                                      }
                                      placement="top"
                                    >
                                      <TaskAltIcon
                                        sx={{
                                          color: theme.palette.text.disabled,
                                          fontSize: "18px",
                                          "&:hover": {
                                            color: theme.palette.primary.main,
                                          },
                                        }}
                                      />
                                    </Tooltip>
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                            <Collapse in={showMessage === item.id}>
                              <Grid container alignItems="center">
                                <Grid item xs={12} pt={1}>
                                  <Divider variant="fullWidth" className={classes.dividerColor} />
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    sx={{
                                      whiteSpace: "initial",
                                    }}
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    pt={1}
                                    color={theme.palette.text.secondary}
                                  >
                                    {`${item?.notification_message}`}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Collapse>
                          </Box>
                        </Box>
                      )
                    );
                  })
                : !loader && (
                    <Box
                      sx={{
                        background: theme.palette.card.main,
                        marginX: "16px",
                        borderTop: `1px solid ${theme.palette.divider}`,
                      }}
                    >
                      <Box display={"flex"} justifyContent="center" pt={0.5}>
                        <Typography color={theme.palette.text.secondary}>You are all caught up! No new notifications to display.</Typography>
                      </Box>
                    </Box>
                  )}
              {loader
                ? [...Array(2)].map((item, index) => (
                    <Box key={index} className={classes.bellMenuContainer}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12}>
                          <Grid container alignItems="center" spacing={1.5}>
                            <Grid item>
                              <Skeleton variant="circular" width={35} height={35} />
                            </Grid>
                            <Grid item xs>
                              <Skeleton width="50%">
                                <Typography>.</Typography>
                              </Skeleton>
                              <Skeleton width="80%">
                                <Typography>.</Typography>
                              </Skeleton>
                            </Grid>
                            <Grid item>
                              <Skeleton width="50px">
                                <Typography>.</Typography>
                              </Skeleton>
                              <Skeleton width="50px">
                                <Typography>.</Typography>
                              </Skeleton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  ))
                : notificationData?.length > 0 &&
                  notificationData?.length < notificationCount && (
                    <Waypoint
                      onEnter={() => {
                        if (notificationData?.length === (pageNum + 1) * pageLimit) {
                          setPageNum(pageNum + 1);
                        }
                      }}
                    />
                  )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Menu>
  );

  const DesktopMenu = useMemo(
    () => () => {
      return (
        <Menu
          anchorEl={desktopMenuAnchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          open={isDesktopMenuOpen}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={() => {
            handleMenuClose(setDesktopMenuAnchorEl);
          }}
        >
          <MenuItem
            onClick={(event) => {
              if (REACT_APP_DOMAIN_ID !== "5") {
                window.location.replace(`${REACT_APP_VN_SETTINGS_URL}/${auth_key}/${user_id}`);
                handleMenuClose(setDesktopMenuAnchorEl);
              } else {
                navigate("/");
                handleMenuClose(setDesktopMenuAnchorEl);
              }
            }}
          >
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText>Settings</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              logout({
                variables: {
                  loggedIn: false,
                },
              });
            }}
          >
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </Menu>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth_key, user_id, desktopMenuAnchorEl, isDesktopMenuOpen]
  );

  const MobileMenu = useMemo(
    () => () => {
      return (
        <Menu
          anchorEl={mobileMenuAnchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={isMobileMenuOpen}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={() => {
            handleMenuClose(setMobileMenuAnchorEl);
          }}
        >
          <MenuItem>
            <ListItemIcon>
              <Apps />
            </ListItemIcon>
            <ListItemText>Apps</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <NotificationsNoneIcon />
            </ListItemIcon>
            <ListItemText>Notifications</ListItemText>
          </MenuItem>
          {REACT_APP_DOMAIN_ID !== "5" ? (
            <MenuItem
              onClick={(event) => {
                window.location.replace(`${REACT_APP_VN_SETTINGS_URL}/${auth_key}/${user_id}`);
                handleMenuClose(setMobileMenuAnchorEl);
              }}
            >
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText>Settings</ListItemText>
            </MenuItem>
          ) : null}
          <MenuItem
            onClick={async (event) => {
              logout({
                variables: {
                  loggedIn: false,
                },
              });
            }}
          >
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </Menu>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth_key, user_id, isMobileMenuOpen, mobileMenuAnchorEl]
  );

  return (
    <>
      <AppBar
        ref={(instance) => {
          if (instance) appBarRef.current = instance;
        }}
        component={"nav"}
        sx={{ height: "70px !important", zIndex: 1200 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            background: theme.palette.card.main,
            height: "70px",
            alignItems: "center",
            padding: "10px",
            position: "sticky",
            top: 0,
            zIndex: 1300,
          }}
        >
          <Box display={"flex"} alignItems={"center"} ml={2.1}>
            {((truth) => {
              switch (truth) {
                case true:
                  if (Object.keys(connected_accounts).length) {
                    return (
                      <IconButton
                        color="inherit"
                        onClick={() => {
                          hamBtnRef.current?.classList.toggle("open");
                          setShowDrawer((prev) => !prev);
                        }}
                        edge="start"
                      >
                        <HamburgerButton
                          width={26}
                          height={26}
                          ref={(instance) => {
                            if (instance) {
                              hamBtnRef.current = instance;
                            }
                          }}
                          open={showDrawer}
                        />
                      </IconButton>
                    );
                  } else {
                    return null;
                  }
                case false:
                  return (
                    <IconButton
                      color="inherit"
                      onClick={() => {
                        hamBtnRef.current?.classList.toggle("open");
                        setShowDrawer((prev) => !prev);
                      }}
                      edge="start"
                    >
                      <HamburgerButton
                        width={26}
                        height={26}
                        ref={(instance) => {
                          if (instance) {
                            hamBtnRef.current = instance;
                          }
                        }}
                        open={showDrawer}
                      />
                    </IconButton>
                  );
              }
            })(props.isVMessenger)}
            <LogoRenderer platform={props.platform} />
          </Box>
          <Box display={"flex"} alignItems={"center"} mr={0.3}>
            <Box display={"flex"} mr={1}>
              {/* <IconButton
                sx={{ display: { xs: "none", md: "flex" } }}
                size="medium"
                color="inherit"
                onClick={() => {
                  setShowProductsDrawer(true);
                }}
              >
                <Apps />
              </IconButton> */}

              <IconButton onClick={handleClick} sx={{ marginRight: "5px" }}>
                <Badge badgeContent={unreadNotificationCount} color="primary">
                  <NotificationsNoneIcon color="primary" />
                </Badge>
              </IconButton>
              {bellMenu}

              {ENV === "dev" && REACT_APP_DOMAIN_ID !== "1" && (
                <IconButton
                  sx={{ display: { xs: "none", md: "flex" } }}
                  size="medium"
                  color="inherit"
                  onClick={() => {
                    navigate("/inbox");
                  }}
                >
                  <Telegram viewBox="3 0 20 20" />
                </IconButton>
              )}
              {/* <IconButton sx={{ display: { xs: "none", md: "flex" } }} size="medium" color="inherit">
                <Notifications />
              </IconButton> */}
            </Box>
            <Box display={"flex"} mr={1}>
              <ThemeSwitchBtn
                theme={preference}
                IconButtonProps={{
                  onClick: () => {
                    try {
                      updateThemePreference({
                        variables: {
                          userId: user_id,
                          themeId: THEME_MAP[THEME_UPDATE_MAP[preference]],
                        },
                      });
                      themePreferenceDispatch({ type: "SET_THEME_PREFERENCE", payload: THEME_UPDATE_MAP[preference] });
                    } catch (err) {}
                  },
                  disabled: updateThemePreferenceLoading,
                }}
              />
            </Box>
            <Box>
              <IconButton
                onClick={(event) => {
                  if (isSmSize) {
                    if (isMobileMenuOpen) {
                      handleMenuClose(setMobileMenuAnchorEl);
                    } else {
                      handleMenuOpen(event, setMobileMenuAnchorEl);
                    }
                  } else {
                    if (isDesktopMenuOpen) {
                      handleMenuClose(setDesktopMenuAnchorEl);
                    } else {
                      handleMenuOpen(event, setDesktopMenuAnchorEl);
                    }
                  }
                }}
              >
                <Avatar variant="circular" aria-haspopup="true" alt="profilePicture" src={profile_picture_url || ""} sx={{ height: "30px", width: "30px" }} />
              </IconButton>
              {isSmSize ? <MobileMenu /> : <DesktopMenu />}
            </Box>
          </Box>
        </Box>
      </AppBar>
      <ProductsDrawer
        open={showProductsDrawer}
        onClose={() => {
          setShowProductsDrawer(false);
        }}
      />
    </>
  );
};

export default VNAppBar;
