import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => {
  return {
    sunAndMoon: {
      width: "24px",
      height: "24px",
      strokeLinecap: "round",
      inlineSize: "100%",
      blockSize: "100%",
      "&.sun-and-moon > :is(.moon, .sun, .sun-beams)": { transformOrigin: "center center" },
      "&.sun-and-moon > .sun-beams": {
        strokeWidth: "2px",
        transition: "transform 0.5s cubic-bezier(.5,1.5,.75,1.25), opacity 0.5s cubic-bezier(.25,0,.3,1)",
      },
      "&.sun-and-moon > .sun": { transition: "transform 0.5s cubic-bezier(.5,1.25,.75,1.25)" },
      "&.sun-and-moon .moon > circle": { transition: "transform 0.25s cubic-bezier(0,0,0,1)" },
      "@supports (cx: 1)": { ".sun-and-moon .moon > circle": { transition: "cx 0.25s cubic-bezier(0,0,0,1)" } },
    },
    iconBtn: {
      "&.dark .sun-and-moon > .sun": {
        transform: "scale(1.75)",
        transitionTimingFunction: "cubic-bezier(.25,0,.3,1)",
        transitionDuration: "0.25s",
      },
      "&.dark .sun-and-moon > .sun-beams": { opacity: 0, transform: "rotate(-25deg)", transitionDuration: "0.15s" },
      "&.dark .sun-and-moon > .moon > circle": { transform: "translate(-7px)", transitionDelay: "0.25s", transitionDuration: "0.5s" },
      "@supports (cx: 1)": {
        "&.dark .sun-and-moon > .moon > circle": {
          transform: "translate(0)",
          cx: 17,
        },
      },
      maxWidth: "40px",
      maxHeight: "40px",
    },
  };
});
