import * as React from "react";
import { SvgIconProps } from "@mui/material";

const MessageEditIcon = (props: SvgIconProps) => (
  <svg width={30} height={30} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.6667 6.66602H6.37037C5.74171 6.66602 5.1388 6.91575 4.69427 7.36028C4.24973 7.80481 4 8.40772 4 9.03639V25.629C4 26.2576 4.24973 26.8606 4.69427 27.3051C5.1388 27.7496 5.74171 27.9993 6.37037 27.9993H22.963C23.5916 27.9993 24.1945 27.7496 24.6391 27.3051C25.0836 26.8606 25.3333 26.2576 25.3333 25.629V17.3327"
      stroke="#0B72F4"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.5543 4.76264C24.0426 4.27433 24.7049 4 25.3955 4C26.0861 4 26.7484 4.27433 27.2367 4.76264C27.725 5.25096 27.9993 5.91325 27.9993 6.60383C27.9993 7.29441 27.725 7.95671 27.2367 8.44502L15.5758 20.1059L10.666 21.3333L11.8935 16.4235L23.5543 4.76264Z"
      stroke="#0B72F4"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default MessageEditIcon;
