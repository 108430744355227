import { Add } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useStyles } from "./styles";
const TemplateScratch = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const [openConfigure, setOpenConfigure] = useState(false);
  // const [formTitle] = useState("");
  // const [formDesc] = useState("");

  // const handleSave = () => {
  //   if (!formTitle) {
  //     return;
  //   }

  //   const payload = {
  //     type: "Header",
  //     label: "Header",
  //     headerbackgroundImage:
  //       "https://img.freepik.com/free-vector/background-design-with-mandalas-pattern_1308-70343.jpg?w=2000&t=st=1664222864~exp=1664223464~hmac=384f0abe0d45d7d2535e1502531b18a28f3e8a63d954d7542bba95c3c20e8acb",
  //     formTitle: formTitle,
  //     formDessription: formDesc ? formDesc : "Form Description",
  //     backgroundColor: "",
  //     formTitleColor: "",
  //     formDescriptionColor: "",
  //   };

  //   // dispatch(saveFormData({ id: formId, data: [payload] }));
  // };

  const handleNavigate = () => {
    let formId = uuidv4();
    navigate("/create-form/" + formId);
  };

  return (
    <Box className={styles.card}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={2}
        onClick={handleNavigate}
      >
        <Add sx={{ fontSize: "2rem", color: "#FcFcFc" }} />
        <Typography variant="body2" sx={{ color: "#FcFcFc" }}>
          Blank Form
        </Typography>
      </Box>
      {/* <Dialog
        open={openConfigure}
        onClose={() => setOpenConfigure(!openConfigure)}
        maxWidth="md"
        fullWidth
      >
        <Grid
          container
          sx={{
            background: "#181A1C",
            border: "1px solid #181A1C",
            boxSizing: "border-box",
            borderRadius: "4px",
            padding: "20px",
            flexDirection: "column",
            display: "flex",
          }}
          xs={12}
        >
          <Typography variant="h5"> Create a new form </Typography>

          <Grid container item xs={12} mt={1} flexDirection="column">
            <Typography variant="body1"> Form Title</Typography>
            <Input
              value={formTitle}
              onChange={(e) => setformTitle(e.target.value)}
            />
          </Grid>
          <Grid container item xs={12} mt={1} flexDirection="column">
            <Typography variant="body1">Form Description </Typography>
            <Input
              value={formDesc}
              onChange={(e) => setformDesc(e.target.value)}
            />
          </Grid>
          <Grid container item xs={12} justifyContent="space-between" mt={3}>
            <Button
              variant="outlined"
              onClick={() => setOpenConfigure(!openConfigure)}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Dialog> */}
    </Box>
  );
};

export default TemplateScratch;
