import { Box, Typography, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SecureLogo from "../../assets/icon-components/SecureLogo";
import VNLogo from "../../assets/icon-components/VNLogo";
import TechLabsLogo from "../../assets/icon-components/techlabsIcon";
import CreatorPlusLogo from "../../assets/icon-components/CreatorPlusLogo";
import { useCreatorPlusContext } from "../../contexts/createPlus-context";

const LogoRenderer = (props: { children?: never; platform: string }) => {
  const { pathname } = useLocation();
  const { creatorPlusState } = useCreatorPlusContext();
  const theme = useTheme();
  const navigate = useNavigate();
  switch (props.platform) {
    case "Secure":
      return (
        <Box sx={{ marginLeft: "20px" }}>
          <SecureLogo
            color={theme.palette.text.primary}
            size={130}
            onClick={() => {
              if (pathname !== "/") {
                navigate("/");
              }
            }}
          />
        </Box>
      );
    case "TechLabs":
      return (
        <Box
          sx={{ marginLeft: "20px", cursor: "pointer" }}
          onClick={() => {
            if (pathname !== "/") {
              navigate("/");
            }
          }}
        >
          <TechLabsLogo fill={theme.palette.text.primary} height="40" />
        </Box>
      );
    default:
      return (
        <Typography
          className="platform"
          onClick={() => {
            if (pathname !== "/") {
              navigate("/");
            }
          }}
          variant="opacity50"
          noWrap
          component="div"
          style={{ display: "flex", gap: "0.2rem", justifyContent: "center", alignItems: "center", fontSize: "14px", marginLeft: "25px", cursor: "pointer" }}
        >
          {creatorPlusState.isCreatorPlus ? (
            <CreatorPlusLogo height={100} width={128} />
          ) : (
            <>
              <VNLogo style={{ height: "28px", width: "28px" }} /> <>{props.platform}</>
            </>
          )}
        </Typography>
      );
  }
};

export default LogoRenderer;
