import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  Paper,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
// >> For DND
// import CustomComponents from "../../Components/CustomComponents";
// import { HTML5Backend } from "react-dnd-html5-backend";
// import { DndProvider } from "react-dnd";
// import FormBuilder from "../../Components/FormBuilder";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import ImageIcon from "@mui/icons-material/Image";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import { useLocation, useNavigate } from "react-router-dom";

import { useParams } from "react-router";

import { v4 as uuidv4 } from "uuid";
import { addQuestion, getQuestionsById } from "../../APIs/api";
import Header from "./header";
import Questions from "./questions";

interface IQuestionOptionsData {
  optionsText: string;
}

export enum EQuestionTypes {
  SHORT_ANSWER = "SHORT_ANSWER",
  PARAGRAPH = "PARAGRAPH",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  CHECKBOXES = "CHECKBOXES",
  DROPDOWN = "DROPDOWN",
  DATE = "DATE",
  Time = "Time",
}
export interface IQuestionsData {
  questionId: string;
  questionText: string;
  questionType: string;
  questionOptions: IQuestionOptionsData[];
  isOpen: boolean;
  isRequired: boolean;
}

export interface IHeadingData {
  title: {
    value: string;
    isEditable: boolean;
  };
  description: {
    value: string;
    isEditable: boolean;
  };
}

const CreateForm = () => {
  const navigate = useNavigate();
  const { formId } = useParams();
  const { state } = useLocation();
  const [questionData, setQuestionData] = useState([
    {
      questionId: uuidv4(),
      questionText: "",
      questionType: EQuestionTypes.SHORT_ANSWER,
      questionOptions: [{ optionsText: "Option 1" }],
      isOpen: true,
      isRequired: false,
    },
  ]);
  const [headingData, setHeadingData] = useState<IHeadingData>({
    title: {
      value: state?.title || "",
      isEditable: false,
    },
    description: {
      value: state?.description || "",
      isEditable: false,
    },
  });

  const handleFormData = async () => {
    const response = await getQuestionsById(formId);
    const quesData = response.map((item) => ({
      questionId: item.id,
      questionText: item.questionText,
      questionType: item.questionType,
      questionOptions: item.options,
      isOpen: false,
      isRequired: item.isRequired,
    }));
    setQuestionData(quesData);
  };

  useEffect(() => {
    handleFormData();
    // eslint-disable-next-line
  }, []);

  const handleDiscard = () => {
    navigate("/");
  };
  const handlePreview = () => {
    navigate(`/preview-form/${formId}`, {
      state: {
        headingData,
        questionData,
      },
    });
  };

  const handleSave = async () => {
    const isValidText = questionData.every((item) => item.questionText !== "");
    if (!isValidText) return alert("Question must not be Empty");
    const response = await addQuestion(questionData, headingData);
    if (response.status) {
      alert(response.message);
    }
  };

  // const addTitleSectionHandler = () => {
  //   // close all open questions
  //   const temp = questionData.map((que) => ({
  //     ...que,
  //     isOpen: false,
  //   }));
  //   const newQuestion = {
  //     questionId: uuidv4(),
  //     questionText: "",
  //     questionType: EQuestionTypes.TITLE,
  //     questionOptions: [{ optionsText: "" }],
  //     isOpen: true,
  //     isRequired: false,
  //   };
  //   setQuestionData([...temp, newQuestion]);
  // };

  const addQuestionHandler = () => {
    // close all open questions
    const temp = questionData.map((que) => ({
      ...que,
      isOpen: false,
    }));
    const newQuestion = {
      questionId: uuidv4(),
      questionText: "",
      questionType: EQuestionTypes.SHORT_ANSWER,
      questionOptions: [{ optionsText: "Option 1" }],
      isOpen: true,
      isRequired: false,
    };
    setQuestionData([...temp, newQuestion]);
  };

  const deleteQuestionHandler = (index) => {
    let tempQuestions = [...questionData];
    if (questionData.length > 1) {
      tempQuestions.splice(index, 1);
    }
    setQuestionData(tempQuestions);
  };

  const duplicateQuestionHandler = (index) => {
    const duplicateQue = questionData[index];
    const temp = questionData.map((que) => ({
      ...que,
      isOpen: false,
    }));
    setQuestionData([...temp, duplicateQue]);
  };

  return (
    <Grid container item xs={12} p={4} justifyContent="center">
      {/* Top Navbar */}
      <Grid
        container
        item
        xs={12}
        sx={{ height: "45px" }}
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <IconButton onClick={handleDiscard}>
          <KeyboardBackspaceIcon sx={{ fontSize: "2rem" }} />
        </IconButton>
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={handleDiscard}>
            Discard
          </Button>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
          <Button variant="contained" onClick={handlePreview}>
            Preview
          </Button>
        </Stack>
      </Grid>

      <Grid item xs={9} width="100%" p={2}>
        <Stack direction="row" alignItems="flex-end" width="100%" spacing={2}>
          <Stack
            direction="column"
            alignItems="center"
            width="100%"
            spacing={2}
          >
            <Header headingData={headingData} setHeadingData={setHeadingData} />
            <Questions
              questionData={questionData}
              setQuestionData={setQuestionData}
              deleteQuestionHandler={deleteQuestionHandler}
              duplicateQuestionHandler={duplicateQuestionHandler}
            />
          </Stack>
          <Paper sx={{ p: 1 }}>
            <List>
              <IconButton size="small" onClick={addQuestionHandler}>
                <ControlPointIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </List>
            <Divider />
            <List>
              <IconButton size="small">
                <FormatSizeIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </List>
            <Divider />
            <List>
              <IconButton size="small">
                <ImageIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </List>
            <Divider />
            <List>
              <IconButton size="small">
                <VideoLibraryIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </List>
          </Paper>
        </Stack>
      </Grid>
      {/* <DndProvider backend={HTML5Backend as any}>
        <Grid container item xs={8} sx={{ background: "#1c1c1c" }} p={1}>
          <FormBuilder />
        </Grid>
        <Grid container item xs={4} sx={{ background: "#1F1F1F" }} p={1}>
          <CustomComponents />
        </Grid>
      </DndProvider> */}
    </Grid>
  );
};

export default CreateForm;
