import { Grid, Typography, Input } from "@mui/material";
import MessageEditIcon from "../../../../assets/icon-components/MessageEditIcon";

const ListHeader = (props: any) => {
  return (
    <Grid variant="opacity75" sx={{ cursor: "pointer" }}>
      <Grid sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} mb={2}>
        <Typography variant="opacity70" fontWeight={600} fontSize={"20px"}>
          Messages
        </Typography>
        <Grid onClick={() => props.setOpenContactList(true)}>
          <MessageEditIcon />
        </Grid>
      </Grid>

      <Input type="text" placeholder={"Search..."} sx={{ borderRadius: 2 }} fullWidth />
    </Grid>
  );
};

export default ListHeader;
