import { Box, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./styles";

const TemplateCard = ({ template }) => {
  const styles = useStyles();
  return (
    <Box className={styles.card}>
      <Typography sx={{ color: "#FcFcFc", textAlign: "center" }}>
        Template preview will be shown
      </Typography>
    </Box>
  );
};

export default TemplateCard;
