import { gql } from "@apollo/client";

export const GET_USER_WORKSPACES = gql`
  query GetUserWorkspaces($userId: Int!) {
    getUserWorkspaces(user_id: $userId) {
      code
      status
      msg
      data
    }
  }
`;

export const GET_USER_SETTINGS = gql`
  query GetUserSettings($userId: Int!) {
    getUserSettings(user_id: $userId) {
      data
    }
  }
`;

export const GET_TEAM_MEMBERS = gql`
  query GetTeamMembers($teamId: Int!) {
    getTeamMembers(team_id: $teamId) {
      code
      status
      msg
      data
    }
  }
`;

export const GET_WORKSPACE_MEMBERS = gql`
  query Query($workspaceId: Int!) {
    getWorkspaceMembers(workspace_id: $workspaceId) {
      data
    }
  }
`;

export const GET_USER_LEGAL_COMPANIES = gql`
  query GetUserCompanies($userId: Int!) {
    getUserCompanies(user_id: $userId) {
      id
      legal_name
      owner_id
      created_by_user_id
      created_ts
      updated_ts
      address
      business_number
      city
      state
      country
    }
  }
`;

export const GET_USER_ORGANIZATIONS = gql`
  query Query($userId: Int!) {
    getUserOrganizations(user_id: $userId) {
      id
      name
      created_ts
      updated_ts
      domain
      created_by_user_id
      owner_id
      admin_id
      display_name
    }
  }
`;

export const GET_USER_EMAIL_CONNECTS = gql`
  query GetUserEmailConnects {
    getUserEmailConnects {
      code
      status
      msg
      data
    }
  }
`;

export const GET_API_KEYS = gql`
  query GetTeamApiKeys($teamId: Int!) {
    getTeamApiKeys(team_id: $teamId) {
      code
      status
      msg
      data
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query Get_all_user_notification($page: Int, $rows: Int, $search: inpsearch, $filterBy: inpfilter) {
    get_all_user_notification(page: $page, rows: $rows, search: $search, filterBy: $filterBy) {
      code
      data
      msg
      status
    }
  }
`;
export const GET_ALL_USERS = gql`
  query GetAllUsersData($page: Int, $rows: Int, $search: String, $filter: getAllUsersFilterBy, $sort: globalOrderBy) {
    getAllUsersData(page: $page, rows: $rows, search: $search, filter: $filter, sort: $sort) {
      code
      status
      msg
      data
    }
  }
`;

export const GET_USER_CONVERSATION = gql`
  query GetUserConversations {
    getUserConversations {
      code
      message
      status
      data {
        userConversation {
          conversation {
            users {
              user_id
              user_name
              user_profile_pic_url
              is_admin
            }
            name
            profile_url
            type
            unread_count
            _id
            updated_at
            created_at
            last_message {
              _id
              conversationId
              senderId
              senderName
              msg
              messageType
              url
              createdAt
              status
            }
          }
          _id
          name
          profile_picture
          user_id
        }
      }
    }
  }
`;

export const GET_CONVERSATION_MESSAGES = gql`
  query GetConversationMessages($conversationId: String!, $limit: Int) {
    getConversationMessages(conversation_id: $conversationId, limit: $limit) {
      code
      message
      status
      data {
        conversationName
        conversationPic
        messages {
          _id
          conversationId
          senderId
          senderName
          msg
          messageType
          url
          createdAt
          status
        }
      }
    }
  }
`;

export const GET_FRONTEND_PLANS_QUERY = gql`
  query GetFrontendPlans($page: Int, $limit: Int, $filterBy: globalTableFilterBy) {
    getFrontendPlans(page: $page, limit: $limit, filterBy: $filterBy) {
      data
      msg
      msg
      code
      status
    }
  }
`;

export const GET_CONVERSATION_INFO = gql`
  query GetConversationInfo($conversationId: String!) {
    getConversationInfo(conversation_id: $conversationId) {
      code
      message
      status
      data {
        conversation {
          users {
            user_id
            user_name
            user_profile_pic_url
            chat_user {
              _id
              user_id
              user_name
              user_profile_pic_url
              created_at
              updated_at
            }
            is_admin
          }
          name
          profile_url
          type
          unread_count
          _id
          updated_at
          created_at
          is_mute
          mute_until
          last_message {
            _id
            conversationId
            senderId
            senderName
            msg
            messageType
            url
            createdAt
            status
          }
        }
      }
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query GetUserProfile {
    getUserProfile {
      code
      status
      msg
      data
    }
  }
`;

export const GET_USER_ONBOARDING_STATUS_QUERY = gql`
  query GetOnboardingStatus {
    getOnboardingStatus {
      code
      status
      msg
      data
    }
  }
`;
