import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  chatListContainer: {
    height: "calc(100vh - 190px)",
    overflowY: "auto",
    width: "100%",
    paddingTop: "10px",
  },
  listContainer: {
    width: "100%",
    padding: "0 !important",
    cursor: "pointer",
    "&$selected:hover": {
      background: "rgba(0, 0, 0, 0.2)",
      color: "opacity75",
      // borderRadius: "50%",
      "& .MuiListItemIcon-root": {
        color: "opacity75",
      },
    },
    "&:hover": {
      background: "rgba(0, 0, 0, 0.2)",
      color: "opacity75",
      // borderRadius: "50px",
      "& .MuiListItemIcon-root": {
        color: "opacity75",
      },
    },
  },
  messageStyle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    lineHeight: "0.4rem",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },

  unreadMsz: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor:"#4f99f7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
