import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  messageBox: {
    // backgroundColor: "rgb(24, 24, 24)"
  },
  leftTop: {
    position: "absolute",
    maxWwidth: "300px",
    minWidth: "80px",
    left: "-40px",
    right: "auto",
    top: "-10px",
    backgroundColor: "white ",
    bottom: "auto",
    padding: "4px 8px",
    borderRadius: "2px",
  },
  messageRight: {
    padding: "0 1rem",
    alignItems: "flex-end",
    maxWidth: "50em",
  },
  messageLeft: {
    padding: "0 1rem",
    alignItems: "flex-start",
    maxWidth: "50em",
  },
  messageBubbleContainerLeft: {
    display: "flex",
    justifyContent: "flex-start",
  },
  messageBubbleContainerRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  messageBubbleLeft: {
    padding: "10px",
    borderRadius: "10px 10px 10px 0px",
    background: "#FFF",
    color: "#000",
    "&:has(>img)": {
      padding: "0",
      background: "transparent",
    },
  },
  messageBubbleRight: {
    padding: "10px",
    borderRadius: "10px 10px 0px 10px",
    background: "rgb(61,172,255)",
    color: "#FFF",
    "&:has(>img)": {
      padding: "0",
      background: "transparent",
    },
    // "&:last-of-type": {
    //   borderRadius: "10px 10px 0px 10px",
    // }
  },
  timestamp: {
    padding: "0px 3px",
    textAlign: "center",
    color: "opacity75",
  },

  event: {
    display: "flex",
    justifyContent: "center",
  },
});
