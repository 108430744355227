import { Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllForms } from "../../APIs/api";

import TemplateRow from "../../Components/TemplateRow";
import { clearData } from "../../Redux/Slice/FormSlice/formSlice";
export interface IFormData {
  id: String;
  formName: String;
  formDescription: String;
}

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formsData, setFormsData] = useState<IFormData[]>([]);

  const handleRedirect = (id, title, desc) => {
    navigate(`/create-form/${id}`, { state: { title, desc } });
  };

  const handleForms = async () => {
    const response = await getAllForms();
    if (response.length > 0) setFormsData(response);
  };

  useEffect(() => {
    handleForms();
  }, []);
  const clearForm = () => {
    dispatch(clearData());
  };
  return (
    <Grid container item xs={12}>
      <Grid
        container
        item
        xs={12}
        justifyContent="flex-end"
        sx={{ background: "#1e1e1e", height: "70px" }}
        alignItems="center"
      >
        <Button variant="contained" onClick={clearForm}>
          Clear Forms
        </Button>
      </Grid>
      <TemplateRow />
      <Grid container item xs={12} p={4}>
        <Stack width="100%">
          <Typography variant="h4" mb={2}>
            Recent Forms
          </Typography>
          <Stack>
            {formsData.length &&
              formsData.map((form) => {
                return (
                  <Grid
                    p={1}
                    xs={12}
                    sx={{
                      border: "1px solid #AEAEAE",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    mb={1}
                    onClick={() =>
                      handleRedirect(
                        form?.id,
                        form.formName,
                        form.formDescription
                      )
                    }
                  >
                    <Typography variant="h6">
                      {form.formName || "Untitled Form"}
                    </Typography>
                    <Typography variant="subtitle1">
                      {form.formDescription || "Form Description"}
                    </Typography>
                  </Grid>
                );
              })}
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
