import { Routes, Route, useLocation } from "react-router-dom";
import { useState, lazy, Suspense, useEffect } from "react";
import { IdleTimerProvider } from "react-idle-timer";
import { Grid, Container, useMediaQuery, useTheme } from "@mui/material";

import { useStyles } from "./styles";
import MainLeftDrawer from "../MainLeftDrawer";
import VNAppBar from "../VNAppBar";
import {
  REACT_APP_DOMAIN_ID,
  WORKSPACE_DOMAINS,
  DOMAINS_MAP,
  REACT_APP_AUTH_PLATFORM_URL,
  REACT_APP_DATAGRID_PRO_LICENSE_KEY,
  ENV,
} from "../../utilities/constants";
import {
  AuthContextProvider,
  WorkspacesContextProvider,
  ThemePreferenceContextProvider,
  VMessengerContextProvider,
  ProfileContextProvider,
} from "../../contexts";
import { authClient, persistor } from "../../apollo/client";
// import AuthInitializer from "../AuthInitializer";
import WorkspacesInitializer from "../WorkspacesInitializer";
import VMessengerInitializer from "../VMessengerInitializer";
import { getReferrerContext } from "../../utilities";
import WorkspacesPreferencesSkeleton from "../../sections/workspaces/components/WorkspacePreferencesSkeleton";
import CreateWorkspacePageSkeleton from "../../sections/workspaces/components/CreateWorkspacePageSkeleton";
import { LicenseInfo } from "@mui/x-license-pro";
import AuthInitializer from "../AuthInitializer";
import InboxPageSkeleton from "../../sections/workspaces/components/InboxPageSkeleton";
import InboxPage from "../../sections/InboxPage";
import { useMutation } from "@apollo/client";
import { LOGOUT } from "../../apollo/mutations";
import jwtDecode from "jwt-decode";
import { getAccessToken } from "../../main";
import Cookies from "js-cookie";
import { CreatorPlusContextProvider } from "../../contexts/createPlus-context";

const WorkspacePreferences = lazy(() => import("../../sections/workspaces/pages/WorkspacePreferences"));
const CreateWorkspace = lazy(() => import("../../sections/workspaces/pages/CreateWorkspacePage"));

LicenseInfo.setLicenseKey(REACT_APP_DATAGRID_PRO_LICENSE_KEY!);

const Scaffold = (props: { children: React.ReactNode; noContainerMenu?: boolean }) => {
  const styles = useStyles();
  const location = useLocation();
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [isDrawerUnpinned, setIsDrawerUnpinned] = useState<boolean>(true);
  const theme = useTheme();
  const isXsSize = useMediaQuery(theme.breakpoints.down("sm"));
  const platform = DOMAINS_MAP[getReferrerContext()!];

  const ScaffoldRoutes = (
    <Routes>
      <Route path="/*" element={props.children} />
      {WORKSPACE_DOMAINS.includes(REACT_APP_DOMAIN_ID!) && (
        <>
          <Route path="/workspaces/createWorkspace" element={<Suspense fallback={<CreateWorkspacePageSkeleton />} children={<CreateWorkspace />} />} />
          <Route path="/inbox" element={<Suspense fallback={<InboxPageSkeleton />} children={<InboxPage />} />} />
          <Route
            path="/workspaces/:workspaceId/preferences"
            element={<Suspense fallback={<WorkspacesPreferencesSkeleton />} children={<WorkspacePreferences />} />}
          />
        </>
      )}
    </Routes>
  );

  const redirectPlatform = getReferrerContext();
  const [
    logout,
    {
      data: logoutdata,
      loading: logoutloading,
      // error: logouterror
    },
  ] = useMutation(LOGOUT, {
    client: authClient,
  });

  useEffect(() => {
    if (logoutdata && !logoutloading) {
      persistor.purge();
      sessionStorage.clear();
      localStorage.clear();
      try {
        let decoded: any = jwtDecode(getAccessToken());
        if (ENV !== "prod" && decoded?.aud) {
          Cookies.remove("access_token_key", { domain: decoded?.aud });
        } else if (decoded?.aud) {
          Cookies.remove("access_token", { domain: decoded?.aud });
        }
      } catch (e) {}
      window.location.replace(`${REACT_APP_AUTH_PLATFORM_URL!}/${redirectPlatform}`);
    }
  }, [logoutdata, logoutloading, redirectPlatform]);

  return (
    <IdleTimerProvider
      timeout={1000 * 60 * 30}
      onIdle={async () => {
        logout({
          variables: {
            loggedIn: false,
          },
        });
      }}
    >
      <ThemePreferenceContextProvider>
        <AuthContextProvider>
          <ProfileContextProvider>
            <AuthInitializer>
              {props.noContainerMenu ? (
                ScaffoldRoutes
              ) : (
                <WorkspacesContextProvider>
                  <VMessengerContextProvider>
                    <CreatorPlusContextProvider>
                      {(({ withWorkspaces, isVMessenger, node }: { withWorkspaces: boolean; isVMessenger: boolean; node: React.ReactNode }) => {
                        if (withWorkspaces) {
                          return <WorkspacesInitializer>{node}</WorkspacesInitializer>;
                        }
                        if (isVMessenger) {
                          return <VMessengerInitializer>{node}</VMessengerInitializer>;
                        }
                        return node;
                      })({
                        withWorkspaces: WORKSPACE_DOMAINS.includes(REACT_APP_DOMAIN_ID!),
                        isVMessenger: REACT_APP_DOMAIN_ID === "6",
                        node: (
                          <Grid container>
                            <Grid item xs={12}>
                              <VNAppBar isVMessenger={REACT_APP_DOMAIN_ID === "6"} platform={platform} setShowDrawer={setShowDrawer} showDrawer={showDrawer} />
                            </Grid>
                            <Grid item sx={{ maxWidth: isDrawerUnpinned ? "69px" : "auto" }}>
                              <MainLeftDrawer
                                injectWorkspaces={WORKSPACE_DOMAINS.includes(REACT_APP_DOMAIN_ID!)}
                                isVMessenger={REACT_APP_DOMAIN_ID === "6"}
                                domainId={REACT_APP_DOMAIN_ID ?? "0"}
                                open={showDrawer}
                                setShowDrawer={setShowDrawer}
                                setIsDrawerUnpinned={setIsDrawerUnpinned}
                              />
                            </Grid>
                            <Grid item sm xs={12} zeroMinWidth>
                              <Grid
                                container
                                id="ScrollContainer"
                                className={styles.contentW}
                                onMouseOver={() => {
                                  if (isDrawerUnpinned) {
                                    setShowDrawer(false);
                                  }
                                }}
                                onClick={(e) => {
                                  if (isXsSize) {
                                    setShowDrawer(false);
                                  }
                                }}
                              >
                                {location.pathname.includes("/profile/") && REACT_APP_DOMAIN_ID === "7" ? (
                                  ScaffoldRoutes
                                ) : (
                                  <Container maxWidth="xl" sx={{ padding: "24px", paddingBottom: "72px" }}>
                                    {ScaffoldRoutes}
                                  </Container>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        ),
                      })}
                    </CreatorPlusContextProvider>
                  </VMessengerContextProvider>
                </WorkspacesContextProvider>
              )}
            </AuthInitializer>
          </ProfileContextProvider>
        </AuthContextProvider>
      </ThemePreferenceContextProvider>
    </IdleTimerProvider>
  );
};

export default Scaffold;
