import { Skeleton, Box, CircularProgress, useMediaQuery, useTheme, Paper } from "@mui/material";
import { WORKSPACE_DOMAINS, REACT_APP_DOMAIN_ID } from "../../utilities/constants";

const ScaffoldSkeleton = () => {
  const theme = useTheme();
  const isSmSize = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box display={"flex"} flexDirection={"column"} height={"100vh"} width={"100vw"}>
      <Paper
        variant="primary"
        sx={{
          borderRadius: 0,
          width: "100%",
          height: "70px",
          padding: "15px 24px 15px 16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box display={"flex"} alignItems="center" gap={"30px"}>
          <Skeleton animation={"wave"} variant="circular" width={"35px"} height={"35px"} />
          <Skeleton animation={"wave"} variant="rounded" width={"150px"} height={"30px"} />
        </Box>
        {isSmSize ? (
          <Box display={"flex"} alignItems={"center"} gap={"5px"}>
            <Skeleton animation={"wave"} variant="circular" width={"35px"} height={"35px"} />
          </Box>
        ) : (
          <Box display={"flex"} alignItems={"center"} gap={"10px"}>
            <Skeleton animation={"wave"} variant="circular" width={"35px"} height={"35px"} />
            <Skeleton animation={"wave"} variant="circular" width={"35px"} height={"35px"} />
            <Skeleton animation={"wave"} variant="circular" width={"35px"} height={"35px"} />
          </Box>
        )}
      </Paper>
      <Box display={"flex"} height={"100%"}>
        <Paper
          variant="primary"
          sx={{
            borderRadius: 0,
            display: "flex",
            width: "69px",
            height: "100%",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            pt: "10px",
            pl: "5px",
            pr: "5px",
          }}
        >
          {WORKSPACE_DOMAINS.includes(REACT_APP_DOMAIN_ID!) && <Skeleton animation={"wave"} variant="rounded" width={"55px"} height={"45px"} />}
          <Skeleton animation={"wave"} variant="circular" width={"40px"} height={"40px"} />
          <Skeleton animation={"wave"} variant="circular" width={"40px"} height={"40px"} />
          <Skeleton animation={"wave"} variant="circular" width={"40px"} height={"40px"} />
          <Skeleton animation={"wave"} variant="circular" width={"40px"} height={"40px"} />
        </Paper>
        <Paper variant="secondary" sx={{ display: "flex", borderRadius: 0, alignItems: "center", justifyContent: "center", height: "100%", width: "100%" }}>
          <CircularProgress />
        </Paper>
      </Box>
    </Box>
  );
};
export default ScaffoldSkeleton;
