import { useStyles } from "./styles";
import { EQuestionTypes, IQuestionsData } from "./index";
import {
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import ShortTextIcon from "@mui/icons-material/ShortText";
import SubjectIcon from "@mui/icons-material/Subject";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClearIcon from "@mui/icons-material/Clear";

interface Props {
  questionData: IQuestionsData[];
  setQuestionData: React.Dispatch<React.SetStateAction<any>>;
  deleteQuestionHandler: (index: number) => void;
  duplicateQuestionHandler: (index: number) => void;
}

const Questions = ({
  questionData,
  setQuestionData,
  deleteQuestionHandler,
  duplicateQuestionHandler,
}: Props) => {
  const styles = useStyles();

  function questionTypeHandler(text: string, i: number) {
    let questionArr = [...questionData];
    questionArr[i].questionType = text;
    console.log(questionArr);
    setQuestionData(questionArr);
  }

  function questionTextHandler(text: string, i: number) {
    let questionArr = [...questionData];
    questionArr[i].questionText = text;
    setQuestionData(questionArr);
  }

  function questionOptionsTextHandler(text: string, i: number, j: number) {
    let questionArr = [...questionData];
    questionArr[i].questionOptions[j].optionsText = text;
    setQuestionData(questionArr);
  }

  function addQuestionOption(i: number) {
    const questionArr = [...questionData];
    if (questionArr[i].questionOptions.length < 5) {
      questionArr[i].questionOptions.push({
        optionsText: "Option " + (questionArr[i].questionOptions.length + 1),
      });
    } else {
      alert("Max of 5 options");
    }
    setQuestionData(questionArr);
  }

  function removeQuestionOptions(i: number, j: number) {
    const questionArr = [...questionData];
    if (questionArr[i].questionOptions.length > 1) {
      questionArr[i].questionOptions.splice(j, 1);
      setQuestionData(questionArr);
    }
  }

  function isRequiredChangeHandler(value: boolean, i: number) {
    const questionArr = [...questionData];
    questionArr[i].isRequired = value;
    setQuestionData(questionArr);
  }

  function isOpenChangeHandler(i: number) {
    const temp = questionData.map((que) => ({
      ...que,
      isOpen: false,
    }));
    console.log(temp);
    temp[i].isOpen = true;
    setQuestionData(temp);
  }

  return (
    <Stack spacing={2} width="100%">
      {questionData.map((que, index) =>
        que.isOpen ? (
          <Stack
            className={styles.commonQuestionView}
            component={Paper}
            key={index}
          >
            <Stack spacing={2} width="100%" direction="row">
              <TextField
                fullWidth
                sx={{ height: 8 }}
                variant="filled"
                placeholder="Question"
                multiline
                autoFocus
                value={que.questionText}
                onChange={(e) => questionTextHandler(e.target.value, index)}
              />
              <FormControl sx={{ m: 1, minWidth: 220 }}>
                <Select
                  value={que.questionType}
                  size="small"
                  displayEmpty
                  onChange={(e) => {
                    questionTypeHandler(e.target.value, index);
                  }}
                >
                  <MenuItem value={EQuestionTypes.SHORT_ANSWER} defaultChecked>
                    <Stack direction="row" spacing={1}>
                      <ShortTextIcon />
                      <Typography variant="subtitle1">Short Answer</Typography>
                    </Stack>
                  </MenuItem>
                  <MenuItem value={EQuestionTypes.PARAGRAPH}>
                    <Stack direction="row" spacing={1}>
                      <SubjectIcon />
                      <Typography variant="subtitle1">Paragraph</Typography>
                    </Stack>
                  </MenuItem>
                  <Divider />
                  <MenuItem value={EQuestionTypes.MULTIPLE_CHOICE}>
                    <Stack direction="row" spacing={1}>
                      <RadioButtonCheckedIcon />
                      <Typography variant="subtitle1">
                        Multiple Choice
                      </Typography>
                    </Stack>
                  </MenuItem>
                  <MenuItem value={EQuestionTypes.CHECKBOXES}>
                    <Stack direction="row" spacing={1}>
                      <CheckBoxIcon />
                      <Typography variant="subtitle1">Checkboxes</Typography>
                    </Stack>
                  </MenuItem>
                  <MenuItem value={EQuestionTypes.DROPDOWN}>
                    <Stack direction="row" spacing={1}>
                      <ArrowDropDownCircleIcon />
                      <Typography variant="subtitle1">Dropdown</Typography>
                    </Stack>
                  </MenuItem>
                  <Divider />
                  <MenuItem value={EQuestionTypes.DATE}>
                    <Stack direction="row" spacing={1}>
                      <EventIcon />
                      <Typography variant="subtitle1">Date</Typography>
                    </Stack>
                  </MenuItem>
                  <MenuItem value={EQuestionTypes.Time}>
                    <Stack direction="row" spacing={1}>
                      <AccessTimeIcon />
                      <Typography variant="subtitle1">Time</Typography>
                    </Stack>
                  </MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Stack py={2} minHeight={100}>
              {que.questionType === EQuestionTypes.SHORT_ANSWER && (
                <TextField
                  variant="standard"
                  sx={{ width: "60%", my: 2 }}
                  placeholder="Short answer text"
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
              {que.questionType === EQuestionTypes.PARAGRAPH && (
                <TextField
                  variant="standard"
                  sx={{ width: "60%", my: 2 }}
                  placeholder="Long answer text"
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
              {que.questionType === EQuestionTypes.MULTIPLE_CHOICE && (
                <Stack pt={1}>
                  {que.questionOptions?.map((opt, j) => (
                    <Stack direction="row" alignItems="center" key={j}>
                      <Radio size="small" />
                      <TextField
                        variant="standard"
                        sx={{ width: "100%", my: 2 }}
                        placeholder="Option"
                        autoFocus
                        value={opt.optionsText}
                        onChange={(e) =>
                          questionOptionsTextHandler(e.target.value, index, j)
                        }
                      />
                      {que?.questionOptions?.length > 1 && (
                        <IconButton
                          onClick={() => removeQuestionOptions(index, j)}
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                    </Stack>
                  ))}
                  {que.questionOptions && que.questionOptions?.length < 5 && (
                    <Stack direction="row" alignItems="center">
                      <Radio size="small" />
                      <TextField
                        onClick={() => addQuestionOption(index)}
                        variant="standard"
                        sx={{ width: "20%" }}
                        placeholder="Add Option"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Stack>
                  )}
                </Stack>
              )}
              {que.questionType === EQuestionTypes.CHECKBOXES && (
                <Stack pt={1}>
                  {que.questionOptions?.map((opt, j) => (
                    <Stack direction="row" alignItems="center" key={j}>
                      <CheckBoxOutlineBlankIcon />
                      <TextField
                        variant="standard"
                        sx={{ width: "100%", my: 2 }}
                        placeholder="Option"
                        autoFocus
                        value={opt.optionsText}
                        onChange={(e) =>
                          questionOptionsTextHandler(e.target.value, index, j)
                        }
                      />
                      {que?.questionOptions?.length > 1 && (
                        <IconButton
                          onClick={() => removeQuestionOptions(index, j)}
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                    </Stack>
                  ))}
                  {que.questionOptions && que.questionOptions?.length < 5 && (
                    <Stack direction="row" alignItems="center">
                      <CheckBoxOutlineBlankIcon />
                      <TextField
                        onClick={() => addQuestionOption(index)}
                        variant="standard"
                        sx={{ width: "20%" }}
                        placeholder="Add Option"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Stack>
                  )}
                </Stack>
              )}
              {que.questionType === EQuestionTypes.DROPDOWN && (
                <>
                  <Stack pt={1}>
                    {que.questionOptions?.map((opt, j) => (
                      <Stack direction="row" alignItems="center" key={j}>
                        <Typography fontSize={14} mr={1}>
                          {j + 1}.
                        </Typography>
                        <TextField
                          variant="standard"
                          sx={{ width: "100%", my: 2 }}
                          placeholder="Option"
                          autoFocus
                          value={opt.optionsText}
                          onChange={(e) =>
                            questionOptionsTextHandler(e.target.value, index, j)
                          }
                        />
                        {que?.questionOptions?.length > 1 && (
                          <IconButton
                            onClick={() => removeQuestionOptions(index, j)}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </Stack>
                    ))}
                    {que.questionOptions && que.questionOptions?.length < 5 && (
                      <Stack direction="row" alignItems="center">
                        <Typography fontSize={14} mr={1}>
                          {que.questionOptions?.length + 1}.
                        </Typography>
                        <TextField
                          onClick={() => addQuestionOption(index)}
                          variant="standard"
                          sx={{ width: "20%" }}
                          placeholder="Add Option"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Stack>
                    )}
                  </Stack>
                </>
              )}
              {que.questionType === EQuestionTypes.DATE && (
                <FormControl sx={{ mt: 2 }}>
                  <TextField
                    disabled
                    id="date"
                    label="Date"
                    type="date"
                    sx={{ width: 220 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              )}
              {que.questionType === EQuestionTypes.Time && (
                <FormControl sx={{ mt: 2 }}>
                  <TextField
                    disabled
                    id="time"
                    label="Time"
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    sx={{ width: 150 }}
                  />
                </FormControl>
              )}
            </Stack>
            <Divider />
            <Stack
              direction="row"
              marginLeft="auto"
              spacing={2}
              pt={2}
              alignItems="center"
            >
              <Tooltip title="duplicate">
                <IconButton onClick={() => duplicateQuestionHandler(index)}>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="delete">
                <IconButton
                  onClick={() => deleteQuestionHandler(index)}
                  disabled={questionData.length === 1}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <Divider orientation="vertical" flexItem />

              <FormControlLabel
                control={
                  <Switch
                    checked={que.isRequired}
                    onChange={(e) =>
                      isRequiredChangeHandler(e.target.checked, index)
                    }
                  />
                }
                label="Required"
                labelPlacement="start"
              />

              <Tooltip title="more">
                <MoreVertIcon />
              </Tooltip>
            </Stack>
          </Stack>
        ) : (
          <>
            <Stack
              className={styles.commonQuestionViewNotOpen}
              component={Paper}
              key={index}
              onClick={() => isOpenChangeHandler(index)}
            >
              <Typography variant="body1">
                {que.questionText || "Question"}&nbsp;
                {que.isRequired && (
                  <Typography sx={{ color: "red", display: "inline-block" }}>
                    *
                  </Typography>
                )}
              </Typography>
              {que.questionType === EQuestionTypes.SHORT_ANSWER && (
                <Typography
                  fontSize={14}
                  mt={1}
                  sx={{
                    color: "rgba(0,0,0,.38)",
                    borderBottom: "1px dotted #000",
                    width: "50%",
                  }}
                >
                  Short answer
                </Typography>
              )}
              {que.questionType === EQuestionTypes.PARAGRAPH && (
                <Typography
                  fontSize={14}
                  mt={1}
                  sx={{
                    color: "rgba(0,0,0,.38)",
                    borderBottom: "1px dotted #000",
                  }}
                >
                  Long answer
                </Typography>
              )}
              {que.questionType === EQuestionTypes.MULTIPLE_CHOICE &&
                que.questionOptions.map((opt, i) => (
                  <Stack direction={"row"} key={i} my={1} alignItems="center">
                    <Radio size="small" disabled />
                    <Typography fontSize={14}>{opt.optionsText}</Typography>
                  </Stack>
                ))}
              {que.questionType === EQuestionTypes.CHECKBOXES &&
                que.questionOptions.map((opt, i) => (
                  <Stack direction={"row"} key={i} my={1} alignItems="center">
                    <CheckBoxOutlineBlankIcon
                      sx={{
                        color: "rgba(0,0,0,.38)",
                        fontSize: 18,
                        marginRight: "0.5rem",
                      }}
                    />
                    <Typography fontSize={14}>{opt.optionsText}</Typography>
                  </Stack>
                ))}
              {que.questionType === EQuestionTypes.DROPDOWN &&
                que.questionOptions.map((opt, i) => (
                  <Stack direction={"row"} key={i} my={1} alignItems="center">
                    <Typography
                      fontSize={14}
                      sx={{
                        marginRight: "1rem",
                      }}
                    >
                      {i + 1}.
                    </Typography>
                    <Typography fontSize={14}>{opt.optionsText}</Typography>
                  </Stack>
                ))}
            </Stack>
          </>
        )
      )}
    </Stack>
  );
};

export default Questions;
