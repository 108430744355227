// @ts-nocheck
import { ExpandMore, KeyboardArrowDown } from "@mui/icons-material";
import {
  outlinedInputClasses,
  selectClasses,
  switchClasses,
  tabClasses,
  svgIconClasses,
  listItemClasses,
  autocompleteClasses,
  inputClasses,
  paperClasses,
  buttonClasses,
  accordionClasses,
  ratingClasses,
  toggleButtonClasses,
  Paper,
  ChipTypeMap,
  tableRowClasses,
  tableCellClasses,
} from "@mui/material";
import { ComponentsOverrides, ComponentsProps, ComponentsVariants, createTheme } from "@mui/material/styles";
import { DataGridProps as DefaultDataGridProps } from "@mui/x-data-grid/models/props/DataGridProps";
import { gridClasses, GridClasses } from "@mui/x-data-grid/constants/gridClasses";
import { PickerComponents, PickersComponentNameToClassKey } from "@mui/x-date-pickers/themeAugmentation";
import { pickersPopperClasses } from "@mui/x-date-pickers/internals";

declare module "@mui/material/OverridableComponent" {
  interface CommonProps {
    variant?: Utilities.OverridableStringUnion<"default", any>;
  }
}

type VNColors =
  | "blue"
  | "blue-opacity"
  | "info-blue"
  | "info-blue-opacity"
  | "error-opacity"
  | "success-opacity"
  | "warning-opacity"
  | "error"
  | "success"
  | "warning"
  | "black-grey"
  | "dark-grey"
  | "grey"
  | "light-grey"
  | "paper-secondary-blue"
  | "paper-elevation-blue"
  | "maroon"
  | "orange"
  | "purple"
  | "maroon-secondary"
  | "dark-blue";

type MediakitsColors = "white" | "navy-blue" | "gradient-bg-main" | "gradient-bg-border-top" | "gradient-bg-border-bottom";

declare module "@mui/material/styles" {
  interface Theme {
    navbar: {
      backgroundColor: string;
    };
  }
  interface ThemeOptions {
    navbar?: {
      backgroundColor?: string;
    };
  }
  interface Palette {
    vn: Record<VNColors, string>;
    card: { main: string; dark: string; light: string };
    mediakits: Record<MediakitsColors, string>;
  }
  interface PaletteOptions {
    vn: Record<VNColors, string>;
    card: { main: string; dark: string; light: string };
    mediakits: Record<MediakitsColors, string>;
  }
}

declare module "@mui/material/Autocomplete" {
  interface AutocompleteProps<
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    T,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Multiple extends boolean | undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    DisableClearable extends boolean | undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    FreeSolo extends boolean | undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"]
  > {
    variant?: "filled" | "outlined" | "standard";
    "variant-extended"?: "background-grey" | "white";
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    grey: true;
    red: true;
  }
}

declare module "@mui/material/InputBase" {
  interface InputBaseProps {
    variant?: Utilities.OverridableStringUnion<"hollow", InputBasePropsVariantOverrides>;
  }
  interface InputBasePropsVariantOverrides {
    hollow: true;
    filled: true;
  }
}

type PaperVariantTypes =
  | "secondary"
  | "primary"
  | "light"
  | "outlined-primary"
  | "outlined-secondary"
  | "outlined-light"
  | "outlined"
  | "elevation"
  | "vanilla"
  | "mediakits"
  | "mediakits-opacity-white"
  | "mediakits-overall-bg";
type PaperPaddingTypes = "0.5rem" | "1rem" | "1.5rem";
type socialName = "instagram" | "linkedin" | "tiktok";
type PaperVariants =
  | `${PaperVariantTypes}-p${PaperPaddingTypes}`
  | PaperVariantTypes
  | `socialGradients-${socialName}`
  | `socialGradients-${socialName}-p${PaperPaddingTypes}`;
declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides extends Record<PaperVariants, true> {}
}

declare module "@mui/material/Select" {
  interface SelectProps {
    "variant-extended"?: "background-grey" | "white";
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    "filled-blue": true;
    "filled-red": true;
    "filled-orange": true;
    "filled-green": true;
    "filled-grey": true;
    "outlined-blue": true;
    "outlined-red": true;
    "outlined-orange": true;
    "outlined-grey": true;
    "outlined-green": true;
    "filled-purple": true;
    "outlined-purple": true;
  }
}

type TypographyOpacityType = "opacity95" | "opacity70" | "opacity50" | "opacity30" | "opacity20" | "opacity10";
type TypographyVariants = `${TypographyOpacityType}-${Exclude<Utilities.GenerateNMultiplesOf<4, 16>, 4 | 16> | 14 | 18}px` | TypographyOpacityType | "error";
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides extends Record<TypographyVariants, true> {}
}

interface DataGridComponentsModified {
  MuiDataGrid?: {
    variants?: ComponentsVariants["MuiDataGrid"];
    defaultProps?: ComponentsProps["MuiDataGrid"];
    styleOverrides?: ComponentsOverrides["MuiDataGrid"];
  };
}

interface DataGridToClassKey {
  MuiDataGrid: keyof GridClasses;
}

interface DataGridComponentsPropsList {
  MuiDataGrid: DefaultDataGridProps;
}

declare module "@mui/material/styles" {
  interface ComponentsPropsList extends DataGridComponentsPropsList {}
}

declare module "@mui/material/styles/createPalette" {
  interface TypeText {
    primary: string;
    secondary: string;
    disabled: string;
    ter: string;
    pri: string;
  }
}

declare module "@mui/material/styles/components" {
  interface Components extends DataGridComponentsModified {}
  interface Components extends PickerComponents {}
}

declare module "@mui/material/styles/overrides" {
  interface ComponentNameToClassKey extends DataGridToClassKey {}
  interface ComponentNameToClassKey extends PickersComponentNameToClassKey {}
}

declare module "@mui/x-data-grid/models/props/DataGridProps" {
  interface DataGridPropsWithoutDefaultValue {
    variant?: Utilities.OverridableStringUnion<"default", DataGridPropsVariantOverrides>;
  }
  export interface DataGridPropsVariantOverrides {
    transparent: true;
  }
}

const defaultDarkTheme = createTheme({
  palette: {
    mode: "dark",
    mediakits: {
      white: "#fff",
      "navy-blue": "#0B0927",
      "gradient-bg-main":
        "radial-gradient(ellipse at 200% 60%, rgba(244, 133, 23, 1) 0%, rgba(186, 7, 252, 1) 0%, rgba(252, 7, 111, 1) 0%, rgba(11, 10, 37, 1) 49%)",
      "gradient-bg-border-top": "linear-gradient(rgb(11, 9, 39, 1), rgba(11, 9, 39, 0) 100%)",
      "gradient-bg-border-bottom": "linear-gradient(rgba(11, 9, 39, 0), rgb(11, 9, 39, 1) 100%)",
    },
    vn: {
      blue: "#3DACFF",
      "blue-opacity": "3dacff40",
      "info-blue": "#73c0fa",
      "info-blue-opacity": "#73c0fa40",
      error: "#E43B3B",
      "error-opacity": "#e43b3b40",
      success: "#3FFCAE",
      "success-opacity": "#3FFCAE40",
      warning: "#FFA842",
      "warning-opacity": "#FFA84240",
      "black-grey": "#181818",
      "dark-grey": "#181A1C",
      grey: "#232425",
      "light-grey": "#787878",
      "paper-secondary-blue": "#e8f0fe",
      "paper-elevation-blue": "#eaf1fb",
      orange: "#F48517",
      maroon: "#FC076D",
      purple: "#BA07FC",
      "maroon-secondary": "#FC076F",
      "dark-blue": "#111F3B",
    },
    card: {
      main: "#181818",
      light: "#212121",
      dark: "#161616",
    },
  },
});

export const dark = createTheme({
  navbar: {
    backgroundColor: "#181818",
  },
  palette: {
    mode: "dark",
    mediakits: {
      white: "#fff",
      "navy-blue": "#0B0927",
      "gradient-bg-main":
        "radial-gradient(ellipse at 200% 60%, rgba(244, 133, 23, 1) 0%, rgba(186, 7, 252, 1) 0%, rgba(252, 7, 111, 1) 0%, rgba(11, 10, 37, 1) 49%)",
      "gradient-bg-border-top": "linear-gradient(rgb(11, 9, 39, 1), rgba(11, 9, 39, 0) 100%)",
      "gradient-bg-border-bottom": "linear-gradient(rgba(11, 9, 39, 0), rgb(11, 9, 39, 1) 100%)",
    },
    vn: {
      blue: "#3DACFF",
      "blue-opacity": "3dacff40",
      "info-blue": "#73c0fa",
      "info-blue-opacity": "#73c0fa40",
      error: "#E43B3B",
      "error-opacity": "#e43b3b40",
      success: "#3FFCAE",
      "success-opacity": "#3FFCAE40",
      warning: "#FFA842",
      "warning-opacity": "#FFA84240",
      "black-grey": "#181818",
      "dark-grey": "#181A1C",
      grey: "#232425",
      "light-grey": "#787878",
      "paper-secondary-blue": "#e8f0fe",
      "paper-elevation-blue": "#eaf1fb",
      orange: "#F48517",
      maroon: "#FC076D",
      purple: "#BA07FC",
      "maroon-secondary": "#FC076F",
      "dark-blue": "#111F3B",
    },
    text: {
      primary: "#fff",
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(255, 255, 255, 0.5)",
      ter: "rgba(255, 255, 255, 0.3)",
      pri: "rgba(255, 255, 255, 0.05)",
    },
    primary: {
      main: "#3DACFF",
      light: "rgba(38, 156, 241, 0.1)",
    },
    error: {
      main: "#E43B3B",
      light: "rgba(242, 39, 39, 0.1)",
    },
    success: {
      main: "#32C78A",
      light: "rgba(50, 199, 138, 0.1)",
    },
    warning: {
      main: "#FFA726",
      light: "rgba(255, 167, 38, 0.1)",
    },
    action: {
      active: "#fff",
      hover: "rgba(255, 255, 255, 0.08)",
      selected: "rgba(255, 255, 255, 0.16)",
      disabled: "rgba(255, 255, 255, 0.3)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    background: {
      default: "#121212",
      paper: "#121212",
    },
    card: {
      main: "#181818",
      light: "#212121",
      dark: "#161616",
    },
    divider: "rgba(255, 255, 255, 0.12)",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiAccordion: {
      defaultProps: { disableGutters: true },
      styleOverrides: { root: { [`&.${accordionClasses.expanded}::before`]: { opacity: 1 } } },
    },
    MuiAccordionSummary: { defaultProps: { expandIcon: <ExpandMore /> } },
    MuiAccordionDetails: { styleOverrides: { root: { backgroundColor: "#181A1C" } } },
    MuiAppBar: {
      defaultProps: { position: "fixed" },
      styleOverrides: {
        root: {
          backgroundColor: "#181818",
          height: "70px",
          boxShadow: "1px 4px 10px 0px #00000012",
          zIndex: defaultDarkTheme.zIndex.drawer + 1,
          backgroundImage: "none",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            backgroundColor: "#3DACFF",
            borderRadius: "4px",
            color: "white",
            fontWeight: 600,
            height: "40px",
            padding: "8px 12px",
            "&:hover": { backgroundColor: "#349AE6" },
          },
        },
        {
          props: { variant: "red" },
          style: {
            backgroundColor: "#E43B3B",
            borderRadius: "4px",
            color: "white",
            fontWeight: 600,
            height: "40px",
            padding: "8px 12px",
            "&:hover": { backgroundColor: "#C83131" },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            borderRadius: "4px",
            color: "#3DACFF",
            fontWeight: 600,
            height: "40px",
            padding: "8px 12px",
            border: "1px solid #3DACFF",
            "&:hover": { backgroundColor: "#3DACFF0D", border: "1px solid #3DACFF" },
          },
        },
        {
          props: { variant: "text" },
          style: { color: "#3DACFF", fontWeight: 600, height: "40px", padding: "8px 12px", "&:hover": { backgroundColor: "#3DACFF0D" } },
        },
        {
          props: { variant: "grey" },
          style: {
            borderRadius: "4px",
            height: "40px",
            fontWeight: 600,
            padding: "8px 12px",
            color: "#c0c0c1",
            backgroundColor: "#2e2f30",
            border: "1.5px solid #434445",
          },
        },
      ],
      defaultProps: { variant: "contained" },
      styleOverrides: {
        root: {
          textTransform: "none",
          width: "fit-content",
        },
      },
    },
    MuiCheckbox: {
      defaultProps: { variant: "primary" },
      variants: [
        { props: { variant: "primary" }, style: { "& svg": { color: "#3DACFF" } } },
        { props: { variant: "secondary" }, style: { "& svg": { color: "#181818" } } },
        { props: { variant: "info" }, style: { "& svg": { color: "#73c0fa" } } },
        { props: { variant: "success" }, style: { "& svg": { color: "#3FFCAE" } } },
        { props: { variant: "warning" }, style: { "& svg": { color: "#FFA842" } } },
        { props: { variant: "error" }, style: { "& svg": { color: "#E43B3B" } } },
      ],
    },
    MuiFab: {
      variants: [
        {
          props: { color: "primary" },
          style: { backgroundColor: "#3DACFF", "&:hover": { backgroundColor: "#3DACFFCC" }, "& > svg": { color: "white" } },
        },
        {
          props: { color: "secondary" },
          style: { backgroundColor: "#181818", "&:hover": { backgroundColor: "#181818CC" }, "& > svg": { color: "white" } },
        },
        {
          props: { color: "info" },
          style: { backgroundColor: "#73c0fa", "&:hover": { backgroundColor: "#73C0FACC" }, "& > svg": { color: "white" } },
        },
        {
          props: { color: "success" },
          style: { backgroundColor: "#3FFCAE", "&:hover": { backgroundColor: "#3FFCAECC" }, "& > svg": { color: "white" } },
        },
        {
          props: { color: "warning" },
          style: { backgroundColor: "#FFA842", "&:hover": { backgroundColor: "#FFA842CC" }, "& > svg": { color: "white" } },
        },
        {
          props: { color: "error" },
          style: { backgroundColor: "#E43B3B", "&:hover": { backgroundColor: "#E43B3BCC" }, "& > svg": { color: "white" } },
        },
      ],
    },
    MuiRadio: { variants: [{ props: { color: "primary" }, style: { "& > span > svg": { color: "#3DACFF" } } }] },
    MuiRating: {
      variants: [
        {
          props: { color: "primary" },
          style: {
            [`& label > span.${ratingClasses.iconFilled} > svg`]: { color: "#faaf00" },
            [`& span.${ratingClasses.decimal} label > span.${ratingClasses.iconFilled} > svg`]: { color: "#faaf00" },
          },
        },
      ],
    },
    MuiSlider: { variants: [{ props: { color: "primary" }, style: { color: "#3DACFF" } }] },
    MuiDialog: {
      defaultProps: { PaperProps: { variant: "primary-p1rem" } },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: "#3DACFF",
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: { disableShrink: true },
      styleOverrides: {
        svg: {
          color: "#3DACFF",
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: { notched: false, sx: { borderColor: "#343434", maxHeight: "40px" } },
      styleOverrides: {
        root: {
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: { border: "1px solid rgba(255,255,255,0.3)" },
          [`&.${autocompleteClasses.focused} .${outlinedInputClasses.notchedOutline}`]: { border: "1px solid #3DACFF" },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#3DACFF",
        },
        flexContainer: {
          [`& .${tabClasses.root}.${tabClasses.selected}`]: {
            color: "#3DACFF",
          },
        },
      },
    },
    MuiTab: { styleOverrides: { root: { fontWeight: "bold", textTransform: "none", minHeight: "48px" } } },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
      styleOverrides: { root: { cursor: "pointer" } },
    },
    MuiPopover: { defaultProps: { PaperProps: { variant: "primary" } } },
    MuiInput: {
      variants: [
        { props: { variant: "filled" }, style: { backgroundColor: "#181818" } },
        { props: { variant: "hollow" }, style: {} },
      ],
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          height: "40px",
          border: "1px solid rgba(255, 255, 255, 0.23)",
          paddingLeft: "10px",
          borderRadius: "4px",
          "&:hover": { borderColor: "rgba(255,255,255,0.3)" },
          [`&.${inputClasses.focused}`]: { borderColor: "#3DACFF" },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          [`&.${switchClasses.checked}`]: {
            color: "#3DACFF",
          },
        },
      },
    },
    MuiToggleButtonGroup: { styleOverrides: { root: { height: "40px" } } },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          [`&.${toggleButtonClasses.selected}>svg.MuiSvgIcon-root`]: { color: "#3DACFF" },
          [`&.${toggleButtonClasses.selected}, &.${toggleButtonClasses.selected}:hover`]: { backgroundColor: "#3DACFF33" },
          "&:hover": { backgroundColor: "#3DACFF14" },
        },
      },
    },
    MuiTooltip: {
      defaultProps: { arrow: true },
      styleOverrides: { tooltip: { backgroundColor: "black" }, arrow: { color: "black" } },
    },
    MuiSelect: {
      defaultProps: { IconComponent: KeyboardArrowDown },
      variants: [
        {
          props: { variant: "filled" },
          style: {
            "&::before, &::after": { display: "none" },
            [`& .${selectClasses.select}`]: { maxHeight: "40px" },
            borderRadius: "10px",
            backgroundColor: "#181818",
            maxHeight: "40px",
          },
        },
        {
          props: { "variant-extended": "background-grey", variant: "filled" },
          style: {
            "&::before, &::after": { display: "none" },
            [`& .${selectClasses.select}`]: { maxHeight: "40px" },
            borderRadius: "10px",
            backgroundColor: "#212121",
            maxHeight: "40px",
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            "&::before, &::after": { display: "none" },
            [`& .${selectClasses.select}`]: { maxHeight: "40px" },
            [`&.${selectClasses.disabled}:hover .${outlinedInputClasses.notchedOutline}`]: { borderColor: "#ffffff4d" },
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: { borderColor: "#3e3e3e" },
            [`& .${outlinedInputClasses.notchedOutline}`]: { borderColor: "#343434" },
            [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: { borderColor: "#3DACFF", borderWidth: "1px" },
            borderRadius: "10px",
            backgroundColor: "#181818",
            maxHeight: "40px",
          },
        },
        {
          props: { "variant-extended": "background-grey", variant: "outlined" },
          style: {
            "&::before, &::after": { display: "none" },
            [`& .${selectClasses.select}`]: { maxHeight: "40px" },
            borderRadius: "10px",
            backgroundColor: "#212121",
            maxHeight: "40px",
          },
        },
      ],
      styleOverrides: {
        standard: {
          backgroundColor: "transparent",
          maxHeight: "40px",
          minWidth: "40px",
          padding: "8px 32px 8px 0px",
        },
        filled: {
          backgroundColor: "transparent",
          maxHeight: "40px",
          minWidth: "40px",
          borderRadius: "10px",
          padding: "8px 32px 8px 12px",
        },
        icon: { color: "#bababa" },
      },
    },
    MuiSvgIcon: {
      variants: [
        { props: { color: "primary" }, style: { color: "#bababa", "&>svg": { color: "#bababa", fill: "#bababa" } } },
        { props: { color: "secondary" }, style: { color: "#3DACFF", "&>svg": { color: "#3DACFF", fill: "#3DACFF" } } },
        { props: { color: "error" }, style: { color: "#E43B3B", "&>svg": { color: "#E43B3B", fill: "#E43B3B" } } },
        { props: { color: "success" }, style: { color: "#3FFCAE", "&>svg": { color: "#3FFCAE", fill: "#3FFCAE" } } },
        { props: { color: "warning" }, style: { color: "#FFA842", "&>svg": { color: "#FFA842", fill: "#FFA842" } } },
        { props: { color: "info" }, style: { color: "#73c0fa", "&>svg": { color: "#73c0fa", fill: "#73c0fa" } } },
      ],
      defaultProps: { color: "primary" },
      styleOverrides: { root: { "&.Side-Menu.indicator.active": { color: "#3DACFF", width: "0.5rem" }, "&.Side-Menu.indicator": { width: "0.5rem" } } },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          "&.Plan-Billing-Tab": { border: "1px solid #5a5a5a" },
          "&.Workspaces-Box": {
            border: "1px solid #4d4d4d",
            backgroundColor: "#212121",
            cursor: "pointer",
            padding: "14px",
            display: "flex",
            gap: "8px",
            justifyContent: "flex-start",
            borderRadius: "4px",
            flexGrow: 1,
            alignItems: "center",
          },
          "&.Accounts-Box": {
            border: "1px solid #4d4d4d",
            backgroundColor: "#212121",
            width: "100%",
            height: "100%",
            borderRadius: "2rem",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          },
          "&.Workspace-Item": {
            color: "rgba(255,255,255,0.7)",
            "&:hover": { color: "rgba(255,255,255)" },
          },
          "&.Divider": {
            backgroundColor: "rgba(255, 255, 255, 0.3)",
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: "#bababa",
          [`& .${svgIconClasses.root}`]: { color: "black" },
        },
      },
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: "outlined-primary" },
          style: { backgroundColor: "#181818", border: "1px solid #434445", color: "#fff", backgroundImage: "none" },
        },
        {
          props: { variant: "outlined-primary-p0.5rem" },
          style: { backgroundColor: "#181818", border: "1px solid #434445", color: "#fff", backgroundImage: "none", padding: "0.5rem" },
        },
        {
          props: { variant: "outlined-primary-p1rem" },
          style: { backgroundColor: "#181818", border: "1px solid #434445", color: "#fff", backgroundImage: "none", padding: "1rem" },
        },
        {
          props: { variant: "outlined-primary-p1.5rem" },
          style: { backgroundColor: "#181818", border: "1px solid #434445", color: "#fff", backgroundImage: "none", padding: "1.5rem" },
        },
        { props: { variant: "primary" }, style: { backgroundColor: "#181818", color: "#fff", backgroundImage: "none" } },
        { props: { variant: "primary-p0.5rem" }, style: { backgroundColor: "#181818", color: "#fff", backgroundImage: "none", padding: "0.5rem" } },
        { props: { variant: "primary-p1rem" }, style: { backgroundColor: "#181818", color: "#fff", backgroundImage: "none", padding: "1rem" } },
        { props: { variant: "primary-p1.5rem" }, style: { backgroundColor: "#181818", color: "#fff", backgroundImage: "none", padding: "1.5rem" } },
        {
          props: { variant: "outlined-secondary" },
          style: { backgroundColor: "#181A1C", border: "1px solid #434445", color: "#fff", backgroundImage: "none" },
        },
        {
          props: { variant: "outlined-secondary-p0.5rem" },
          style: { backgroundColor: "#181A1C", border: "1px solid #434445", color: "#fff", backgroundImage: "none", padding: "0.5rem" },
        },
        {
          props: { variant: "outlined-secondary-p1rem" },
          style: { backgroundColor: "#181A1C", border: "1px solid #434445", color: "#fff", backgroundImage: "none", padding: "1rem" },
        },
        {
          props: { variant: "outlined-secondary-p1.5rem" },
          style: { backgroundColor: "#181A1C", border: "1px solid #434445", color: "#fff", backgroundImage: "none", padding: "1.5rem" },
        },
        { props: { variant: "secondary" }, style: { backgroundColor: "#181A1C", color: "#fff", backgroundImage: "none" } },
        { props: { variant: "secondary-p0.5rem" }, style: { backgroundColor: "#181A1C", color: "#fff", backgroundImage: "none", padding: "0.5rem" } },
        { props: { variant: "secondary-p1rem" }, style: { backgroundColor: "#181A1C", color: "#fff", backgroundImage: "none", padding: "1rem" } },
        { props: { variant: "secondary-p1.5rem" }, style: { backgroundColor: "#181A1C", color: "#fff", backgroundImage: "none", padding: "1.5rem" } },
        {
          props: { variant: "outlined-light" },
          style: { backgroundColor: "#232425", border: "1px solid #434445", color: "#fff", backgroundImage: "none" },
        },
        {
          props: { variant: "outlined-light-p0.5rem" },
          style: { backgroundColor: "#232425", border: "1px solid #434445", color: "#fff", backgroundImage: "none", padding: "0.5rem" },
        },
        {
          props: { variant: "outlined-light-p1rem" },
          style: { backgroundColor: "#232425", border: "1px solid #434445", color: "#fff", backgroundImage: "none", padding: "1rem" },
        },
        {
          props: { variant: "outlined-light-p1.5rem" },
          style: { backgroundColor: "#232425", border: "1px solid #434445", color: "#fff", backgroundImage: "none", padding: "1.5rem" },
        },
        { props: { variant: "light" }, style: { backgroundColor: "#232425", color: "#fff", backgroundImage: "none" } },
        { props: { variant: "light-p0.5rem" }, style: { backgroundColor: "#232425", color: "#fff", backgroundImage: "none", padding: "0.5rem" } },
        { props: { variant: "light-p1rem" }, style: { backgroundColor: "#232425", color: "#fff", backgroundImage: "none", padding: "1rem" } },
        { props: { variant: "light-p1.5rem" }, style: { backgroundColor: "#232425", color: "#fff", backgroundImage: "none", padding: "1.5rem" } },
        { props: { variant: "outlined" }, style: { backgroundColor: "#1e1e1e", border: "1px solid #434445" } },
        { props: { variant: "outlined-p0.5rem" }, style: { backgroundColor: "#1e1e1e", padding: "0.5rem", border: "1px solid #434445" } },
        { props: { variant: "outlined-p1rem" }, style: { backgroundColor: "#1e1e1e", padding: "1rem", border: "1px solid #434445" } },
        { props: { variant: "outlined-p1.5rem" }, style: { backgroundColor: "#1e1e1e", padding: "1.5rem", border: "1px solid #434445" } },
        { props: { variant: "elevation" }, style: { boxShadow: "none" } },
        { props: { variant: "elevation-p0.5rem" }, style: { padding: "0.5rem", boxShadow: "none" } },
        { props: { variant: "elevation-p1rem" }, style: { padding: "1rem", boxShadow: "none" } },
        { props: { variant: "elevation-p1.5rem" }, style: { padding: "1.5rem", boxShadow: "none" } },
        { props: { variant: "mediakits" }, style: { backgroundColor: "rgba(0, 0, 0, 0.6)", boxShadow: "none" } },
        { props: { variant: "mediakits-opacity-white" }, style: { backgroundColor: "rgba(225, 225, 225, 0.05)", boxShadow: defaultDarkTheme.shadows[1] } },
        {
          props: { variant: "mediakits-p0.5rem" },
          style: { backgroundColor: "rgba(0, 0, 0, 0.6)", padding: "0.5rem", boxShadow: defaultDarkTheme.shadows[1] },
        },
        { props: { variant: "mediakits-p1rem" }, style: { backgroundColor: "rgba(0, 0, 0, 0.6)", padding: "1rem", boxShadow: defaultDarkTheme.shadows[1] } },
        {
          props: { variant: "mediakits-p1.5rem" },
          style: { backgroundColor: "rgba(0, 0, 0, 0.6)", padding: "1.5rem", boxShadow: defaultDarkTheme.shadows[1] },
        },
        { props: { variant: "mediakits-overall-bg" }, style: { backgroundColor: "rgba(0, 0, 0, 0.6)" } },
        { props: { variant: "mediakits-overall-bg-p0.5rem" }, style: { backgroundColor: "rgba(0, 0, 0, 0.6)", padding: "0.5rem" } },
        { props: { variant: "mediakits-overall-bg-p1rem" }, style: { backgroundColor: "rgba(0, 0, 0, 0.6)", padding: "1rem" } },
        { props: { variant: "mediakits-overall-bg-p1.5rem" }, style: { backgroundColor: "rgba(0, 0, 0, 0.6)", padding: "1.5rem" } },
        {
          props: { variant: "socialGradients-instagram" },
          style: { background: "linear-gradient(73.09deg, #C650BF 0%, #EE377D 50%, #ED7035 76.04%, #FC9B3D 100%)", boxShadow: defaultDarkTheme.shadows[1] },
        },
        {
          props: { variant: "socialGradients-instagram-p0.5rem" },
          style: {
            background: "linear-gradient(73.09deg, #C650BF 0%, #EE377D 50%, #ED7035 76.04%, #FC9B3D 100%)",
            boxShadow: defaultDarkTheme.shadows[1],
            padding: "0.5rem",
          },
        },
        {
          props: { variant: "socialGradients-instagram-p1rem" },
          style: {
            background: "linear-gradient(73.09deg, #C650BF 0%, #EE377D 50%, #ED7035 76.04%, #FC9B3D 100%)",
            boxShadow: defaultDarkTheme.shadows[1],
            padding: "1rem",
          },
        },
        {
          props: { variant: "socialGradients-instagram-p1.5rem" },
          style: {
            background: "linear-gradient(73.09deg, #C650BF 0%, #EE377D 50%, #ED7035 76.04%, #FC9B3D 100%)",
            boxShadow: defaultDarkTheme.shadows[1],
            padding: "1.5rem",
          },
        },
        {
          props: { variant: "socialGradients-linkedin" },
          style: { background: "linear-gradient(270deg, #095BC3 0%, #4F99F7 100%)", boxShadow: defaultDarkTheme.shadows[1] },
        },
        {
          props: { variant: "socialGradients-linkedin-p0.5rem" },
          style: {
            background: "linear-gradient(270deg, #095BC3 0%, #4F99F7 100%)",
            boxShadow: defaultDarkTheme.shadows[1],
            padding: "0.5rem",
          },
        },
        {
          props: { variant: "socialGradients-linkedin-p1rem" },
          style: {
            background: "linear-gradient(270deg, #095BC3 0%, #4F99F7 100%)",
            boxShadow: defaultDarkTheme.shadows[1],
            padding: "1rem",
          },
        },
        {
          props: { variant: "socialGradients-linkedin-p1.5rem" },
          style: {
            background: "linear-gradient(270deg, #095BC3 0%, #4F99F7 100%)",
            boxShadow: defaultDarkTheme.shadows[1],
            padding: "1.5rem",
          },
        },
        {
          props: { variant: "socialGradients-tiktok" },
          style: { background: "linear-gradient(256.56deg, #1ECACC 0%, #0285CD 100%)", boxShadow: defaultDarkTheme.shadows[1] },
        },
        {
          props: { variant: "socialGradients-tiktok-p0.5rem" },
          style: {
            background: "linear-gradient(256.56deg, #1ECACC 0%, #0285CD 100%)",
            boxShadow: defaultDarkTheme.shadows[1],
            padding: "0.5rem",
          },
        },
        {
          props: { variant: "socialGradients-tiktok-p1rem" },
          style: {
            background: "linear-gradient(256.56deg, #1ECACC 0%, #0285CD 100%)",
            boxShadow: defaultDarkTheme.shadows[1],
            padding: "1rem",
          },
        },
        {
          props: { variant: "socialGradients-tiktok-p1.5rem" },
          style: {
            background: "linear-gradient(256.56deg, #1ECACC 0%, #0285CD 100%)",
            boxShadow: defaultDarkTheme.shadows[1],
            padding: "1.5rem",
          },
        },
      ],
    },
    MuiMenu: {
      defaultProps: { PaperProps: { variant: "primary" } },
    },
    MuiChip: {
      variants: [
        { props: { variant: "filled-blue" }, style: { color: "#3DACFF", backgroundColor: "#3DACFF26" } },
        { props: { variant: "outlined-blue" }, style: { color: "#3DACFF", backgroundColor: "transparent", border: "1px solid #3DACFF" } },
        { props: { variant: "filled-red" }, style: { color: "#E43B3B", backgroundColor: "#E43B3B26" } },
        { props: { variant: "outlined-red" }, style: { color: "#E43B3B", backgroundColor: "transparent", border: "1px solid #E43B3B" } },
        { props: { variant: "filled-grey" }, style: { color: "#787878", backgroundColor: "#78787826" } },
        { props: { variant: "outlined-grey" }, style: { color: "#787878", backgroundColor: "transparent", border: "1px solid #787878" } },
        { props: { variant: "filled-orange" }, style: { color: "#FFA842", backgroundColor: "#FFA84226" } },
        { props: { variant: "outlined-orange" }, style: { color: "#FFA842", backgroundColor: "transparent", border: "1px solid #FFA842" } },
        { props: { variant: "filled-green" }, style: { color: "#3FFCAE", backgroundColor: "#3FFCAE26" } },
        { props: { variant: "outlined-green" }, style: { color: "#3FFCAE", backgroundColor: "transparent", border: "1px solid #3FFCAE" } },
        { props: { variant: "filled-purple" }, style: { color: "#CA97F2", backgroundColor: "##CA97F226" } },
        { props: { variant: "outlined-purple" }, style: { color: "#CA97F2", backgroundColor: "transparent", border: "1px solid #CA97F2" } },
      ],
    },
    MuiList: {
      variants: [
        {
          props: { variant: "striped" },
          style: {
            [`& .${listItemClasses.root}`]: { "&:nth-of-type(even)": { backgroundColor: "#212121" }, "&:nth-of-type(odd)": { backgroundColor: "#161616" } },
          },
        },
      ],
    },
    MuiTextField: {
      defaultProps: { InputProps: { variant: "hollow" }, InputLabelProps: { shrink: true } },
    },
    MuiAutocomplete: {
      variants: [
        {
          props: { variant: "filled" },
          style: {
            [`& .${autocompleteClasses.inputRoot}`]: {
              borderRadius: "10px",
              backgroundColor: "#181818",
              [`& .${outlinedInputClasses.notchedOutline}`]: { border: "none" },
            },
            [`&.Mui-focused .${autocompleteClasses.inputRoot}`]: {
              backgroundColor: "rgba(255, 255, 255, 0.09)",
            },
            [`&:hover .${autocompleteClasses.inputRoot}`]: {
              backgroundColor: "rgba(255, 255, 255, 0.13)",
            },
          },
        },
        {
          props: { "variant-extended": "background-grey", variant: "filled" },
          style: {
            [`& .${autocompleteClasses.inputRoot}`]: {
              borderRadius: "10px",
              backgroundColor: "#212121",
              [`& .${outlinedInputClasses.notchedOutline}`]: { border: "none" },
            },
            [`&.Mui-focused .${autocompleteClasses.inputRoot}`]: {
              backgroundColor: "rgba(255, 255, 255, 0.09)",
            },
            [`&:hover .${autocompleteClasses.inputRoot}`]: {
              backgroundColor: "rgba(255, 255, 255, 0.13)",
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            [`& .${autocompleteClasses.inputRoot}`]: {
              borderRadius: "10px",
              backgroundColor: "#181818",
              [`& .${outlinedInputClasses.notchedOutline}`]: { border: "1px solid #343434" },
              [`&:hover .${outlinedInputClasses.notchedOutline}`]: { borderColor: "#3e3e3e" },
              [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: { borderColor: "#3DACFF" },
            },
          },
        },
        {
          props: { "variant-extended": "background-grey", variant: "outlined" },
          style: {
            [`& .${autocompleteClasses.inputRoot}`]: {
              borderRadius: "10px",
              backgroundColor: "#212121",
              [`& .${outlinedInputClasses.notchedOutline}`]: { border: "1px solid #343434" },
              [`&:hover .${outlinedInputClasses.notchedOutline}`]: { borderColor: "#3e3e3e" },
              [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: { borderColor: "#3DACFF" },
            },
          },
        },
      ],
      defaultProps: { popupIcon: <KeyboardArrowDown /> },
      styleOverrides: { root: { [`& .${outlinedInputClasses.root} .${autocompleteClasses.input}`]: { padding: 0 } } },
    },
    MuiDataGrid: {
      defaultProps: { disableSelectionOnClick: true, componentsProps: { baseButton: { variant: "text" }, baseCheckbox: { variant: "default" } } },
      styleOverrides: {
        root: { border: "1px solid #343434" },
        panel: { [`& .${gridClasses.paper}.${paperClasses.root}`]: { backgroundImage: "none", boxShadow: defaultDarkTheme.shadows[3] } },
        footerContainer: { backgroundImage: "none", backgroundColor: "#181818", color: "#909090", [`& > td`]: { borderBottom: "none" } },
        panelHeader: { "& label": { position: "block", transform: "none", fontSize: "12px" }, "& label.Mui-focused": { color: "#3DACFF" } },
        panelFooter: { "&>button>span>svg": { color: "inherit" } },
        columnHeaders: {
          backgroundColor: "#212121",
          borderBottom: "1px solid #343434",
          color: "#bababa",
        },
        columnHeader: { "&:focus-within": { outlineColor: "#3DACFF" }, "&:focus": { outlineColor: "#3DACFF" } },
        pinnedColumnHeaders: { backgroundImage: "none", backgroundColor: "#212121" },
        "pinnedColumnHeaders--left": { boxShadow: "14px 0px 16px rgba(0, 0, 0, 0.25)" },
        "pinnedColumnHeaders--right": { boxShadow: "-14px 0px 16px rgba(0, 0, 0, 0.25)" },
        pinnedColumns: { backgroundImage: "none" },
        "pinnedColumns--left": { boxShadow: "14px 0px 16px rgba(0, 0, 0, 0.25)" },
        "pinnedColumns--right": { boxShadow: "-14px 0px 16px rgba(0, 0, 0, 0.25)" },
        //columnSeparator: { display: "none" },
        filterFormValueInput: { "& > div > label.Mui-focused": { color: "#3DACFF" } },
        filterFormOperatorInput: { "& > label.Mui-focused": { color: "#3DACFF" } },
        filterFormColumnInput: { "& > label.Mui-focused": { color: "#3DACFF" } },
        filterFormDeleteIcon: { "& > button > svg": { color: "#E43B3B" } },
        filterForm: { gap: "1rem" },
        row: {
          "&:not(:last-of-type)": { borderBottom: "1px solid #343434" },
          "&:nth-of-type(odd)": { backgroundColor: "#181818" },
          "&:nth-of-type(even)": { backgroundColor: "#212121" },
          "&:hover": { backgroundColor: "#121212" },
          cursor: "pointer",
          color: "#bababa",
        },
        cell: { borderBottom: "none", "&:focus-within": { outlineColor: "#3DACFF" }, "&:focus": { outlineColor: "#3DACFF" } },
        toolbarContainer: {
          backgroundImage: "none",
          backgroundColor: "#181818",
          gap: "5px",
          paddingBottom: "5px",
          [`& .${buttonClasses.root}`]: { backgroundColor: "transparent", color: "#bababa", boxShadow: "none", "&:hover": { backgroundColor: "#3dacff14" } },
        },
      },
    },
    MuiTablePagination: { styleOverrides: { root: { color: "#bababa" } } },
    MuiTableContainer: { styleOverrides: { root: { backgroundColor: "#121212" } } },
    MuiTableCell: { styleOverrides: { root: { border: "none", color: "#5f6368" } } },
    MuiTableHead: {
      styleOverrides: {
        root: {
          color: "#5f6368",
          backgroundColor: "#212121",
          borderBottom: "1px solid #343434",
          [`&>tr.${tableRowClasses.root}.${tableRowClasses.head}`]: { border: "none !important", backgroundColor: "#212121" },
          [`&>tr.${tableRowClasses.root}.${tableRowClasses.head}:hover`]: { backgroundColor: "#212121" },
          [`&>tr.${tableRowClasses.root}>th.${tableCellClasses.root}`]: { border: "none !important", fontWeight: 600 },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          border: 0,
          [`&:nth-of-type(odd)`]: { backgroundColor: "#181818" },
          [`&:nth-of-type(even)`]: { backgroundColor: "#212121" },
          [`&:hover`]: { backgroundColor: "#121212" },
          cursor: "pointer",
          color: "#5f6368",
        },
      },
    },
    MuiTypography: {
      variants: [
        { props: { variant: "h1" }, style: { color: "white", fontSize: "56px", lineHeight: "91.43%", letterSpacing: "-1.5px" } },
        { props: { variant: "h2" }, style: { color: "white", fontSize: "48px", lineHeight: "99.56%", letterSpacing: "-0.5px" } },
        { props: { variant: "h3" }, style: { color: "white", fontSize: "40px", lineHeight: "102.4%" } },
        { props: { variant: "h4" }, style: { color: "white", fontSize: "32px", lineHeight: "106.7%" } },
        { props: { variant: "h5" }, style: { color: "white", fontSize: "28px", lineHeight: "109.71%", letterSpacing: "0.18px" } },
        { props: { variant: "h6" }, style: { color: "white", fontSize: "24px", lineHeight: "85.33%", letterSpacing: "0.15px" } },
        { props: { variant: "h7" }, style: { color: "white", fontSize: "18px", lineHeight: "113.78%", letterSpacing: "0.15px" } },
        { props: { variant: "body1" }, style: { color: "white", fontSize: "16px", lineHeight: "128%", letterSpacing: "0.5px" } },
        { props: { variant: "body2" }, style: { color: "white", fontSize: "14px", lineHeight: "121.9%", letterSpacing: "0.25px" } },
        { props: { variant: "caption" }, style: { color: "white", fontSize: "12px", lineHeight: "113.78%", letterSpacing: "0.4px" } },
        { props: { variant: "opacity10-8px" }, style: { color: "rgba(255,255,255,0.1)", fontSize: "8px" } },
        { props: { variant: "opacity10-12px" }, style: { color: "rgba(255,255,255,0.1)", fontSize: "12px" } },
        { props: { variant: "opacity10-14px" }, style: { color: "rgba(255,255,255,0.1)", fontSize: "14px" } },
        { props: { variant: "opacity10" }, style: { color: "rgba(255,255,255,0.1)" } },
        { props: { variant: "opacity10-18px" }, style: { color: "rgba(255,255,255,0.1)", fontSize: "18px" } },
        { props: { variant: "opacity10-20px" }, style: { color: "rgba(255,255,255,0.1)", fontSize: "20px" } },
        { props: { variant: "opacity10-24px" }, style: { color: "rgba(255,255,255,0.1)", fontSize: "24px" } },
        { props: { variant: "opacity10-28px" }, style: { color: "rgba(255,255,255,0.1)", fontSize: "28px" } },
        { props: { variant: "opacity10-32px" }, style: { color: "rgba(255,255,255,0.1)", fontSize: "32px" } },
        { props: { variant: "opacity10-36px" }, style: { color: "rgba(255,255,255,0.1)", fontSize: "36px" } },
        { props: { variant: "opacity10-40px" }, style: { color: "rgba(255,255,255,0.1)", fontSize: "40px" } },
        { props: { variant: "opacity10-44px" }, style: { color: "rgba(255,255,255,0.1)", fontSize: "44px" } },
        { props: { variant: "opacity10-48px" }, style: { color: "rgba(255,255,255,0.1)", fontSize: "48px" } },
        { props: { variant: "opacity10-52px" }, style: { color: "rgba(255,255,255,0.1)", fontSize: "52px" } },
        { props: { variant: "opacity10-56px" }, style: { color: "rgba(255,255,255,0.1)", fontSize: "56px" } },
        { props: { variant: "opacity10-60px" }, style: { color: "rgba(255,255,255,0.1)", fontSize: "60px" } },
        { props: { variant: "opacity10-64px" }, style: { color: "rgba(255,255,255,0.1)", fontSize: "64px" } },
        { props: { variant: "opacity20-8px" }, style: { color: "rgba(255,255,255,0.2)", fontSize: "8px" } },
        { props: { variant: "opacity20-12px" }, style: { color: "rgba(255,255,255,0.2)", fontSize: "12px" } },
        { props: { variant: "opacity20-14px" }, style: { color: "rgba(255,255,255,0.2)", fontSize: "14px" } },
        { props: { variant: "opacity20" }, style: { color: "rgba(255,255,255,0.2)" } },
        { props: { variant: "opacity20-18px" }, style: { color: "rgba(255,255,255,0.2)", fontSize: "18px" } },
        { props: { variant: "opacity20-20px" }, style: { color: "rgba(255,255,255,0.2)", fontSize: "20px" } },
        { props: { variant: "opacity20-24px" }, style: { color: "rgba(255,255,255,0.2)", fontSize: "24px" } },
        { props: { variant: "opacity20-28px" }, style: { color: "rgba(255,255,255,0.2)", fontSize: "28px" } },
        { props: { variant: "opacity20-32px" }, style: { color: "rgba(255,255,255,0.2)", fontSize: "32px" } },
        { props: { variant: "opacity20-36px" }, style: { color: "rgba(255,255,255,0.2)", fontSize: "36px" } },
        { props: { variant: "opacity20-40px" }, style: { color: "rgba(255,255,255,0.2)", fontSize: "40px" } },
        { props: { variant: "opacity20-44px" }, style: { color: "rgba(255,255,255,0.2)", fontSize: "44px" } },
        { props: { variant: "opacity20-48px" }, style: { color: "rgba(255,255,255,0.2)", fontSize: "48px" } },
        { props: { variant: "opacity20-52px" }, style: { color: "rgba(255,255,255,0.2)", fontSize: "52px" } },
        { props: { variant: "opacity20-56px" }, style: { color: "rgba(255,255,255,0.2)", fontSize: "56px" } },
        { props: { variant: "opacity20-60px" }, style: { color: "rgba(255,255,255,0.2)", fontSize: "60px" } },
        { props: { variant: "opacity20-64px" }, style: { color: "rgba(255,255,255,0.2)", fontSize: "64px" } },
        { props: { variant: "opacity30-8px" }, style: { color: "rgba(255,255,255,0.3)", fontSize: "8px" } },
        { props: { variant: "opacity30-12px" }, style: { color: "rgba(255,255,255,0.3)", fontSize: "12px" } },
        { props: { variant: "opacity30-14px" }, style: { color: "rgba(255,255,255,0.3)", fontSize: "14px" } },
        { props: { variant: "opacity30" }, style: { color: "rgba(255,255,255,0.3)" } },
        { props: { variant: "opacity30-18px" }, style: { color: "rgba(255,255,255,0.3)", fontSize: "18px" } },
        { props: { variant: "opacity30-20px" }, style: { color: "rgba(255,255,255,0.3)", fontSize: "20px" } },
        { props: { variant: "opacity30-24px" }, style: { color: "rgba(255,255,255,0.3)", fontSize: "24px" } },
        { props: { variant: "opacity30-28px" }, style: { color: "rgba(255,255,255,0.3)", fontSize: "28px" } },
        { props: { variant: "opacity30-32px" }, style: { color: "rgba(255,255,255,0.3)", fontSize: "32px" } },
        { props: { variant: "opacity30-36px" }, style: { color: "rgba(255,255,255,0.3)", fontSize: "36px" } },
        { props: { variant: "opacity30-40px" }, style: { color: "rgba(255,255,255,0.3)", fontSize: "40px" } },
        { props: { variant: "opacity30-44px" }, style: { color: "rgba(255,255,255,0.3)", fontSize: "44px" } },
        { props: { variant: "opacity30-48px" }, style: { color: "rgba(255,255,255,0.3)", fontSize: "48px" } },
        { props: { variant: "opacity30-52px" }, style: { color: "rgba(255,255,255,0.3)", fontSize: "52px" } },
        { props: { variant: "opacity30-56px" }, style: { color: "rgba(255,255,255,0.3)", fontSize: "56px" } },
        { props: { variant: "opacity30-60px" }, style: { color: "rgba(255,255,255,0.3)", fontSize: "60px" } },
        { props: { variant: "opacity30-64px" }, style: { color: "rgba(255,255,255,0.3)", fontSize: "64px" } },
        { props: { variant: "opacity50-8px" }, style: { color: "rgba(255,255,255,0.5)", fontSize: "8px" } },
        { props: { variant: "opacity50-12px" }, style: { color: "rgba(255,255,255,0.5)", fontSize: "12px" } },
        { props: { variant: "opacity50-14px" }, style: { color: "rgba(255,255,255,0.5)", fontSize: "14px" } },
        { props: { variant: "opacity50" }, style: { color: "rgba(255,255,255,0.5)" } },
        { props: { variant: "opacity50-18px" }, style: { color: "rgba(255,255,255,0.5)", fontSize: "18px" } },
        { props: { variant: "opacity50-20px" }, style: { color: "rgba(255,255,255,0.5)", fontSize: "20px" } },
        { props: { variant: "opacity50-24px" }, style: { color: "rgba(255,255,255,0.5)", fontSize: "24px" } },
        { props: { variant: "opacity50-28px" }, style: { color: "rgba(255,255,255,0.5)", fontSize: "28px" } },
        { props: { variant: "opacity50-32px" }, style: { color: "rgba(255,255,255,0.5)", fontSize: "32px" } },
        { props: { variant: "opacity50-36px" }, style: { color: "rgba(255,255,255,0.5)", fontSize: "36px" } },
        { props: { variant: "opacity50-40px" }, style: { color: "rgba(255,255,255,0.5)", fontSize: "40px" } },
        { props: { variant: "opacity50-44px" }, style: { color: "rgba(255,255,255,0.5)", fontSize: "44px" } },
        { props: { variant: "opacity50-48px" }, style: { color: "rgba(255,255,255,0.5)", fontSize: "48px" } },
        { props: { variant: "opacity50-52px" }, style: { color: "rgba(255,255,255,0.5)", fontSize: "52px" } },
        { props: { variant: "opacity50-56px" }, style: { color: "rgba(255,255,255,0.5)", fontSize: "56px" } },
        { props: { variant: "opacity50-60px" }, style: { color: "rgba(255,255,255,0.5)", fontSize: "60px" } },
        { props: { variant: "opacity50-64px" }, style: { color: "rgba(255,255,255,0.5)", fontSize: "64px" } },
        { props: { variant: "opacity70-8px" }, style: { color: "rgba(255,255,255,0.7)", fontSize: "8px" } },
        { props: { variant: "opacity70-12px" }, style: { color: "rgba(255,255,255,0.7)", fontSize: "12px" } },
        { props: { variant: "opacity70-14px" }, style: { color: "rgba(255,255,255,0.7)", fontSize: "14px" } },
        { props: { variant: "opacity70" }, style: { color: "rgba(255,255,255,0.7)" } },
        { props: { variant: "opacity70-18px" }, style: { color: "rgba(255,255,255,0.7)", fontSize: "18px" } },
        { props: { variant: "opacity70-20px" }, style: { color: "rgba(255,255,255,0.7)", fontSize: "20px" } },
        { props: { variant: "opacity70-24px" }, style: { color: "rgba(255,255,255,0.7)", fontSize: "24px" } },
        { props: { variant: "opacity70-28px" }, style: { color: "rgba(255,255,255,0.7)", fontSize: "28px" } },
        { props: { variant: "opacity70-32px" }, style: { color: "rgba(255,255,255,0.7)", fontSize: "32px" } },
        { props: { variant: "opacity70-36px" }, style: { color: "rgba(255,255,255,0.7)", fontSize: "36px" } },
        { props: { variant: "opacity70-40px" }, style: { color: "rgba(255,255,255,0.7)", fontSize: "40px" } },
        { props: { variant: "opacity70-44px" }, style: { color: "rgba(255,255,255,0.7)", fontSize: "44px" } },
        { props: { variant: "opacity70-48px" }, style: { color: "rgba(255,255,255,0.7)", fontSize: "48px" } },
        { props: { variant: "opacity70-52px" }, style: { color: "rgba(255,255,255,0.7)", fontSize: "52px" } },
        { props: { variant: "opacity70-56px" }, style: { color: "rgba(255,255,255,0.7)", fontSize: "56px" } },
        { props: { variant: "opacity70-60px" }, style: { color: "rgba(255,255,255,0.7)", fontSize: "60px" } },
        { props: { variant: "opacity70-64px" }, style: { color: "rgba(255,255,255,0.7)", fontSize: "64px" } },
        { props: { variant: "opacity95-8px" }, style: { color: "rgba(255,255,255,0.95)", fontSize: "8px" } },
        { props: { variant: "opacity95-12px" }, style: { color: "rgba(255,255,255,0.95)", fontSize: "12px" } },
        { props: { variant: "opacity95-14px" }, style: { color: "rgba(255,255,255,0.95)", fontSize: "14px" } },
        { props: { variant: "opacity95" }, style: { color: "rgba(255,255,255,0.95)" } },
        { props: { variant: "opacity95-18px" }, style: { color: "rgba(255,255,255,0.95)", fontSize: "18px" } },
        { props: { variant: "opacity95-20px" }, style: { color: "rgba(255,255,255,0.95)", fontSize: "20px" } },
        { props: { variant: "opacity95-24px" }, style: { color: "rgba(255,255,255,0.95)", fontSize: "24px" } },
        { props: { variant: "opacity95-28px" }, style: { color: "rgba(255,255,255,0.95)", fontSize: "28px" } },
        { props: { variant: "opacity95-32px" }, style: { color: "rgba(255,255,255,0.95)", fontSize: "32px" } },
        { props: { variant: "opacity95-36px" }, style: { color: "rgba(255,255,255,0.95)", fontSize: "36px" } },
        { props: { variant: "opacity95-40px" }, style: { color: "rgba(255,255,255,0.95)", fontSize: "40px" } },
        { props: { variant: "opacity95-44px" }, style: { color: "rgba(255,255,255,0.95)", fontSize: "44px" } },
        { props: { variant: "opacity95-48px" }, style: { color: "rgba(255,255,255,0.95)", fontSize: "48px" } },
        { props: { variant: "opacity95-52px" }, style: { color: "rgba(255,255,255,0.95)", fontSize: "52px" } },
        { props: { variant: "opacity95-56px" }, style: { color: "rgba(255,255,255,0.95)", fontSize: "56px" } },
        { props: { variant: "opacity95-60px" }, style: { color: "rgba(255,255,255,0.95)", fontSize: "60px" } },
        { props: { variant: "opacity95-64px" }, style: { color: "rgba(255,255,255,0.95)", fontSize: "64px" } },
        { props: { variant: "error" }, style: { color: "#E43B3B" } },
      ],
      styleOverrides: {
        root: {
          letterSpacing: "0.25px",
        },
      },
    },
    MuiDatePicker: {
      defaultProps: { InputAdornmentProps: { sx: { "& button": { padding: "5px" } } } },
    },
    MuiDesktopTimePicker: { defaultProps: { InputAdornmentProps: { sx: { "& button": { padding: "5px" } } } } },
    MuiClock: {
      styleOverrides: {
        amButton: { backgroundColor: "#3DACFF", "&:hover": { backgroundColor: "#3DACFFCC" } },
        pmButton: { backgroundColor: "#3DACFF", "&:hover": { backgroundColor: "#3DACFFCC" } },
        pin: { backgroundColor: "#3DACFF" },
      },
    },
    MuiClockPointer: { styleOverrides: { root: { backgroundColor: "#3DACFF" }, thumb: { backgroundColor: "#3DACFF", borderColor: "#3DACFF" } } },
    MuiPickersPopper: {
      styleOverrides: { root: { [`& .${pickersPopperClasses.paper}`]: { backgroundImage: "none" } } },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.Side-Menu>svg": { color: "#bababa" },
          "&.Side-Menu.expanded>svg": { color: "white" },
          "& .sun-and-moon > :is(.moon, .sun)": { fill: "#bababa" },
          "& .sun-and-moon > .sun-beams": { stroke: "#bababa" },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Side-Menu": {
            borderRadius: "2rem",
            display: "flex",
            padding: "0.5rem 1rem",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          },
          "&.Side-Menu:hover": { backgroundColor: "rgba(255, 255, 255, 0.08)" },
          "&.Side-Menu.expanded": { color: "white", backgroundColor: "rgba(255, 255, 255, 0.08)" },
          "&.Side-Menu.Sub-Menu": { borderRadius: "2px" },
          "&.Side-Menu.Sub-Menu.expanded": { color: "white", backgroundColor: "transparent" },
          "&.Side-Menu.Sub-Menu.expanded:hover": { backgroundColor: "rgba(255, 255, 255, 0.08)" },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          "&.Side-Menu.active": { color: "white" },
          "&.SideMenu:hover": { backgroundColor: "rgba(255, 255, 255, 0.08)" },
          "&.Side-Menu": { color: "#bababa" },
          "&.Side-Menu.Sub-Menu.active": { color: "white" },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          "&.Side-Menu.active>svg": { color: "white", fill: "white" },
          paddingLeft: "2px",
          "&.Side-Menu>svg": { color: "#bababa", fill: "#bababa" },
        },
      },
    },
    MuiToolbar: { styleOverrides: { root: { [defaultDarkTheme.breakpoints.down("sm")]: { minHeight: "70px" } } } },
  },
});

export const light = createTheme({
  palette: {
    mode: "light",
    mediakits: {
      white: "#fff",
      "navy-blue": "#0B0927",
      "gradient-bg-main":
        "radial-gradient(ellipse at 200% 60%, rgba(244, 133, 23, 1) 0%, rgba(186, 7, 252, 1) 0%, rgba(252, 7, 111, 1) 0%, rgba(11, 10, 37, 1) 49%)",
      "gradient-bg-border-top": "linear-gradient(rgb(11, 9, 39, 1), rgba(11, 9, 39, 0) 100%)",
      "gradient-bg-border-bottom": "linear-gradient(rgba(11, 9, 39, 0), rgb(11, 9, 39, 1) 100%)",
    },
    vn: {
      blue: "#3DACFF",
      "blue-opacity": "3dacff40",
      "info-blue": "#73c0fa",
      "info-blue-opacity": "#73c0fa40",
      error: "#E43B3B",
      "error-opacity": "#e43b3b40",
      success: "#3FFCAE",
      "success-opacity": "#3FFCAE40",
      warning: "#FFA842",
      "warning-opacity": "#FFA84240",
      "black-grey": "#181818",
      "dark-grey": "#181A1C",
      grey: "#232425",
      "light-grey": "#787878",
      "paper-secondary-blue": "#e8f0fe",
      "paper-elevation-blue": "#eaf1fb",
      orange: "#F48517",
      maroon: "#FC076D",
      purple: "#BA07FC",
      "maroon-secondary": "#FC076F",
      "dark-blue": "#111F3B",
    },
    text: {
      primary: "rgba(0, 0, 0, 1)",
      secondary: "rgba(0, 0, 0, 0.87)",
      disabled: "rgba(0, 0, 0, 0.7)",
      ter: "rgba(0, 0, 0, 0.5)",
      pri: "rgba(0, 0, 0, 0.3)",
    },
    primary: {
      main: "#1192f3",
      light: "rgba(38, 156, 241, 0.2)",
    },
    error: {
      main: "#ec0e0e",
      light: "rgba(242, 39, 39, 0.2)",
    },
    success: {
      main: "#10a165",
      light: "rgba(50, 199, 138, 0.2)",
    },
    warning: {
      main: "#f3950b",
      light: "rgba(255, 167, 38, 0.2)",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.08)",
      selected: "rgba(0, 0, 0, 0.08)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
    },
    background: {
      default: "#f2f2f3",
      paper: "#f2f2f3",
    },
    card: {
      main: "#fff",
      light: "#f1f5f7",
      dark: "#e1e5e7",
    },
    divider: "rgba(0, 0, 0, 0.12)",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiAccordion: {
      defaultProps: { disableGutters: true },
      styleOverrides: { root: { [`&.${accordionClasses.expanded}::before`]: { opacity: 1 }, backgroundColor: "#fff" } },
    },
    MuiAccordionSummary: { defaultProps: { expandIcon: <ExpandMore /> } },
    MuiAccordionDetails: { styleOverrides: { root: { backgroundColor: "#fff" } } },
    MuiAppBar: {
      defaultProps: { position: "fixed" },
      styleOverrides: {
        root: {
          backgroundColor: "white",
          height: "70px",
          zIndex: defaultDarkTheme.zIndex.drawer + 1,
          backgroundImage: "none",
          boxShadow: "1px 4px 10px 0px #00000012",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            backgroundColor: "#3DACFF",
            borderRadius: "4px",
            color: "white",
            fontWeight: 600,
            height: "40px",
            padding: "8px 12px",
            "&:hover": { backgroundColor: "#349AE6" },
          },
        },
        {
          props: { variant: "red" },
          style: {
            backgroundColor: "#E43B3B",
            borderRadius: "4px",
            color: "white",
            fontWeight: 600,
            height: "40px",
            padding: "8px 12px",
            "&:hover": { backgroundColor: "#C83131" },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            borderRadius: "4px",
            color: "#3DACFF",
            fontWeight: 600,
            height: "40px",
            padding: "8px 12px",
            border: "1px solid #3DACFF",
            "&:hover": { backgroundColor: "#3DACFF1A", border: "1px solid #3DACFF" },
          },
        },
        {
          props: { variant: "text" },
          style: { color: "#3DACFF", fontWeight: 600, height: "40px", padding: "8px 12px", "&:hover": { backgroundColor: "#3DACFF1A" } },
        },
        {
          props: { variant: "grey" },
          style: {
            borderRadius: "4px",
            height: "40px",
            fontWeight: 600,
            padding: "8px 12px",
            color: "#8e8e8e",
            backgroundColor: "#d5dae3",
            border: "1.5px solid #c1c1c1",
            "&:hover": { backgroundColor: "#d0d9e8" },
          },
        },
      ],
      defaultProps: { variant: "contained" },
      styleOverrides: {
        root: {
          textTransform: "none",
          width: "fit-content",
        },
      },
    },
    MuiCheckbox: {
      defaultProps: { variant: "primary" },
      variants: [
        { props: { variant: "primary" }, style: { "& svg": { color: "#3DACFF" } } },
        { props: { variant: "secondary" }, style: { "& svg": { color: "#181818" } } },
        { props: { variant: "info" }, style: { "& svg": { color: "#73c0fa" } } },
        { props: { variant: "success" }, style: { "& svg": { color: "#3FFCAE" } } },
        { props: { variant: "warning" }, style: { "& svg": { color: "#FFA842" } } },
        { props: { variant: "error" }, style: { "& svg": { color: "#E43B3B" } } },
      ],
    },
    MuiFab: {
      variants: [
        {
          props: { color: "primary" },
          style: { backgroundColor: "#3DACFF", "&:hover": { backgroundColor: "#3DACFFCC" }, "& > svg": { color: "white" } },
        },
        {
          props: { color: "secondary" },
          style: { backgroundColor: "#181818", "&:hover": { backgroundColor: "#181818CC" }, "& > svg": { color: "white" } },
        },
        {
          props: { color: "info" },
          style: { backgroundColor: "#73c0fa", "&:hover": { backgroundColor: "#73C0FACC" }, "& > svg": { color: "white" } },
        },
        {
          props: { color: "success" },
          style: { backgroundColor: "#3FFCAE", "&:hover": { backgroundColor: "#3FFCAECC" }, "& > svg": { color: "white" } },
        },
        {
          props: { color: "warning" },
          style: { backgroundColor: "#FFA842", "&:hover": { backgroundColor: "#FFA842CC" }, "& > svg": { color: "white" } },
        },
        {
          props: { color: "error" },
          style: { backgroundColor: "#E43B3B", "&:hover": { backgroundColor: "#E43B3BCC" }, "& > svg": { color: "white" } },
        },
      ],
    },
    MuiRadio: {
      variants: [{ props: { color: "primary" }, style: { "& > span > svg": { color: "#3DACFF" } } }],
      defaultProps: { variant: "primary", color: "primary" },
    },
    MuiRating: {
      variants: [
        {
          props: { color: "primary" },
          style: {
            [`& label > span.${ratingClasses.iconFilled} > svg`]: { color: "#faaf00" },
            [`& span.${ratingClasses.decimal} label > span.${ratingClasses.iconFilled} > svg`]: { color: "#faaf00" },
          },
        },
      ],
    },
    MuiSlider: { variants: [{ props: { color: "primary" }, style: { color: "#3DACFF" } }] },
    MuiDialog: {
      defaultProps: { PaperProps: { variant: "primary-p1rem" } },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: "#3DACFF",
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: { disableShrink: true },
      styleOverrides: {
        svg: {
          color: "#3DACFF",
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: { notched: false, sx: { borderColor: "#5f6368", maxHeight: "40px" } },
      styleOverrides: {
        root: {
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: { border: "1px solid #43464a" },
          [`&.${autocompleteClasses.focused} .${outlinedInputClasses.notchedOutline}`]: { border: "1px solid #3DACFF" },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#3DACFF",
        },
        flexContainer: {
          [`& .${tabClasses.root}.${tabClasses.selected}`]: {
            color: "#3DACFF",
          },
          [`& .${tabClasses.root}`]: { color: "#7a8088" },
        },
      },
    },
    MuiTab: { styleOverrides: { root: { fontWeight: "bold", textTransform: "none", minHeight: "48px" } } },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
      styleOverrides: { root: { cursor: "pointer" } },
    },
    MuiInput: {
      variants: [
        { props: { variant: "filled" }, style: { backgroundColor: "white" } },
        { props: { variant: "hollow" }, style: {} },
      ],
      defaultProps: { disableUnderline: true },
      styleOverrides: {
        root: {
          height: "40px",
          border: "1px solid #bdbfc2",
          paddingLeft: "10px",
          borderRadius: "4px",
          "&:hover": { borderColor: "#43464a" },
          [`&.${inputClasses.focused}`]: { borderColor: "#3DACFF" },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          [`&.${switchClasses.checked}`]: {
            color: "#3DACFF",
          },
          color: "#e5e5e5",
        },
      },
    },
    MuiToggleButtonGroup: { styleOverrides: { root: { height: "40px" } } },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          [`&.${toggleButtonClasses.selected}>svg.MuiSvgIcon-root`]: { color: "#3DACFF" },
          [`&.${toggleButtonClasses.disabled}`]: { backgroundColor: "#e5e5e5" },
          [`&.${toggleButtonClasses.selected}, &.${toggleButtonClasses.selected}:hover`]: { backgroundColor: "#3DACFF33" },
          "&:hover": { backgroundColor: "#3DACFF14" },
        },
      },
    },
    MuiTooltip: {
      defaultProps: { arrow: true },
      styleOverrides: { tooltip: { backgroundColor: "black" }, arrow: { color: "black" } },
    },
    MuiSelect: {
      defaultProps: { IconComponent: KeyboardArrowDown, MenuProps: { PaperProps: { variant: "primary" } } },
      variants: [
        {
          props: { variant: "filled" },
          style: {
            "&::before, &::after": { display: "none" },
            [`& .${selectClasses.select}`]: { maxHeight: "40px" },
            borderRadius: "10px",
            backgroundColor: "#e8f0fe",
            maxHeight: "40px",
          },
        },
        {
          props: { "variant-extended": "background-grey", variant: "filled" },
          style: {
            "&::before, &::after": { display: "none" },
            [`& .${selectClasses.select}`]: { maxHeight: "40px" },
            borderRadius: "10px",
            backgroundColor: "#d3dae7",
            maxHeight: "40px",
          },
        },
        {
          props: { "variant-extended": "white", variant: "filled" },
          style: {
            "&::before, &::after": { display: "none" },
            [`& .${selectClasses.select}`]: { maxHeight: "40px" },
            borderRadius: "10px",
            backgroundColor: "#fff",
            maxHeight: "40px",
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            "&::before, &::after": { display: "none" },
            [`& .${selectClasses.select}`]: { maxHeight: "40px" },
            [`&.${selectClasses.disabled}:hover .${outlinedInputClasses.notchedOutline}`]: { borderColor: "#ffffff4d" },
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: { borderColor: "#3e3e3e" },
            [`& .${outlinedInputClasses.notchedOutline}`]: { borderColor: "#959aa3" },
            [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: { borderColor: "#3DACFF", borderWidth: "1px" },
            borderRadius: "10px",
            backgroundColor: "#fff",
            maxHeight: "40px",
          },
        },
        {
          props: { "variant-extended": "background-grey", variant: "outlined" },
          style: {
            "&::before, &::after": { display: "none" },
            [`& .${selectClasses.select}`]: { maxHeight: "40px" },
            [`&.${selectClasses.disabled}:hover .${outlinedInputClasses.notchedOutline}`]: { borderColor: "#ffffff4d" },
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: { borderColor: "#3e3e3e" },
            [`& .${outlinedInputClasses.notchedOutline}`]: { borderColor: "#959aa3" },
            [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: { borderColor: "#3DACFF", borderWidth: "1px" },
            borderRadius: "10px",
            backgroundColor: "#d3dae7",
            maxHeight: "40px",
          },
        },
      ],
      styleOverrides: {
        standard: {
          backgroundColor: "transparent",
          maxHeight: "40px",
          minWidth: "40px",
          padding: "8px 32px 8px 0px",
        },
        filled: {
          backgroundColor: "transparent",
          maxHeight: "40px",
          minWidth: "40px",
          borderRadius: "10px",
          padding: "8px 32px 8px 12px",
        },
        icon: { color: "#000" },
      },
    },
    MuiSvgIcon: {
      variants: [
        { props: { color: "primary" }, style: { color: "#000", "&>svg": { color: "#000", fill: "#000" } } },
        { props: { color: "secondary" }, style: { color: "#3DACFF", "&>svg": { color: "#3DACFF", fill: "#3DACFF" } } },
        { props: { color: "error" }, style: { color: "#E43B3B", "&>svg": { color: "#E43B3B", fill: "#E43B3B" } } },
        { props: { color: "success" }, style: { color: "#3FFCAE", "&>svg": { color: "#3FFCAE", fill: "#3FFCAE" } } },
        { props: { color: "warning" }, style: { color: "#FFA842", "&>svg": { color: "#FFA842", fill: "#FFA842" } } },
        { props: { color: "info" }, style: { color: "#73c0fa", "&>svg": { color: "#73c0fa", fill: "#73c0fa" } } },
      ],
      defaultProps: { color: "primary" },
      styleOverrides: {
        root: {
          "&.On-Dark": { color: "rgba(255,255,255,0.95)" },
          "&.Side-Menu.indicator.active": { color: "#3DACFF", width: "0.5rem" },
          "&.Side-Menu.indicator": { width: "0.5rem" },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          "&.Plan-Billing-Tab": { border: "1px solid #000" },
          "&.On-Dark": { color: "rgba(255,255,255,0.95)" },
          "&.Workspaces-Box": {
            border: "1px solid #0000001F",
            backgroundColor: "#f1f5f7",
            cursor: "pointer",
            padding: "14px",
            display: "flex",
            gap: "8px",
            justifyContent: "flex-start",
            borderRadius: "4px",
            flexGrow: 1,
            alignItems: "center",
          },
          "&.Main-Container": { backgroundColor: "#f2f2f3" },
          "&.Accounts-Box": {
            border: "1px solid #0000001F",
            backgroundColor: "#f1f5f7",
            width: "100%",
            height: "100%",
            borderRadius: "2rem",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          },
          "&.Workspace-Item": {
            color: "rgba(0,0,0,0.7)",
            "&:hover": { color: "rgba(0,0,0)" },
          },
          "&.Divider": {
            backgroundColor: "#000",
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: { variant: "vanilla" },
      variants: [
        {
          props: { variant: "outlined-primary" },
          style: { border: "1px solid #d2d2d2", backgroundImage: "none" },
        },
        {
          props: { variant: "outlined-primary-p0.5rem" },
          style: { border: "1px solid #d2d2d2", backgroundImage: "none", padding: "0.5rem" },
        },
        {
          props: { variant: "outlined-primary-p1rem" },
          style: { border: "1px solid #d2d2d2", backgroundImage: "none", padding: "1rem" },
        },
        {
          props: { variant: "outlined-primary-p1.5rem" },
          style: { border: "1px solid #d2d2d2", backgroundImage: "none", padding: "1.5rem" },
        },
        { props: { variant: "primary" }, style: { backgroundImage: "none", backgroundColor: "white" } },
        { props: { variant: "primary-p0.5rem" }, style: { backgroundImage: "none", padding: "0.5rem", backgroundColor: "white" } },
        { props: { variant: "primary-p1rem" }, style: { backgroundImage: "none", padding: "1rem", backgroundColor: "white" } },
        { props: { variant: "primary-p1.5rem" }, style: { backgroundImage: "none", padding: "1.5rem", backgroundColor: "white" } },
        {
          props: { variant: "outlined-secondary" },
          style: { backgroundColor: "#e8f0fe", border: "1px solid #d2d2d2", backgroundImage: "none" },
        },
        {
          props: { variant: "outlined-secondary-p0.5rem" },
          style: { backgroundColor: "#e8f0fe", border: "1px solid #d2d2d2", backgroundImage: "none", padding: "0.5rem" },
        },
        {
          props: { variant: "outlined-secondary-p1rem" },
          style: { backgroundColor: "#e8f0fe", border: "1px solid #d2d2d2", backgroundImage: "none", padding: "1rem" },
        },
        {
          props: { variant: "outlined-secondary-p1.5rem" },
          style: { backgroundColor: "#e8f0fe", border: "1px solid #d2d2d2", backgroundImage: "none", padding: "1.5rem" },
        },
        { props: { variant: "secondary" }, style: { backgroundColor: "#fff", backgroundImage: "none", boxShadow: "1px 2px 10px 0px #00000012" } },
        {
          props: { variant: "secondary-p0.5rem" },
          style: { backgroundColor: "#fff", backgroundImage: "none", padding: "0.5rem", boxShadow: "1px 2px 10px 0px #00000012" },
        },
        {
          props: { variant: "secondary-p1rem" },
          style: { backgroundColor: "#fff", backgroundImage: "none", padding: "1rem", boxShadow: "1px 2px 10px 0px #00000012" },
        },
        {
          props: { variant: "secondary-p1.5rem" },
          style: { backgroundColor: "#fff", backgroundImage: "none", padding: "1.5rem", boxShadow: "1px 2px 10px 0px #00000012" },
        },
        {
          props: { variant: "outlined-light" },
          style: { backgroundColor: "#f2f2f2", border: "1px solid #d2d2d2", backgroundImage: "none" },
        },
        {
          props: { variant: "outlined-light-p0.5rem" },
          style: { backgroundColor: "#f2f2f2", border: "1px solid #d2d2d2", backgroundImage: "none", padding: "0.5rem" },
        },
        {
          props: { variant: "outlined-light-p1rem" },
          style: { backgroundColor: "#f2f2f2", border: "1px solid #d2d2d2", backgroundImage: "none", padding: "1rem" },
        },
        {
          props: { variant: "outlined-light-p1.5rem" },
          style: { backgroundColor: "#f2f2f2", border: "1px solid #d2d2d2", backgroundImage: "none", padding: "1.5rem" },
        },
        { props: { variant: "light" }, style: { backgroundColor: "#d8dde6", backgroundImage: "none" } },
        { props: { variant: "light-p0.5rem" }, style: { backgroundColor: "#d8dde6", backgroundImage: "none", padding: "0.5rem" } },
        { props: { variant: "light-p1rem" }, style: { backgroundColor: "#d8dde6", backgroundImage: "none", padding: "1rem" } },
        { props: { variant: "light-p1.5rem" }, style: { backgroundColor: "#d8dde6", backgroundImage: "none", padding: "1.5rem" } },
        { props: { variant: "elevation" }, style: { backgroundColor: "#fff", backgroundImage: "none", boxShadow: "1px 2px 10px 0px #00000012" } },
        {
          props: { variant: "elevation-p0.5rem" },
          style: { backgroundColor: "#fff", backgroundImage: "none", padding: "0.5rem", boxShadow: "1px 2px 10px 0px #00000012" },
        },
        {
          props: { variant: "elevation-p1rem" },
          style: { backgroundColor: "#fff", backgroundImage: "none", padding: "1rem", boxShadow: "1px 2px 10px 0px #00000012" },
        },
        {
          props: { variant: "elevation-p1.5rem" },
          style: { backgroundColor: "#fff", backgroundImage: "none", padding: "1.5rem", boxShadow: "1px 2px 10px 0px #00000012" },
        },
        { props: { variant: "outlined" }, style: { backgroundColor: "#eaf1fb", backgroundImage: "none", border: "1px solid #d2d2d2" } },
        {
          props: { variant: "outlined-p0.5rem" },
          style: { backgroundColor: "#eaf1fb", backgroundImage: "none", border: "1px solid #d2d2d2", padding: "0.5rem" },
        },
        { props: { variant: "outlined-p1rem" }, style: { backgroundColor: "#eaf1fb", backgroundImage: "none", border: "1px solid #d2d2d2", padding: "1rem" } },
        {
          props: { variant: "outlined-p1.5rem" },
          style: { backgroundColor: "#eaf1fb", backgroundImage: "none", border: "1px solid #d2d2d2", padding: "1.5rem" },
        },
        { props: { variant: "vanilla" }, style: { backgroundImage: "none" } },
        { props: { variant: "vanilla-p0.5rem" }, style: { backgroundImage: "none", padding: "0.5rem" } },
        { props: { variant: "vanilla-p1rem" }, style: { backgroundImage: "none", padding: "1rem" } },
        { props: { variant: "vanilla-p1.5rem" }, style: { backgroundImage: "none", padding: "1.5rem" } },
        { props: { variant: "mediakits" }, style: { backgroundColor: "rgba(0, 0, 0, 0.6)", boxShadow: defaultDarkTheme.shadows[1] } },
        { props: { variant: "mediakits-opacity-white" }, style: { backgroundColor: "rgba(225, 225, 225, 0.05)", boxShadow: defaultDarkTheme.shadows[1] } },
        {
          props: { variant: "mediakits-p0.5rem" },
          style: { backgroundColor: "rgba(0, 0, 0, 0.6)", padding: "0.5rem", boxShadow: defaultDarkTheme.shadows[1] },
        },
        { props: { variant: "mediakits-p1rem" }, style: { backgroundColor: "rgba(0, 0, 0, 0.6)", padding: "1rem", boxShadow: defaultDarkTheme.shadows[1] } },
        {
          props: { variant: "mediakits-p1.5rem" },
          style: { backgroundColor: "rgba(0, 0, 0, 0.6)", padding: "1.5rem", boxShadow: defaultDarkTheme.shadows[1] },
        },
        { props: { variant: "mediakits-overall-bg" }, style: { backgroundColor: "rgba(0, 0, 0, 0.6)" } },
        { props: { variant: "mediakits-overall-bg-p0.5rem" }, style: { backgroundColor: "rgba(0, 0, 0, 0.6)", padding: "0.5rem" } },
        { props: { variant: "mediakits-overall-bg-p1rem" }, style: { backgroundColor: "rgba(0, 0, 0, 0.6)", padding: "1rem" } },
        { props: { variant: "mediakits-overall-bg-p1.5rem" }, style: { backgroundColor: "rgba(0, 0, 0, 0.6)", padding: "1.5rem" } },
        {
          props: { variant: "socialGradients-instagram" },
          style: { background: "linear-gradient(73.09deg, #C650BF 0%, #EE377D 50%, #ED7035 76.04%, #FC9B3D 100%)", boxShadow: defaultDarkTheme.shadows[1] },
        },
        {
          props: { variant: "socialGradients-instagram-p0.5rem" },
          style: {
            background: "linear-gradient(73.09deg, #C650BF 0%, #EE377D 50%, #ED7035 76.04%, #FC9B3D 100%)",
            boxShadow: defaultDarkTheme.shadows[1],
            padding: "0.5rem",
          },
        },
        {
          props: { variant: "socialGradients-instagram-p1rem" },
          style: {
            background: "linear-gradient(73.09deg, #C650BF 0%, #EE377D 50%, #ED7035 76.04%, #FC9B3D 100%)",
            boxShadow: defaultDarkTheme.shadows[1],
            padding: "1rem",
          },
        },
        {
          props: { variant: "socialGradients-instagram-p1.5rem" },
          style: {
            background: "linear-gradient(73.09deg, #C650BF 0%, #EE377D 50%, #ED7035 76.04%, #FC9B3D 100%)",
            boxShadow: defaultDarkTheme.shadows[1],
            padding: "1.5rem",
          },
        },
        {
          props: { variant: "socialGradients-linkedin" },
          style: { background: "linear-gradient(270deg, #095BC3 0%, #4F99F7 100%)", boxShadow: defaultDarkTheme.shadows[1] },
        },
        {
          props: { variant: "socialGradients-linkedin-p0.5rem" },
          style: {
            background: "linear-gradient(270deg, #095BC3 0%, #4F99F7 100%)",
            boxShadow: defaultDarkTheme.shadows[1],
            padding: "0.5rem",
          },
        },
        {
          props: { variant: "socialGradients-linkedin-p1rem" },
          style: {
            background: "linear-gradient(270deg, #095BC3 0%, #4F99F7 100%)",
            boxShadow: defaultDarkTheme.shadows[1],
            padding: "1rem",
          },
        },
        {
          props: { variant: "socialGradients-linkedin-p1.5rem" },
          style: {
            background: "linear-gradient(270deg, #095BC3 0%, #4F99F7 100%)",
            boxShadow: defaultDarkTheme.shadows[1],
            padding: "1.5rem",
          },
        },
        {
          props: { variant: "socialGradients-tiktok" },
          style: { background: "linear-gradient(256.56deg, #1ECACC 0%, #0285CD 100%)", boxShadow: defaultDarkTheme.shadows[1] },
        },
        {
          props: { variant: "socialGradients-tiktok-p0.5rem" },
          style: {
            background: "linear-gradient(256.56deg, #1ECACC 0%, #0285CD 100%)",
            boxShadow: defaultDarkTheme.shadows[1],
            padding: "0.5rem",
          },
        },
        {
          props: { variant: "socialGradients-tiktok-p1rem" },
          style: {
            background: "linear-gradient(256.56deg, #1ECACC 0%, #0285CD 100%)",
            boxShadow: defaultDarkTheme.shadows[1],
            padding: "1rem",
          },
        },
        {
          props: { variant: "socialGradients-tiktok-p1.5rem" },
          style: {
            background: "linear-gradient(256.56deg, #1ECACC 0%, #0285CD 100%)",
            boxShadow: defaultDarkTheme.shadows[1],
            padding: "1.5rem",
          },
        },
      ],
    },
    MuiMenu: {
      defaultProps: { PaperProps: { variant: "primary" } },
    },
    MuiChip: {
      variants: [
        { props: { variant: "filled-blue" }, style: { color: "#3DACFF", backgroundColor: "#3DACFF26" } },
        { props: { variant: "outlined-blue" }, style: { color: "#3DACFF", backgroundColor: "transparent", border: "1px solid #3DACFF" } },
        { props: { variant: "filled-red" }, style: { color: "#E43B3B", backgroundColor: "#E43B3B26" } },
        { props: { variant: "outlined-red" }, style: { color: "#E43B3B", backgroundColor: "transparent", border: "1px solid #E43B3B" } },
        { props: { variant: "filled-grey" }, style: { color: "#787878", backgroundColor: "#78787826" } },
        { props: { variant: "outlined-grey" }, style: { color: "#787878", backgroundColor: "transparent", border: "1px solid #787878" } },
        { props: { variant: "filled-orange" }, style: { color: "#FFA842", backgroundColor: "#FFA84226" } },
        { props: { variant: "outlined-orange" }, style: { color: "#FFA842", backgroundColor: "transparent", border: "1px solid #FFA842" } },
        { props: { variant: "filled-green" }, style: { color: "#3FFCAE", backgroundColor: "#3FFCAE26" } },
        { props: { variant: "outlined-green" }, style: { color: "#3FFCAE", backgroundColor: "transparent", border: "1px solid #3FFCAE" } },
        { props: { variant: "filled-purple" }, style: { color: "#CA97F2", backgroundColor: "##CA97F226" } },
        { props: { variant: "outlined-purple" }, style: { color: "#CA97F2", backgroundColor: "transparent", border: "1px solid #CA97F2" } },
      ],
    },
    MuiList: {
      variants: [
        {
          props: { variant: "striped" },
          style: {
            [`& .${listItemClasses.root}`]: { "&:nth-of-type(even)": { backgroundColor: "#fff" }, "&:nth-of-type(odd)": { backgroundColor: "#eaf1fb" } },
          },
        },
      ],
    },
    MuiTextField: {
      defaultProps: {
        InputProps: { variant: "hollow" },
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiAutocomplete: {
      variants: [
        {
          props: { variant: "filled" },
          style: {
            [`& .${autocompleteClasses.inputRoot}`]: {
              borderRadius: "10px",
              backgroundColor: "#e8f0fe",
              [`& .${outlinedInputClasses.notchedOutline}`]: { border: "none" },
            },
            [`&.Mui-focused .${autocompleteClasses.inputRoot}`]: {
              backgroundColor: "rgba(0, 0, 0, 0.09)",
            },
            [`&:hover .${autocompleteClasses.inputRoot}`]: {
              backgroundColor: "rgba(0, 0, 0, 0.09)",
            },
          },
        },
        {
          props: { "variant-extended": "background-grey", variant: "filled" },
          style: {
            [`& .${autocompleteClasses.inputRoot}`]: {
              borderRadius: "10px",
              backgroundColor: "#d3dae7",
              [`& .${outlinedInputClasses.notchedOutline}`]: { border: "none" },
            },
            [`&.Mui-focused .${autocompleteClasses.inputRoot}`]: {
              backgroundColor: "rgba(0, 0, 0, 0.09)",
            },
            [`&:hover .${autocompleteClasses.inputRoot}`]: {
              backgroundColor: "rgba(0, 0, 0, 0.09)",
            },
          },
        },
        {
          props: { "variant-extended": "white", variant: "filled" },
          style: {
            [`& .${autocompleteClasses.inputRoot}`]: {
              borderRadius: "10px",
              backgroundColor: "#fff",
              [`& .${outlinedInputClasses.notchedOutline}`]: { border: "none" },
            },
            [`&.Mui-focused .${autocompleteClasses.inputRoot}`]: {
              backgroundColor: "rgba(0, 0, 0, 0.09)",
            },
            [`&:hover .${autocompleteClasses.inputRoot}`]: {
              backgroundColor: "rgba(0, 0, 0, 0.09)",
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            [`& .${autocompleteClasses.inputRoot}`]: {
              borderRadius: "10px",
              backgroundColor: "#e8f0fe",
              [`& .${outlinedInputClasses.notchedOutline}`]: { border: "1px solid #959aa3" },
              [`&:hover .${outlinedInputClasses.notchedOutline}`]: { borderColor: "#3e3e3e" },
              [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: { borderColor: "#3DACFF" },
            },
          },
        },
        {
          props: { "variant-extended": "background-grey", variant: "outlined" },
          style: {
            [`& .${autocompleteClasses.inputRoot}`]: {
              borderRadius: "10px",
              backgroundColor: "#d3dae7",
              [`& .${outlinedInputClasses.notchedOutline}`]: { border: "1px solid #959aa3" },
              [`&:hover .${outlinedInputClasses.notchedOutline}`]: { borderColor: "#3e3e3e" },
              [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: { borderColor: "#3DACFF" },
            },
          },
        },
      ],
      defaultProps: {
        popupIcon: <KeyboardArrowDown />,
        PaperComponent: (props) => <Paper {...props} variant="primary" sx={{ boxShadow: 3 }} />,
      },
      styleOverrides: { root: { [`& .${outlinedInputClasses.root} .${autocompleteClasses.input}`]: { padding: 0 } } },
    },
    MuiDataGrid: {
      defaultProps: { disableSelectionOnClick: true, componentsProps: { baseButton: { variant: "text" }, baseCheckbox: { variant: "default" } } },
      styleOverrides: {
        root: { border: "1px solid #c2c2c2", boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)" },
        panel: { [`& .${gridClasses.paper}.${paperClasses.root}`]: { backgroundImage: "none", boxShadow: defaultDarkTheme.shadows[3] } },
        panelFooter: { "&>button>span>svg": { color: "inherit" } },
        footerContainer: {
          borderTop: "1px solid #c2c2c2",
          backgroundImage: "none",
          backgroundColor: "#fff",
          color: "#5f6368",
          [`& > td`]: { borderBottom: "none" },
        },
        panelHeader: { "& label": { position: "block", transform: "none", fontSize: "12px" }, "& label.Mui-focused": { color: "#3DACFF" } },
        columnHeaders: { backgroundColor: "#f1f5f7", borderBottom: "1px solid #c2c2c2", color: "#5f6368" },
        columnHeader: { "&:focus-within": { outlineColor: "#3DACFF" }, "&:focus": { outlineColor: "#3DACFF" } },
        //columnSeparator: { display: "none" },
        iconSeparator: { color: "#bdbdbd" },
        pinnedColumnHeaders: { backgroundImage: "none", backgroundColor: "#f1f5f7" },
        "pinnedColumnHeaders--left": { boxShadow: "14px 0px 16px rgba(0, 0, 0, 0.25)" },
        "pinnedColumnHeaders--right": { boxShadow: "-14px 0px 16px rgba(0, 0, 0, 0.25)" },
        pinnedColumns: { backgroundImage: "none" },
        "pinnedColumns--left": { boxShadow: "14px 0px 16px rgba(0, 0, 0, 0.25)" },
        "pinnedColumns--right": { boxShadow: "-14px 0px 16px rgba(0, 0, 0, 0.25)" },
        filterFormValueInput: { "& > div > label.Mui-focused": { color: "#3DACFF" } },
        filterFormOperatorInput: { "& > label.Mui-focused": { color: "#3DACFF" } },
        filterFormColumnInput: { "& > label.Mui-focused": { color: "#3DACFF" } },
        filterFormDeleteIcon: { "& > button > svg": { color: "#E43B3B" } },
        filterForm: { gap: "1rem" },
        menu: { [`&>.${paperClasses.root}`]: { backgroundColor: "white" } },
        row: {
          "&:not(:last-of-type)": { borderBottom: "1px solid #c2c2c2" },
          "&:nth-of-type(odd)": { backgroundColor: "#fff" },
          "&:nth-of-type(even)": { backgroundColor: "#f1f5f7" },
          "&:hover": { backgroundColor: "#0000001F" },
          cursor: "pointer",
          color: "#5f6368",
        },
        cell: { borderBottom: "none", "&:focus-within": { outlineColor: "#3DACFF" }, "&:focus": { outlineColor: "#3DACFF" } },
        toolbarContainer: {
          backgroundImage: "none",
          backgroundColor: "#ffffff",
          gap: "5px",
          paddingBottom: "5px",
          [`& .${buttonClasses.root}`]: { backgroundColor: "transparent", color: "#5f6368", boxShadow: "none", "&:hover": { backgroundColor: "#3dacff26" } },
        },
      },
    },
    MuiTablePagination: { styleOverrides: { root: { color: "#5f6368" } } },
    MuiTableContainer: { styleOverrides: { root: { backgroundColor: "#fff" } } },
    MuiTableCell: { styleOverrides: { root: { border: "none", color: "#5f6368" } } },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#f1f5f7",
          color: "#5f6368",
          borderBottom: "1px solid #c2c2c2",
          [`&>tr.${tableRowClasses.root}.${tableRowClasses.head}`]: { border: "none !important", backgroundColor: "#f1f5f7" },
          [`&>tr.${tableRowClasses.root}.${tableRowClasses.head}:hover`]: { backgroundColor: "#f1f5f7" },
          [`&>tr.${tableRowClasses.root}>th.${tableCellClasses.root}`]: { border: "none !important", fontWeight: 600 },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          border: 0,
          [`&:nth-of-type(odd)`]: { backgroundColor: "#fff" },
          [`&:nth-of-type(even)`]: { backgroundColor: "#f1f5f7" },
          [`&:hover`]: { backgroundColor: "#0000001F" },
          cursor: "pointer",
          color: "#5f6368",
        },
      },
    },
    MuiTypography: {
      variants: [
        { props: { variant: "h1" }, style: { color: "black", fontSize: "56px", lineHeight: "91.43%", letterSpacing: "-1.5px" } },
        { props: { variant: "h2" }, style: { color: "black", fontSize: "48px", lineHeight: "99.56%", letterSpacing: "-0.5px" } },
        { props: { variant: "h3" }, style: { color: "black", fontSize: "40px", lineHeight: "102.4%" } },
        { props: { variant: "h4" }, style: { color: "black", fontSize: "32px", lineHeight: "106.7%" } },
        { props: { variant: "h5" }, style: { color: "black", fontSize: "28px", lineHeight: "109.71%", letterSpacing: "0.18px" } },
        { props: { variant: "h6" }, style: { color: "black", fontSize: "24px", lineHeight: "85.33%", letterSpacing: "0.15px" } },
        { props: { variant: "h7" }, style: { color: "black", fontSize: "18px", lineHeight: "113.78%", letterSpacing: "0.15px" } },
        { props: { variant: "body1" }, style: { color: "black", fontSize: "16px", lineHeight: "128%", letterSpacing: "0.5px" } },
        { props: { variant: "body2" }, style: { color: "black", fontSize: "14px", lineHeight: "121.9%", letterSpacing: "0.25px" } },
        { props: { variant: "caption" }, style: { color: "black", fontSize: "12px", lineHeight: "113.78%", letterSpacing: "0.4px" } },
        { props: { variant: "opacity10-8px" }, style: { color: "rgba(0,0,0,0.1)", fontSize: "8px" } },
        { props: { variant: "opacity10-12px" }, style: { color: "rgba(0,0,0,0.1)", fontSize: "12px" } },
        { props: { variant: "opacity10-14px" }, style: { color: "rgba(0,0,0,0.1)", fontSize: "14px" } },
        { props: { variant: "opacity10" }, style: { color: "rgba(0,0,0,0.1)" } },
        { props: { variant: "opacity10-18px" }, style: { color: "rgba(0,0,0,0.1)", fontSize: "18px" } },
        { props: { variant: "opacity10-20px" }, style: { color: "rgba(0,0,0,0.1)", fontSize: "20px" } },
        { props: { variant: "opacity10-24px" }, style: { color: "rgba(0,0,0,0.1)", fontSize: "24px" } },
        { props: { variant: "opacity10-28px" }, style: { color: "rgba(0,0,0,0.1)", fontSize: "28px" } },
        { props: { variant: "opacity10-32px" }, style: { color: "rgba(0,0,0,0.1)", fontSize: "32px" } },
        { props: { variant: "opacity10-36px" }, style: { color: "rgba(0,0,0,0.1)", fontSize: "36px" } },
        { props: { variant: "opacity10-40px" }, style: { color: "rgba(0,0,0,0.1)", fontSize: "40px" } },
        { props: { variant: "opacity10-44px" }, style: { color: "rgba(0,0,0,0.1)", fontSize: "44px" } },
        { props: { variant: "opacity10-48px" }, style: { color: "rgba(0,0,0,0.1)", fontSize: "48px" } },
        { props: { variant: "opacity10-52px" }, style: { color: "rgba(0,0,0,0.1)", fontSize: "52px" } },
        { props: { variant: "opacity10-56px" }, style: { color: "rgba(0,0,0,0.1)", fontSize: "56px" } },
        { props: { variant: "opacity10-60px" }, style: { color: "rgba(0,0,0,0.1)", fontSize: "60px" } },
        { props: { variant: "opacity10-64px" }, style: { color: "rgba(0,0,0,0.1)", fontSize: "64px" } },
        { props: { variant: "opacity20-8px" }, style: { color: "rgba(0,0,0,0.2)", fontSize: "8px" } },
        { props: { variant: "opacity20-12px" }, style: { color: "rgba(0,0,0,0.2)", fontSize: "12px" } },
        { props: { variant: "opacity20-14px" }, style: { color: "rgba(0,0,0,0.2)", fontSize: "14px" } },
        { props: { variant: "opacity20" }, style: { color: "rgba(0,0,0,0.2)" } },
        { props: { variant: "opacity20-18px" }, style: { color: "rgba(0,0,0,0.2)", fontSize: "18px" } },
        { props: { variant: "opacity20-20px" }, style: { color: "rgba(0,0,0,0.2)", fontSize: "20px" } },
        { props: { variant: "opacity20-24px" }, style: { color: "rgba(0,0,0,0.2)", fontSize: "24px" } },
        { props: { variant: "opacity20-28px" }, style: { color: "rgba(0,0,0,0.2)", fontSize: "28px" } },
        { props: { variant: "opacity20-32px" }, style: { color: "rgba(0,0,0,0.2)", fontSize: "32px" } },
        { props: { variant: "opacity20-36px" }, style: { color: "rgba(0,0,0,0.2)", fontSize: "36px" } },
        { props: { variant: "opacity20-40px" }, style: { color: "rgba(0,0,0,0.2)", fontSize: "40px" } },
        { props: { variant: "opacity20-44px" }, style: { color: "rgba(0,0,0,0.2)", fontSize: "44px" } },
        { props: { variant: "opacity20-48px" }, style: { color: "rgba(0,0,0,0.2)", fontSize: "48px" } },
        { props: { variant: "opacity20-52px" }, style: { color: "rgba(0,0,0,0.2)", fontSize: "52px" } },
        { props: { variant: "opacity20-56px" }, style: { color: "rgba(0,0,0,0.2)", fontSize: "56px" } },
        { props: { variant: "opacity20-60px" }, style: { color: "rgba(0,0,0,0.2)", fontSize: "60px" } },
        { props: { variant: "opacity20-64px" }, style: { color: "rgba(0,0,0,0.2)", fontSize: "64px" } },
        { props: { variant: "opacity30-8px" }, style: { color: "rgba(0,0,0,0.3)", fontSize: "8px" } },
        { props: { variant: "opacity30-12px" }, style: { color: "rgba(0,0,0,0.3)", fontSize: "12px" } },
        { props: { variant: "opacity30-14px" }, style: { color: "rgba(0,0,0,0.3)", fontSize: "14px" } },
        { props: { variant: "opacity30" }, style: { color: "rgba(0,0,0,0.3)" } },
        { props: { variant: "opacity30-18px" }, style: { color: "rgba(0,0,0,0.3)", fontSize: "18px" } },
        { props: { variant: "opacity30-20px" }, style: { color: "rgba(0,0,0,0.3)", fontSize: "20px" } },
        { props: { variant: "opacity30-24px" }, style: { color: "rgba(0,0,0,0.3)", fontSize: "24px" } },
        { props: { variant: "opacity30-28px" }, style: { color: "rgba(0,0,0,0.3)", fontSize: "28px" } },
        { props: { variant: "opacity30-32px" }, style: { color: "rgba(0,0,0,0.3)", fontSize: "32px" } },
        { props: { variant: "opacity30-36px" }, style: { color: "rgba(0,0,0,0.3)", fontSize: "36px" } },
        { props: { variant: "opacity30-40px" }, style: { color: "rgba(0,0,0,0.3)", fontSize: "40px" } },
        { props: { variant: "opacity30-44px" }, style: { color: "rgba(0,0,0,0.3)", fontSize: "44px" } },
        { props: { variant: "opacity30-48px" }, style: { color: "rgba(0,0,0,0.3)", fontSize: "48px" } },
        { props: { variant: "opacity30-52px" }, style: { color: "rgba(0,0,0,0.3)", fontSize: "52px" } },
        { props: { variant: "opacity30-56px" }, style: { color: "rgba(0,0,0,0.3)", fontSize: "56px" } },
        { props: { variant: "opacity30-60px" }, style: { color: "rgba(0,0,0,0.3)", fontSize: "60px" } },
        { props: { variant: "opacity30-64px" }, style: { color: "rgba(0,0,0,0.3)", fontSize: "64px" } },
        { props: { variant: "opacity50-8px" }, style: { color: "rgba(0,0,0,0.5)", fontSize: "8px" } },
        { props: { variant: "opacity50-12px" }, style: { color: "rgba(0,0,0,0.5)", fontSize: "12px" } },
        { props: { variant: "opacity50-14px" }, style: { color: "rgba(0,0,0,0.5)", fontSize: "14px" } },
        { props: { variant: "opacity50" }, style: { color: "rgba(0,0,0,0.5)" } },
        { props: { variant: "opacity50-18px" }, style: { color: "rgba(0,0,0,0.5)", fontSize: "18px" } },
        { props: { variant: "opacity50-20px" }, style: { color: "rgba(0,0,0,0.5)", fontSize: "20px" } },
        { props: { variant: "opacity50-24px" }, style: { color: "rgba(0,0,0,0.5)", fontSize: "24px" } },
        { props: { variant: "opacity50-28px" }, style: { color: "rgba(0,0,0,0.5)", fontSize: "28px" } },
        { props: { variant: "opacity50-32px" }, style: { color: "rgba(0,0,0,0.5)", fontSize: "32px" } },
        { props: { variant: "opacity50-36px" }, style: { color: "rgba(0,0,0,0.5)", fontSize: "36px" } },
        { props: { variant: "opacity50-40px" }, style: { color: "rgba(0,0,0,0.5)", fontSize: "40px" } },
        { props: { variant: "opacity50-44px" }, style: { color: "rgba(0,0,0,0.5)", fontSize: "44px" } },
        { props: { variant: "opacity50-48px" }, style: { color: "rgba(0,0,0,0.5)", fontSize: "48px" } },
        { props: { variant: "opacity50-52px" }, style: { color: "rgba(0,0,0,0.5)", fontSize: "52px" } },
        { props: { variant: "opacity50-56px" }, style: { color: "rgba(0,0,0,0.5)", fontSize: "56px" } },
        { props: { variant: "opacity50-60px" }, style: { color: "rgba(0,0,0,0.5)", fontSize: "60px" } },
        { props: { variant: "opacity50-64px" }, style: { color: "rgba(0,0,0,0.5)", fontSize: "64px" } },
        { props: { variant: "opacity70-8px" }, style: { color: "rgba(0,0,0,0.7)", fontSize: "8px" } },
        { props: { variant: "opacity70-12px" }, style: { color: "rgba(0,0,0,0.7)", fontSize: "12px" } },
        { props: { variant: "opacity70-14px" }, style: { color: "rgba(0,0,0,0.7)", fontSize: "14px" } },
        { props: { variant: "opacity70" }, style: { color: "rgba(0,0,0,0.7)" } },
        { props: { variant: "opacity70-18px" }, style: { color: "rgba(0,0,0,0.7)", fontSize: "18px" } },
        { props: { variant: "opacity70-20px" }, style: { color: "rgba(0,0,0,0.7)", fontSize: "20px" } },
        { props: { variant: "opacity70-24px" }, style: { color: "rgba(0,0,0,0.7)", fontSize: "24px" } },
        { props: { variant: "opacity70-28px" }, style: { color: "rgba(0,0,0,0.7)", fontSize: "28px" } },
        { props: { variant: "opacity70-32px" }, style: { color: "rgba(0,0,0,0.7)", fontSize: "32px" } },
        { props: { variant: "opacity70-36px" }, style: { color: "rgba(0,0,0,0.7)", fontSize: "36px" } },
        { props: { variant: "opacity70-40px" }, style: { color: "rgba(0,0,0,0.7)", fontSize: "40px" } },
        { props: { variant: "opacity70-44px" }, style: { color: "rgba(0,0,0,0.7)", fontSize: "44px" } },
        { props: { variant: "opacity70-48px" }, style: { color: "rgba(0,0,0,0.7)", fontSize: "48px" } },
        { props: { variant: "opacity70-52px" }, style: { color: "rgba(0,0,0,0.7)", fontSize: "52px" } },
        { props: { variant: "opacity70-56px" }, style: { color: "rgba(0,0,0,0.7)", fontSize: "56px" } },
        { props: { variant: "opacity70-60px" }, style: { color: "rgba(0,0,0,0.7)", fontSize: "60px" } },
        { props: { variant: "opacity70-64px" }, style: { color: "rgba(0,0,0,0.7)", fontSize: "64px" } },
        { props: { variant: "opacity95-8px" }, style: { color: "rgba(0,0,0,0.95)", fontSize: "8px" } },
        { props: { variant: "opacity95-12px" }, style: { color: "rgba(0,0,0,0.95)", fontSize: "12px" } },
        { props: { variant: "opacity95-14px" }, style: { color: "rgba(0,0,0,0.95)", fontSize: "14px" } },
        { props: { variant: "opacity95" }, style: { color: "rgba(0,0,0,0.95)" } },
        { props: { variant: "opacity95-18px" }, style: { color: "rgba(0,0,0,0.95)", fontSize: "18px" } },
        { props: { variant: "opacity95-20px" }, style: { color: "rgba(0,0,0,0.95)", fontSize: "20px" } },
        { props: { variant: "opacity95-24px" }, style: { color: "rgba(0,0,0,0.95)", fontSize: "24px" } },
        { props: { variant: "opacity95-28px" }, style: { color: "rgba(0,0,0,0.95)", fontSize: "28px" } },
        { props: { variant: "opacity95-32px" }, style: { color: "rgba(0,0,0,0.95)", fontSize: "32px" } },
        { props: { variant: "opacity95-36px" }, style: { color: "rgba(0,0,0,0.95)", fontSize: "36px" } },
        { props: { variant: "opacity95-40px" }, style: { color: "rgba(0,0,0,0.95)", fontSize: "40px" } },
        { props: { variant: "opacity95-44px" }, style: { color: "rgba(0,0,0,0.95)", fontSize: "44px" } },
        { props: { variant: "opacity95-48px" }, style: { color: "rgba(0,0,0,0.95)", fontSize: "48px" } },
        { props: { variant: "opacity95-52px" }, style: { color: "rgba(0,0,0,0.95)", fontSize: "52px" } },
        { props: { variant: "opacity95-56px" }, style: { color: "rgba(0,0,0,0.95)", fontSize: "56px" } },
        { props: { variant: "opacity95-60px" }, style: { color: "rgba(0,0,0,0.95)", fontSize: "60px" } },
        { props: { variant: "opacity95-64px" }, style: { color: "rgba(0,0,0,0.95)", fontSize: "64px" } },
        { props: { variant: "error" }, style: { color: "#E43B3B" } },
      ],
      styleOverrides: {
        root: {
          letterSpacing: "0.25px",
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        InputAdornmentProps: { sx: { "& button": { padding: "5px" } } },
        PaperProps: {
          variant: "primary",
          sx: { boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)" },
        },
      },
    },
    MuiDesktopTimePicker: {
      defaultProps: {
        InputAdornmentProps: { sx: { "& button": { padding: "5px" } } },
        PaperProps: {
          variant: "primary",
          sx: { boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)" },
        },
      },
    },
    MuiClock: {
      styleOverrides: {
        amButton: { backgroundColor: "#3DACFF", "&:hover": { backgroundColor: "#3DACFFCC" } },
        pmButton: { backgroundColor: "#3DACFF", "&:hover": { backgroundColor: "#3DACFFCC" } },
        pin: { backgroundColor: "#3DACFF" },
      },
    },
    MuiClockPointer: { styleOverrides: { root: { backgroundColor: "#3DACFF" }, thumb: { backgroundColor: "#3DACFF", borderColor: "#3DACFF" } } },
    MuiPickersPopper: {
      styleOverrides: { root: { [`& .${pickersPopperClasses.paper}`]: { backgroundImage: "none" } } },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.Side-Menu>svg": { color: "#5f6368" },
          "&.Side-Menu.expanded>svg": { color: "#041e49" },
          "& .sun-and-moon > :is(.moon, .sun)": { fill: "#000" },
          "& .sun-and-moon > .sun-beams": { stroke: "#000" },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Side-Menu": {
            borderRadius: "2rem",
            display: "flex",
            padding: "0.5rem 1rem",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          },
          "&.Side-Menu:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
          "&.Side-Menu.expanded": { color: "#001d35", backgroundColor: "rgba(0,0,0,0.1)" },
          "&.Side-Menu.Sub-Menu": { borderRadius: "2px" },
          "&.Side-Menu.Sub-Menu.expanded": { color: "#001d35", backgroundColor: "transparent" },
          "&.Side-Menu.Sub-Menu.expanded:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          "&.Side-Menu.active": { color: "#041e49" },
          "&.SideMenu:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
          "&.Side-Menu": { color: "#5f6368" },
          "&.Side-Menu.Sub-Menu.active": { color: "#041e49" },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          "&.Side-Menu.active>svg": { color: "#000", fill: "#000" },
          paddingLeft: "2px",
          "&.Side-Menu>svg": { color: "#5f6368", fill: "#5f6368" },
        },
      },
    },
    MuiToolbar: { styleOverrides: { root: { [defaultDarkTheme.breakpoints.down("sm")]: { minHeight: "70px" } } } },
  },
});
