import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import AllRoutes from "./Routes";
import Dark from "./Theme/Dark";

function App() {
  return (
    <ThemeProvider theme={Dark}>
      <CssBaseline />
      <AllRoutes />
    </ThemeProvider>
  );
}

export default App;
