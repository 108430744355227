import { Scaffold } from "Scaffold/src/main";
import PrivateRoutes from "./private";

const AllRoutes = () => {
  return (
    <>
      <Scaffold>
        <PrivateRoutes />
      </Scaffold>
    </>
  );
};

export default AllRoutes;
