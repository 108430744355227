import { makeStyles } from "@mui/styles";
import { REACT_APP_DOMAIN_ID } from "../../utilities/constants";

export const useStyles = makeStyles((theme: any) => ({
  contentW: {
    height: REACT_APP_DOMAIN_ID === "7" ? "100vh" : "calc(100vh - 70px)",
    maxHeight: REACT_APP_DOMAIN_ID === "7" ? "100vh" : "calc(100vh - 70px)",
    marginTop: REACT_APP_DOMAIN_ID === "7" ? 0 : "70px",
    position: "relative",
    overflow: "auto",
    overflowX: "hidden",
    justifyContent: REACT_APP_DOMAIN_ID === "7" ? "center" : "flex-start"
  },
}));
