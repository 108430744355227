/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Grid, Typography, Avatar } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InboxInfoDrawer from "../InboxInfoDrawer";

const ChatHeader = (props: any) => {
  const [showInboxInfoDrawer, setShowInboxInfoDrawer] = useState<boolean>(false);

  return (
    <Grid container spacing={2} alignItems="center" display="flex" justifyContent="space-between">
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Avatar alt="profilePic" src={props?.chatRoomData?.profileUrl} sx={{ width: 40, height: 40 }} />
        <Grid item pl={1}>
          <Typography variant="opacity70-14px" fontWeight={600}>
            {props?.chatRoomData?.name}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <MoreVertIcon
          sx={{ color: "opacity70", fontSize: "25px" }}
          onClick={() => {
            setShowInboxInfoDrawer(true);
          }}
        />
      </Grid>

      <InboxInfoDrawer
        open={showInboxInfoDrawer}
        onClose={() => {
          setShowInboxInfoDrawer(false);
        }}
        conversationId={props?.chatRoomData?.id}
      />
    </Grid>
  );
};

export default ChatHeader;
