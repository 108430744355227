import { useLazyQuery, useMutation } from "@apollo/client";
import { Avatar, Box, Grid, Typography, Button, Modal, Input, FormLabel } from "@mui/material";
import { CREATE_CONVERSATION } from "../../../../apollo/mutations";
import { GET_ALL_USERS } from "../../../../apollo/queries";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import CheckIcon from "@mui/icons-material/Check";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { subscriptionClient, userClient } from "../../../../apollo/client";
import axios from "axios";
import { getAccessToken } from "../../../../main";

type accountType = {
  user_id: number;
  user_name: string;
  user_profile_pic_url: string;
};
interface Props {
  searchContact: string;
  setsearchContact: React.Dispatch<React.SetStateAction<string>>;
  handleChange: (e: any) => void;
  handleDrawerClose: () => void;
  handleChat: (e: any) => void;
  isGroupSelection: boolean;
  groupSelectedArr: accountType[];
  setGroupSelectedArr: React.Dispatch<React.SetStateAction<accountType[]>>;
}

const ContactList: React.FC<Props> = (props) => {
  const [contactListItems, setContactListItems] = useState<accountType[]>([]);
  const [page, setPage] = useState<number>(1);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [groupName, setGroupName] = useState<string>("");
  const [groupPicture, setGroupPicture] = useState<string>("");
  const CHAT_IMAGE_UPLOAD_API = "https://api.upload.dev.vnplatform.com/user/upload/images";

  const [getAllUsersQuery, { data: allUsersData, loading: allUsersLoading, error: allUsersError }] = useLazyQuery(GET_ALL_USERS, {
    client: userClient,
    variables: {
      rows: 25,
      page: page,
      sort: {
        key: "first_name",
        sort: "asc",
      },
      search: props.searchContact ? props.searchContact : null,
      filter: null,
    },
    fetchPolicy: "network-only",
  });

  const [createConversation, { data: createConversationData, loading: createConversationLoading, error: createConversationError }] = useMutation(
    CREATE_CONVERSATION,
    {
      client: subscriptionClient,
    }
  );
  useEffect(() => {
    if (createConversationData && !createConversationLoading) {
      const data = createConversationData?.createConversation?.data?.newConv;
      let item = {
        id: data?._id,
        conversationtype: data?.type,
        name: data?.name,
        profileUrl: data?.profile_url,
      };
      props.handleDrawerClose();
      props.handleChat(item);
    }// eslint-disable-next-line
  }, [createConversationData, createConversationLoading, createConversationError]);

  //call this function with user to create new conversation
  const createNewConversation = (account: any, type: string) => {
    createConversation({
      variables: {
        type: type,
        otherMembers: account,
        name: groupName ? groupName : null,
        conversationPicUrl: groupPicture ? groupPicture : null,
      },
    });
  };

  const onGroupSelection = (item: accountType) => {
    let tempArr = [...props.groupSelectedArr];
    let indexTemp: number = tempArr.findIndex((e: any) => e?.user_id === item?.user_id);
    if (indexTemp > -1) {
      tempArr.splice(indexTemp, 1);
    } else {
      tempArr.push(item);
    }
    props.setGroupSelectedArr(tempArr);
  };

  const handleChatSelection = (item: any, type: string) => {
    if (!props?.isGroupSelection && type === "CHAT") {
      createNewConversation(item, type);
    } else if (props?.isGroupSelection && type === "GROUP" && groupName) {
      createNewConversation(item, type);
    } else {
      onGroupSelection(item);
    }
  };

  useEffect(() => {
    //call the api when page is updated
    fetchMore();
    // eslint-disable-next-line
  }, [page, props.searchContact]);

  useEffect(() => {
    if (allUsersData && !allUsersLoading) {
      let userData = allUsersData?.getAllUsersData?.data?.users;
      if (props.searchContact) {
        setContactListItems([]);
        if (userData.length > 0) {
          userData.map((user: any) => {
            let userItem: accountType = {
              user_id: user.id,
              user_name: user.first_name + " " + user.last_name,
              user_profile_pic_url: user.profile_pic_url,
            };
            return setContactListItems((prevState) => {
              return [...prevState, userItem];
            });
          });
        }
      } else {
        if (userData.length > 0) {
          userData.map((user: any) => {
            let userItem: accountType = {
              user_id: user.id,
              user_name: user.first_name + " " + user.last_name,
              user_profile_pic_url: user.profile_pic_url,
            };
            return setContactListItems((prevState) => {
              return [...prevState, userItem];
            });
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [allUsersData, allUsersLoading, allUsersError]);

  const fetchMore = () => {
    getAllUsersQuery({
      variables: {
        page: props.searchContact ? 1 : page,
        rows: 25,
        sort: {
          key: "first_name",
          sort: "asc",
        },
        filter: null,
        search: props.searchContact ? props.searchContact : null,
      },
    });
  };
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files?.length) {
      const selectedImage = event.target.files[0];
      userImageUpload(selectedImage);
    }
  };

  const userImageUpload = async (img: File) => {
    const formData = new FormData();
    formData.append("file", img);
    const token: any = getAccessToken();
    try {
      if (token) {
        const { data } = await axios.post(CHAT_IMAGE_UPLOAD_API, formData, {
          headers: {
            Authorization: token,
          },
        });
        if (data?.data?.url) setGroupPicture(data?.data?.url);
      }
    } catch (error) {
      throw error;
    }
  };

  const contactItem = contactListItems.map((item) => {
    return (
      <>
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{
            padding: "10px",
          }}
          onClick={() => {
            handleChatSelection(item, "CHAT");
          }}
        >
          <Grid item position={"relative"}>
            <Avatar src={item.user_profile_pic_url} />
            {props.groupSelectedArr?.map((newItem) => {
              return (
                newItem?.user_id === item?.user_id && (
                  <CheckIcon
                    fontSize="small"
                    sx={{
                      bottom: 0,
                      right: 0,
                      left: 40,
                      position: "absolute",
                      color: "#3DACFF",
                      backgroundColor: "#000",
                      borderRadius: "50%",
                      padding: "1px",
                      opacity: 0.9,
                    }}
                  />
                )
              );
            })}
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: "opacity75",
              }}
            >
              {item.user_name}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  });

  return (
    <Box>
      {props.groupSelectedArr?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            backgroundColor: "#000",
            padding: 1,
          }}
        >
          <Button variant="outlined" onClick={() => setShowModal(true)}>
            Done
          </Button>
        </Box>
      )}

      <Box
        id="scrollableDiv"
        sx={{
          height: "87vh",
          overflow: "scroll",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <InfiniteScroll
          dataLength={contactListItems.length}
          next={() => {
            setPage((prev) => prev + 1);
          }}
          style={{ display: "flex", flexDirection: "column" }} //To put endMessage and loader to the top.
          // inverse={true} //
          hasMore={true}
          scrollableTarget="scrollableDiv"
          loader={<h4 style={{ color: "opacity75", textAlign: "center" }}>Loading...</h4>}
        >
          <Box>{contactItem}</Box>
        </InfiniteScroll>
      </Box>

      <Modal open={showModal} onClose={() => setShowModal(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "400px",
            maxHeight: "600px",
            padding: "16px",
            position: "relative",
            background: "#121212",
            borderRadius: "8px",
            overflow: "auto",
          }}
        >
          <Grid container display={"flex"} justifyContent={"space-between"} flexDirection={"row"}>
            <Typography variant="body1" id="modal-modal-description" sx={{ my: 2, color: "white" }}>
              Enter the group name.
            </Typography>
            <Grid item container justifyContent={"center"} position="relative" width={"30%"}>
              <FormLabel
                htmlFor="file"
                sx={{
                  borderRadius: "50%",
                  "&:hover": { cursor: "pointer", svg: { opacity: 1 } },
                }}
              >
                <Input
                  type="file"
                  id="file"
                  onChange={changeHandler}
                  inputProps={{ accept: "image/jpg, image/jpeg, image/png" }}
                  sx={{ visibility: "hidden", position: "absolute" }}
                />
                <Avatar src={groupPicture ?? ""} style={{ width: "70px", height: "70px" }} />
                <ModeEditOutlineIcon
                  fontSize="small"
                  sx={{
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    color: "#3DACFF",
                    backgroundColor: "#000",
                    borderRadius: "50%",
                    padding: "6px",
                  }}
                />
              </FormLabel>
            </Grid>
          </Grid>
          <Input
            autoFocus
            fullWidth
            required
            placeholder="Type here..."
            sx={{
              color: "white !important",
              borderRadius: "5px",
            }}
            onChange={(e) => setGroupName(e.target.value)}
          />

          <Grid direction={"row"} justifyContent={"end"} container sx={{ mt: 1 }}>
            <Button
              variant="outlined"
              sx={{ m: 1 }}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" sx={{ m: 1 }} disabled={!groupName} onClick={() => handleChatSelection(props.groupSelectedArr, "GROUP")}>
              Create
            </Button>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default ContactList;
