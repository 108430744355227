/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Grid, Typography } from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";

const InboxRoomEmpty = () => {
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ minHeight: "100%", backgroundColor: "rgba(255, 255, 255, 0.2)" }}>
      <CommentIcon
        sx={{
          fontSize: "45px",
          color: "opacity75",
          marginTop: "3px",
        }}
      />
      <Grid item>
        <Typography variant="opacity95"> Start your first Chat</Typography>
      </Grid>
      <Grid item>
        <Typography variant="opacity70">Talk to one person or a group. Express yourself with reactions and bots.</Typography>
      </Grid>
    </Grid>
  );
};

export default InboxRoomEmpty;
