import { Route, Routes } from "react-router-dom";
import { useAuthContext } from "Scaffold/src/contexts";
import { REACT_APP_AUTH_PLATFORM_URL } from "Scaffold/src/utilities/constants";
import CreateForm from "../Sections/CreateForm";
import Dashboard from "../Sections/Dashboard";
import NotFound from "../Sections/NotFound";
import PreviewForm from "../Sections/PreviewForm";

const PublicRoute = () => {
  window.location.replace(REACT_APP_AUTH_PLATFORM_URL!);
  return <></>;
};

const PrivateRoutes = function () {
  const {
    authState: { access_token },
  } = useAuthContext();

  return (
    <Routes>
      {access_token ? (
        <>
          <Route path="/create-form/:formId" element={<CreateForm />} />
          <Route path="/preview-form">
            <Route index element={<PreviewForm />} />
            <Route path=":formId" element={<PreviewForm />} />
          </Route>
          <Route path="/" element={<Dashboard />} />
          <Route path="*" element={<NotFound />} />
        </>
      ) : (
        <>
          <PublicRoute />
        </>
      )}
    </Routes>
  );
};

export default PrivateRoutes;
