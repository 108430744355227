import { Paper, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useStyles } from "./styles";
import { IHeadingData } from "./index";

interface Props {
  headingData: IHeadingData;
  setHeadingData: React.Dispatch<React.SetStateAction<any>>;
}

const Header = ({ headingData, setHeadingData }: Props) => {
  const styles = useStyles();

  const handleChange = (e) => {
    const temp = {
      ...headingData,
      [e.target.name]: {
        ...headingData[e.target.name],
        value: e.target.value,
      },
    };
    setHeadingData(temp);
    // };
  };
  const handleEdit = (name: string, value: boolean) => {
    const temp = {
      ...headingData,
      [name]: {
        ...headingData[name],
        isEditable: value,
      },
    };

    setHeadingData(temp);
  };

  return (
    <Stack className={styles.commonView} component={Paper}>
      <Stack spacing={2} width="100%">
        {headingData.title.isEditable ? (
          <TextField
            fullWidth
            autoFocus
            variant="standard"
            inputProps={{ style: { fontSize: 40 } }}
            placeholder="Title"
            value={headingData.title.value}
            name="title"
            onChange={handleChange}
            onBlur={() => handleEdit("title", false)}
          />
        ) : (
          <Typography
            variant="h4"
            color="black"
            onClick={() => handleEdit("title", true)}
            borderBottom={1}
          >
            {headingData.title.value || "Untitled Form"}
          </Typography>
        )}
        {headingData.description.isEditable ? (
          <TextField
            fullWidth
            autoFocus
            variant="standard"
            placeholder="Description"
            value={headingData.description.value}
            name="description"
            onChange={handleChange}
            onBlur={() => handleEdit("description", false)}
          />
        ) : (
          <Typography
            variant="subtitle1"
            color="black"
            onClick={() => handleEdit("description", true)}
            borderBottom={1}
          >
            {headingData.description.value || "Form description"}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default Header;
