import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  topSection: {
    background: "opacity75",
    alignItems: "center",
  },

  groupStyle: {
    padding: "4px 0px 4px 0px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    margin: "10px 10px",
    borderRadius: 2,
  },
  groupText: {
    color: "#0B72F4",
    paddingLeft: 8,
  },
});
