/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import InboxList from "./components/InboxList";
import InboxRoom from "./components/InboxRoom";
// import InboxRoomEmpty from "./components/InboxRoomEmpty";
import { CHAT_ROOM, CHAT_LIST } from "../../apollo/subscription";
import { useLazyQuery, useSubscription } from "@apollo/client";
import { subscriptionClient } from "../../apollo/client";
import { GET_CONVERSATION_MESSAGES } from "../../apollo/queries";
import InboxRoomEmpty from "./components/InboxRoomEmpty";
import ContactListContainer from "./components/ContactListContainer";
// import { ConstructionOutlined } from "@mui/icons-material";

export interface MessageTypeN {
  conversationId: string;
  createdAt: string;
  messageType: string;
  msg: string;
  senderId: number;
  senderName: string;
  url: string | null;
}

interface chatRoomType {
  id: string;
  name: string;
  conversationtype: "chat | group";
  groupName: "";
  lastMessage?: Object;
  profileUrl: any;
  timestamp: string;
  unreadCount: number;
}

const InboxPage = () => {
  //this state is for chat room or chat list in mobile view
  // ** true === Chat List
  // ** false === Chat Room
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showList, setShowList] = useState<boolean>(false);

  //container index
  const [chatRoomData, setChatRoomData] = useState<chatRoomType>();
  const [openContactList, setOpenContactList] = useState<boolean>(false);
  const [refreshChatList, setrefreshChatList] = useState<boolean>(false);
  //pages index
  const [conversationIdSelected, setConversationIdSelected] = useState<string>("");
  const [messages, setMessages] = useState<MessageTypeN[]>([]);
  const [myId, setmyId] = useState<number>(0);

  // SUBSCRIPTION InboxList
  const { data: chatListData } = useSubscription(CHAT_LIST, {
    client: subscriptionClient,
  });

  // SUBSCRIPTION InboxRoom
  const { data, error, loading } = useSubscription(CHAT_ROOM, {
    client: subscriptionClient,
    variables: {
      conversationId: conversationIdSelected,
    },
  });

  useEffect(() => {
    // eslint-disable-next-line
    const latestMessage = data?.messageAdded?.data;
    if (latestMessage) {
      setMessages((prevState) => [...prevState, latestMessage]);
    }
    // eslint-disable-next-line
  }, [data, error, loading]);

  // PREV CONVERSATION MESSAGES
  const [getConversationMessages, { data: getConversationMessagesData, loading: getCoversationMessagesLoading, error: getConversationMessagesError }] =
    useLazyQuery(GET_CONVERSATION_MESSAGES, {
      client: subscriptionClient,
      variables: {
        conversationId: conversationIdSelected,
        limit: null,
      },
      fetchPolicy: "network-only",
    });

  //hit getConversationMessages only when we have conversationId
  useEffect(() => {
    if (conversationIdSelected !== "" && conversationIdSelected !== null) {
      getConversationMessages();
    }
    // eslint-disable-next-line
  }, [conversationIdSelected]);

  useEffect(() => {
    if (getConversationMessagesData !== undefined && getCoversationMessagesLoading !== true) {
      // //adding previous messages to the state
      let conversationData = getConversationMessagesData?.getConversationMessages?.data?.messages;
      if (conversationData && conversationData.length > 0) {
        setMessages([]);
        conversationData.map((item: any) => {
          let messageData: MessageTypeN = {
            conversationId: item.conversationId,
            createdAt: item.createdAt,
            messageType: item.messageType,
            msg: item.msg,
            senderId: item.senderId,
            senderName: item.senderName,
            url: item.url,
          };
          return setMessages((prevState) => [...prevState, messageData]);
        });
      } else {
        setMessages([]);
      }
    } else {
    }
  }, [getConversationMessagesData, getCoversationMessagesLoading, getConversationMessagesError]);

  const handleChat = (item: any) => {
    setChatRoomData(item);
    setConversationIdSelected(item.id);
  };

  return (
    // <>
    <Grid container sx={{ height: "calc(100vh - 100px)", maxHeight: "calc(100vh - 100px)", overflow: "hidden", marginBottom: "-72px" }}>
      <Grid item xs={12} sm={12} md={3} sx={{ maxHeight: "calc(100vh - 100px)" }}>
        {openContactList ? (
          <ContactListContainer
            openContactList={openContactList}
            setOpenContactList={setOpenContactList}
            refreshChatList={refreshChatList}
            setrefreshChatList={setrefreshChatList}
            handleChat={handleChat}
          />
        ) : (
          <InboxList
            setConversationIdSelected={setConversationIdSelected}
            messages={messages}
            myId={myId}
            setmyId={setmyId}
            handleChat={handleChat}
            setOpenContactList={setOpenContactList}
            refreshChatList={refreshChatList}
            setrefreshChatList={setrefreshChatList}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={9} lg={9} sx={{ maxHeight: "calc(100vh - 100px)", width: "100%" }}>
        {chatRoomData ? <InboxRoom chatRoomData={chatRoomData} messages={messages} myId={myId} /> : <InboxRoomEmpty />}
      </Grid>
    </Grid>
    // </>
  );
};

export default InboxPage;
