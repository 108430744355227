import { Grid, Box, Typography, CircularProgress } from "@mui/material";
import { useStyles } from "./style";
import { useRef, useEffect } from "react";
import dayjs from "dayjs";

const ChatMessages = (props: any) => {
  const messagesArray = props.messages;
  const classes = useStyles();
  const messagesEndRef = useRef<null | HTMLDivElement>();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollToBottom();
  }, [props.messages]);

  const messageItem = props.messages.map((message: any) => {
    return (
      <Grid item height={"max-content"}>
        {message.messageType === "EVENT" && (
          <Grid className={classes?.event}>
            <Typography variant="opacity70-14px">{message?.msg}</Typography>
          </Grid>
        )}

        <Grid
          container
          display={message.messageType === "EVENT" ? "none" : "flex"}
          flexDirection="column"
          wrap="nowrap"
          alignItems={message.senderId === props.myId ? "flex-end" : "flex-start"}
        >
          <Grid item display="flex" flexDirection="column" className={message.senderId === props.myId ? classes.messageRight : classes.messageLeft}>
            <Box className={message.senderId === props.myId ? classes.messageBubbleContainerRight : classes.messageBubbleContainerLeft}>
              <Box sx={{ margin: "8px 0px" }} className={message.senderId === props.myId ? classes.messageBubbleRight : classes.messageBubbleLeft}>
                {message.messageType === "TEXT" && (
                  <Typography sx={{ color: message.senderId === props.myId ? "#fff" : "#000", wordBreak: "break-all" }}>{message.msg}</Typography>
                )}

                {message.messageType === "IMAGE" && (
                  <img
                    src={message?.url}
                    alt="chatImg"
                    style={{
                      borderRadius: "10px 10px 10px 10px",
                      width: 300,
                      height: 220,
                    }}
                  />
                )}
                {message.messageType === "VIDEO" && <video src={message?.url} width="300" height="220" controls />}
              </Box>
            </Box>
            {props.myId === message.senderId && messagesArray[messagesArray.length - 1].createdAt === message.createdAt ? (
              <Typography variant="caption" className={classes.timestamp}>
                {dayjs(message.createdAt).format("hh:mma")}
              </Typography>
            ) : null}
            {props.myId !== message.senderId ? (
              <Typography variant="caption" className={classes.timestamp}>
                {dayjs(message.createdAt).format("hh:mma")}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    );
  });

  return (
    <>
      <Grid container direction="column" flexWrap="nowrap" className={classes.messageBox}>
        {messageItem}
        {props.loader && <CircularProgress />}
        <Box ref={messagesEndRef}></Box>
      </Grid>
    </>
  );
};

export default ChatMessages;
