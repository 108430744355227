import { useRef } from "react";
import { styled, Popper, PopperProps, Paper, PaperProps } from "@mui/material";

const PopperWithArrow = (
  props: Omit<PopperProps, "children"> & { PaperProps?: PaperProps; backgroundColor?: React.CSSProperties["backgroundColor"]; children?: React.ReactNode }
) => {
  const arrowRef = useRef<HTMLDivElement>();
  const StyledPopper: any = styled(Popper)(({ theme }) => ({
    zIndex: 1,
    "& > div": {
      position: "relative",
    },
    '&[data-popper-placement*="bottom"]': {
      "& > div": {
        marginTop: 8,
      },
      "& .MuiPopper-arrow": {
        top: 0,
        left: "48%",
        marginTop: "-0.9em",
        width: "3em",
        height: "1em",
        "&::before": {
          borderWidth: "0 1rem 1rem 1rem",
          borderColor: `transparent transparent ${props.backgroundColor || theme.palette.background.paper} transparent`,
        },
      },
    },
    '&[data-popper-placement*="top"]': {
      "& > div": {
        marginBottom: 8,
      },
      "& .MuiPopper-arrow": {
        bottom: 0,
        right: "48%",
        marginBottom: "-0.9em",
        width: "3em",
        height: "1em",
        "&::before": {
          borderWidth: "1rem 1rem 0 1rem",
          borderColor: `${props.backgroundColor || theme.palette.background.paper} transparent transparent transparent`,
        },
      },
    },
    '&[data-popper-placement*="right"]': {
      "& > div": {
        marginLeft: 12,
      },
      "& .MuiPopper-arrow": {
        zIndex: -1,
        left: -1,
        marginLeft: "-0.9em",
        height: "3em",
        width: "1em",
        "&::before": {
          borderWidth: "1rem 1rem 1rem 0",
          borderColor: `transparent ${props.backgroundColor || theme.palette.background.paper} transparent transparent`,
        },
      },
    },
    '&[data-popper-placement*="left"]': {
      "& > div": {
        marginRight: 8,
      },
      "& .MuiPopper-arrow": {
        right: 0,
        marginRight: "-0.9em",
        height: "3em",
        width: "1em",
        "&::before": {
          borderWidth: "1rem 0 1rem 1rem",
          borderColor: `transparent transparent transparent ${props.backgroundColor || theme.palette.background.paper}`,
        },
      },
    },
  }));

  const Arrow = styled("div")({
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  });

  return (
    <StyledPopper
      onResize={undefined}
      onResizeCapture={undefined}
      {...props}
      modifiers={
        props.modifiers?.length
          ? [...props.modifiers, { name: "arrow", enabled: true, options: { element: arrowRef.current } }]
          : [{ name: "arrow", enabled: true, options: { element: arrowRef.current } }]
      }
    >
      <div>
        <Arrow
          className="MuiPopper-arrow"
          ref={(instance) => {
            if (instance) {
              arrowRef.current = instance;
            }
          }}
        />
        <Paper {...props.PaperProps}>{props.children}</Paper>
      </div>
    </StyledPopper>
  );
};

export default PopperWithArrow;
