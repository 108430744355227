/* eslint-disable @typescript-eslint/no-unused-vars */
import { Divider, Grid, TextField, Box, Typography, Button, Input, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContactList from "../ContactList";
import GroupIcon from "@mui/icons-material/Group";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useStyles } from "./styles";
import CancelIcon from "@mui/icons-material/Cancel";
import { Close, Search } from "@mui/icons-material";

type accountType = {
  user_id: number;
  user_name: string;
  user_profile_pic_url: any;
};

const ContactListContainer = (props: any) => {
  const classes = useStyles();
  const [searchContact, setsearchContact] = useState<string>("");
  const [isGroupSelection, setGroupSelection] = useState<boolean>(false);
  const [groupSelectedArr, setGroupSelectedArr] = useState<accountType[]>([]);
  const handleDrawerClose = () => {
    props.setrefreshChatList(true);
    props.setOpenContactList(false);
    //refresh the chatList on this event
  };

  const handleChange = (e: any) => {
    setsearchContact(e.target.value);
  };

  return (
    <Grid container direction="column">
      <Grid container className={classes.topSection}>
        <Grid sx={{ display: "flex", alignItems: "center", my: 1 }}>
          <Close onClick={handleDrawerClose} />
          <Typography variant="opacity70" fontWeight={600} fontSize={"18px"} ml={0.5}>
            New Chat
          </Typography>
        </Grid>
        <Grid container>
          <Box
            className={classes.groupStyle}
            onClick={() => {
              setGroupSelection(!isGroupSelection);
              setGroupSelectedArr([]);
            }}
          >
            {!isGroupSelection ? <GroupIcon sx={{ color: "#0B72F4" }} /> : <CancelIcon sx={{ color: "opacity70" }} />}

            <Typography className={classes.groupText}>New Group</Typography>
          </Box>
          <Box className={classes.groupStyle}>
            <GroupAddIcon sx={{ color: "#0B72F4" }} />
            <Typography className={classes.groupText}>Invite Friends</Typography>
          </Box>
        </Grid>
        <Input
          type="text"
          sx={{ width: " -webkit-fill-available" }}
          placeholder={"Search"}
          value={searchContact}
          onChange={handleChange}
          startAdornment={
            <InputAdornment position="start">
              <Search sx={{ color: "opacity70" }} />
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item>
        <ContactList
          searchContact={searchContact}
          setsearchContact={setsearchContact}
          handleChange={handleChange}
          handleDrawerClose={handleDrawerClose}
          handleChat={props.handleChat}
          isGroupSelection={isGroupSelection}
          groupSelectedArr={groupSelectedArr}
          setGroupSelectedArr={setGroupSelectedArr}
        />
      </Grid>
    </Grid>
  );
};

export default ContactListContainer;
