import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";

export const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    bar: {
      fill: "none",
      stroke: theme.palette.text.secondary,
      strokeDasharray: "60 207",
      strokeWidth: 6,
      transition: "stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1)",
    },
    middleBar: {
      fill: "none",
      stroke: theme.palette.text.secondary,
      strokeDasharray: "60 60",
      strokeWidth: 6,
      transition: "stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1)",
    },
    changebar1: { strokeDasharray: "90 207", strokeDashoffset: "-134", strokeWidth: 6 },
    changebar2: { strokeDasharray: "1 60", strokeDashoffset: "-30", strokeWidth: 6 },
    changebar3: { strokeDasharray: "90 207", strokeDashoffset: "-134", strokeWidth: 6 },
  };
});
