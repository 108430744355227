import { createTheme } from "@mui/material";

// Main Theme
const Dark = createTheme({
  palette: {
    mode: "dark",
    // mediakits: {
    //   white: "#fff",
    //   "navy-blue": "#0B0927",
    //   "gradient-bg-main":
    //     "radial-gradient(ellipse at 200% 60%, rgba(244, 133, 23, 1) 0%, rgba(186, 7, 252, 1) 0%, rgba(252, 7, 111, 1) 0%, rgba(11, 10, 37, 1) 49%)",
    //   "gradient-bg-border-top": "linear-gradient(rgb(11, 9, 39, 1), rgba(11, 9, 39, 0) 100%)",
    //   "gradient-bg-border-bottom": "linear-gradient(rgba(11, 9, 39, 0), rgb(11, 9, 39, 1) 100%)",
    // },
    // vn: {
    //   blue: "#3DACFF",
    //   "blue-opacity": "3dacff40",
    //   "info-blue": "#73c0fa",
    //   "info-blue-opacity": "#73c0fa40",
    //   error: "#E43B3B",
    //   "error-opacity": "#e43b3b40",
    //   success: "#3FFCAE",
    //   "success-opacity": "#3FFCAE40",
    //   warning: "#FFA842",
    //   "warning-opacity": "#FFA84240",
    //   "black-grey": "#181818",
    //   "dark-grey": "#181A1C",
    //   grey: "#232425",
    //   "light-grey": "#787878",
    //   "paper-secondary-blue": "#e8f0fe",
    //   "paper-elevation-blue": "#eaf1fb",
    //   orange: "#F48517",
    //   maroon: "#FC076D",
    //   purple: "#BA07FC",
    //   "maroon-secondary": "#FC076F",
    //   "dark-blue": "#111F3B",
    // },
    card: {
      main: "#181818",
      light: "#212121",
      dark: "#161616",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          color: "white",
          paddingLeft: "10px",
          padding: "5px 10px 5px 10px",
          border: "1px solid #424242 !important",
          borderRadius: "5px",
          "&.Mui-focused": {
            border: "1px solid #3075cb !important",
            borderRadius: "5px",
          },
        },
        input: {
          fontsize: "16px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        noOptions: {
          color: "white",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#121212",
          color: "white",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
    },

    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          color: "#FFFFFF",
          width: "100%",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          "&.MuiAppBar-colorDefault": {
            backgroundColor: "#121212",
          },
        },
      },
    },

    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: "rgba(255, 255, 255, 0.7)",
          p: {
            marginBottom: 0,
          },
        },
      },
    },

    MuiButton: {
      variants: [
        {
          props: { color: "primary", variant: "contained" },
          style: {
            backgroundColor: `{Dark.palette.primary.main}`,
            color: "white",
            letterSpacing: "1.5px",
            fontWeight: 700,
            padding: "8px 16px",
            "&.Mui-hover ": {
              backgroundColor: `{Dark.palette.primary.light}`,
            },
            "&.Mui-clicked ": {
              opacity: 0.7,
              color: "white",
            },
            "&.Mui-disabled ": {
              opacity: 0.6,
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            border: `{1px solid {Dark.palette.divider}}`,
            fontWeight: 700,
            padding: "8px 16px",
            "&.Mui-hover ": {
              backgroundColor: `{Dark.palette.primary.main}`,
            },
            "&.Mui-clicked ": {
              opacity: 0.7,
              color: "white",
            },
          },
        },
        {
          props: { color: "primary", variant: "text" },
          style: {
            color: `{Dark.palette.primary.main}`,
            fontWeight: 700,
            textTransform: "none",
            padding: "8px 16px",
            "&.Mui-hover ": {
              textDecoration: `underline {Dark.palette.primary.light}`,
            },
            "&.Mui-clicked ": {
              opacity: 0.4,
              color: "white",
            },
          },
        },
      ],
    },
  },
});

export default Dark;
