import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
  expand: {
    "&.transition": {
      transition: "transform 255ms ease-in-out",
    },
    "&.expand": {
      transform: "rotate(-180deg)",
    },
  },
}));
