/* eslint-disable @typescript-eslint/no-unused-vars */
import { KeyboardEvent, useState } from "react";
import { useStyles } from "./styles";
import { Grid, Box, Input, TextField } from "@mui/material";
import { AddReaction, AttachFile, AddCircle, Photo, NearMeOutlined, SentimentSatisfiedAlt, CameraAltOutlined, MicOutlined, MicOff } from "@mui/icons-material";
import EmojiPicker, { Theme } from "emoji-picker-react";
import useRecorder from "../../hooks/useRecorder";

const ChatResponse = (props: any) => {
  const [showEmoji, setShowEmoji] = useState<boolean>(false);
  let [audioURL, isRecording, startRecording, stopRecording] = useRecorder();
  const classes = useStyles();

  const keyHandler = (e: KeyboardEvent<HTMLImageElement>) => {
    if (e.key === "Enter") {
      props.handleSendMessage("TEXT", props.msgToSend);
      props.setMsgToSend("");
    }
  };

  return (
    <>
      {showEmoji && (
        <Box position="absolute" bottom="9vh" right="5vw">
          <EmojiPicker
            theme={Theme.DARK}
            autoFocusSearch={false}
            height={400}
            onEmojiClick={(e) => {
              props.handleSendMessage("TEXT", e.emoji);
              props.setMsgToSend("");
              setShowEmoji(!showEmoji);
            }}
          />
        </Box>
      )}
      <Grid display="flex" alignItems="center" onKeyDown={keyHandler}>
        <Input type="file" onChange={(e) => props.handleUpload(e)} id="icon-button-file" style={{ display: "none" }} />
        <label htmlFor="icon-button-file">
          <AddCircle
            sx={{
              fontSize: "25px",
              color: "opacity75",
              marginRight: ".5rem",
            }}
          />
        </label>
        <Grid
          container
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            padding: "1px 8px 1px 0px ",
            borderRadius: 3,
          }}
        >
          <Grid
            item
            xs={11}
            lg={10}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            sx={{ backgroundColor: "rgba(0, 0, 0, 0.2)", borderRadius: 4, pr: 1 }}
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type to Reply"
              className={classes.messageBox}
              InputProps={{
                classes: {
                  input: classes.text,
                },
              }}
              value={props.msgToSend}
              onChange={(e) => props.setMsgToSend(e.target.value)}
            />
            <SentimentSatisfiedAlt
              onClick={() => setShowEmoji(!showEmoji)}
              sx={{
                fontSize: "25px",
                color: "opacity75",
              }}
            />
          </Grid>
          <Grid item xs={1} lg={2} className={classes.sendBtnContainer} gap={1}>
            <Box onClick={startRecording} hidden={isRecording}>
              <MicOutlined
                sx={{
                  fontSize: "25px",
                }}
              />
            </Box>
            <Box onClick={stopRecording} hidden={!isRecording}>
              <MicOff
                sx={{
                  fontSize: "25px",
                }}
              />
            </Box>

            <Input
              color="primary"
              inputProps={{ accept: "image/*" }}
              type="file"
              onChange={(e) => props.handleUpload(e)}
              id="icon-button-img"
              style={{ display: "none" }}
            />
            <label htmlFor="icon-button-img">
              <Box>
                <CameraAltOutlined
                  sx={{
                    fontSize: "25px",
                    // color: "opacity",
                  }}
                />
              </Box>
            </label>
            <Box
              className={classes.sendIcon}
              onClick={() => {
                props.handleSendMessage("TEXT", props.msgToSend);
                props.setMsgToSend("");
              }}
            >
              <NearMeOutlined
                sx={{
                  fontSize: "25px",
                  color: "opacity75",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ChatResponse;
