import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  chatHeaderContainer: {
    padding: "1rem",
    background: "opacity95",
    borderRadius: "30px 30px 0 0",

  },
  chatBoxContainer: {
    overflowY: "auto",
   
  },
  chatResponseContainer: {
    padding: ".8rem",
    background:"rgba(255,255,255,0.2)",
 
  },
});
