import { useLazyQuery } from "@apollo/client";
import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { userClient } from "../../apollo/client";
import { GET_TEAM_MEMBERS, GET_USER_WORKSPACES } from "../../apollo/queries";
import { useAuthContext, useWorkspacesContext } from "../../contexts";
import { REACT_APP_DOMAIN_ID } from "../../utilities/constants";
import ScaffoldSkeleton from "../Skeleton";

const WorkspacesInitializer = (props: { children: React.ReactNode }) => {
  const {
    authState: { user_id, last_active_workspace_id },
  } = useAuthContext();
  const {
    workspacesState: { list, active_workspace },
    workspacesDispatch,
  } = useWorkspacesContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [getUserWorkspace, { data: userWorkspacesData, loading: userWorkspacesLoading, error: userWorkspacesError }] = useLazyQuery(GET_USER_WORKSPACES, {
    client: userClient,
    variables: {
      userId: user_id,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (user_id) {
      getUserWorkspace();
    }
  }, [getUserWorkspace, user_id]);

  useEffect(() => {
    if (list.length && active_workspace) {
      setLoading(false);
    }
  }, [list, active_workspace]);

  useEffect(() => {
    if (userWorkspacesData && !userWorkspacesLoading && !userWorkspacesError) {
      if (userWorkspacesData?.getUserWorkspaces?.status && userWorkspacesData?.getUserWorkspaces?.data?.length) {
        workspacesDispatch({ type: "SET_WORKSPACES", payload: userWorkspacesData?.getUserWorkspaces?.data });
      }
    }
  }, [userWorkspacesData, userWorkspacesLoading, userWorkspacesError, workspacesDispatch]);

  useEffect(
    () => {
      if (typeof last_active_workspace_id === "number" && list.length) {
        const index = list.findIndex((workspace: any) => workspace.id === last_active_workspace_id);
        if (index !== -1) {
          workspacesDispatch({ type: "SET_ACTIVE_WORKSPACE", payload: list[index] });
        } else {
          const i = list.findIndex((workspace: any) => workspace?.is_personal === 1);
          if (i !== -1) {
            workspacesDispatch({ type: "SET_ACTIVE_WORKSPACE", payload: list[i] });
          } else if (list.length) {
            workspacesDispatch({ type: "SET_ACTIVE_WORKSPACE", payload: list[0] });
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [last_active_workspace_id, list]
  );

  const [getAllTeamMembersQuery, { data: teamMembersData, loading: teamMembersLoading, error: teamMembersError }] = useLazyQuery(GET_TEAM_MEMBERS, {
    client: userClient,
    variables: {
      teamId: active_workspace?.team_id,
    },
  });

  useEffect(() => {
    if (active_workspace?.team_id) {
      getAllTeamMembersQuery();
    } // eslint-disable-next-line
  }, [active_workspace?.id]);

  useEffect(() => {
    if (teamMembersData && !teamMembersLoading && !teamMembersError && user_id) {
      const members = teamMembersData?.getTeamMembers?.data;
      if (members) {
        let loggedInMember = members.find((member: any) => member?.user_id === user_id);
        if (loggedInMember) {
          if (loggedInMember?.is_owner) {
            workspacesDispatch({ type: "SET_WORKSPACE_OWNER", payload: true });
            workspacesDispatch({ type: "SET_WORKSPACE_ADMIN", payload: true });
          } else if (loggedInMember?.is_admin) {
            workspacesDispatch({ type: "SET_WORKSPACE_OWNER", payload: false });
            workspacesDispatch({ type: "SET_WORKSPACE_ADMIN", payload: true });
          } else {
            workspacesDispatch({ type: "SET_WORKSPACE_OWNER", payload: false });
            workspacesDispatch({ type: "SET_WORKSPACE_ADMIN", payload: false });
          }
        }
      }
    } // eslint-disable-next-line
  }, [teamMembersData, user_id]);

  return loading ? (
    REACT_APP_DOMAIN_ID === "7" ? (
      <Box width={"100vw"} height={"100vh"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <CircularProgress disableShrink />
      </Box>
    ) : (
      <ScaffoldSkeleton />
    )
  ) : (
    <Box component={"div"}>{props.children}</Box>
  );
};

export default WorkspacesInitializer;
