import { createContext } from "../hooks";
import React, { useState } from "react";

let initialState: Profile.State = {
  first_name: "",
  last_name: "",
  year_of_birth: "",
  profile_pic_url: "",
  email: "",
};

export const [useProfileContext, ProfileProvider] = createContext<Profile.ContextType>();

export const ProfileContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [profileState, profileDispatch] = useState<Profile.State>(initialState);

  return <ProfileProvider value={{ profileState, profileDispatch }}>{children}</ProfileProvider>;
};
