import { SvgIconProps } from "@mui/material";

const CreatorPlusIcon = (props: SvgIconProps) => {
  return (
    <svg {...props} version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200.000000 179.000000" preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,179.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
        <path
          d="M485 1406 c-72 -23 -119 -53 -173 -110 -96 -101 -138 -236 -129 -411
15 -284 155 -425 421 -425 55 0 123 8 171 19 70 17 82 18 96 5 15 -13 17 -9
22 38 10 83 8 253 -2 242 -6 -5 -28 -45 -50 -89 -72 -145 -146 -204 -235 -189
-57 9 -116 72 -140 146 -49 157 -49 472 0 622 21 63 75 123 123 136 87 23 159
-32 236 -180 25 -50 50 -90 55 -90 11 0 13 112 4 212 -6 60 -9 65 -24 56 -13
-8 -37 -6 -97 11 -90 24 -214 27 -278 7z"
        />
        <path
          d="M1547 1173 c-4 -3 -7 -62 -7 -129 l0 -123 -122 -3 c-119 -3 -123 -4
-126 -25 -3 -23 -2 -23 122 -23 l126 0 0 -140 0 -140 25 0 24 0 3 138 3 137
128 3 127 3 0 24 0 25 -130 0 -130 0 0 130 c0 119 -1 130 -18 130 -10 0 -22
-3 -25 -7z"
        />
      </g>
    </svg>
  );
};

export default CreatorPlusIcon;
