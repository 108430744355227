import React from "react";
import { Tooltip, tooltipClasses, TooltipProps, styled } from "@mui/material";

const CustomTooltip = function <
  T extends {
    sx: Required<Pick<React.CSSProperties, "backgroundColor" | "borderRadius">>;
    content: TooltipProps["title"];
    children: React.ReactElement<any, any>;
    placement?: TooltipProps["placement"];
  } & Omit<TooltipProps, "placement" | "sx" | "children" | "title">
>(props: T) {
  const {
    content,
    sx: { backgroundColor, borderRadius },
    children,
    placement,
    ...restProps
  } = props;
  const StyledComponent = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow={true} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: backgroundColor,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: backgroundColor,
        borderRadius: borderRadius,
      },
    })
  );
  return (
    <StyledComponent placement={placement} title={content} {...restProps}>
      {children}
    </StyledComponent>
  );
};

export default CustomTooltip;
