import { gql } from "@apollo/client";

export const NEW_NOTIFICATION = gql`
    subscription NewNotification {
        newNotification {
            code
            status
            msg
            data {
                id
            }
        }
    }
`;