/* eslint-disable no-unreachable */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useNavigate } from "react-router-dom";
import { Grid, IconButton, Menu, MenuItem, Typography, Drawer, Paper, Button, Switch, useTheme, Avatar } from "@mui/material";
import React, { useState, useEffect } from "react";
import { GET_CONVERSATION_INFO, GET_USER_CONVERSATION } from "../../../../apollo/queries";
import { toast } from "react-toastify";
import { subscriptionClient } from "../../../../apollo/client";
import { useMutation, useQuery } from "@apollo/client";
import PhotoIcon from "@mui/icons-material/Photo";
import FolderIcon from "@mui/icons-material/Folder";
import LogoutIcon from "@mui/icons-material/Logout";
import BlockIcon from "@mui/icons-material/Block";
import FlagIcon from "@mui/icons-material/Flag";
import AddIcon from "@mui/icons-material/Add";
import { useAuthContext } from "../../../../contexts";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { LEAVE_GROUP, REMOVE_MEMBER, MUTE_CONVERSATION } from "../../../../apollo/mutations";
import { TOASTIFY_THEME } from "../../../../utilities/constants";
import InviteGroupMemberDialog from "../InviteGroupMemberDialog";
import InboxMuteModal from "../InboxMuteModal";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

interface InboxInfoDrawerProps {
  open: boolean;
  onClose: () => void;
  conversationId: string;
}

type Users = {
  user_id?: string;
  user_name?: string;
  user_profile_pic_url?: string;
  chat_user?: null;
  is_admin?: boolean;
};

interface InfoType {
  _id: string;
  created_at: string;
  last_message: Object;
  name: string;
  profile_url: string;
  type: string;
  updated_at: string;
  users: Users[];
  is_mute: boolean;
}

const muteChatActivityArray = [
  {
    key: 1,
    labelName: "8 hours",
  },
  {
    key: 1,
    labelName: "1 week",
  },
  {
    key: 1,
    labelName: "Always",
  },
];

const InboxInfoDrawer = (props: InboxInfoDrawerProps) => {
  const theme = useTheme();
  const [info, setInfo] = useState<InfoType>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [groupAdmin, setGroupAdmin] = useState<boolean>(false);
  const [showInviteMemberModal, setShowInviteMemberModal] = useState<boolean>(false);
  const [muteChatActivity, setMuteChatActivity] = useState<boolean>(false);
  const [showMuteActivityModal, setShowMuteActivityModal] = useState<boolean>(false);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const {
    authState: { user_id },
  } = useAuthContext();

  const [leaveGroup, { data: leaveGroupData, loading: leaveGroupLoading, error: leaveGroupError }] = useMutation(LEAVE_GROUP, {
    client: subscriptionClient,
    fetchPolicy: "network-only",
    refetchQueries: [{ query: GET_USER_CONVERSATION, fetchPolicy: "cache-only" }],
  });

  // mutation to remove member from group chat
  const [removeMember, { data: removeMemberData, error: removeMemberError, loading: removeMemberLoading }] = useMutation(REMOVE_MEMBER, {
    client: subscriptionClient,
    fetchPolicy: "network-only",
  });

  const [muteConversation, { data: muteConversationData, loading: muteConversationLoading, error: muteConversationError }] = useMutation(MUTE_CONVERSATION, {
    client: subscriptionClient,
    fetchPolicy: "network-only",
  });

  const {
    data,
    error,
    loading,
    refetch: getConversationInfoQuery,
  } = useQuery(GET_CONVERSATION_INFO, {
    variables: {
      conversationId: props.conversationId,
    },
    client: subscriptionClient,
    fetchPolicy: "network-only",
  });

  const handleLeaveGroup = () => {
    if (info?.type === "GROUP") {
      leaveGroup({
        variables: {
          conversationId: props?.conversationId,
        },
      });
    }
  };

  const handleRemoveMember = (user: Users) => {
    removeMember({
      variables: {
        conversationId: props.conversationId,
        user: {
          is_admin: user.is_admin,
          user_id: user.user_id,
          user_name: user.user_name,
          user_profile_pic_url: user.user_profile_pic_url,
        },
      },
    });
    handleClose();
  };

  const handleMakeAdmin = (user: Users) => {
    //awaiting API
    handleClose();
  };

  useEffect(() => {
    if (data?.getConversationInfo?.data?.conversation) {
      setInfo(data.getConversationInfo.data.conversation);
    }
  }, [data, error, loading]);

  useEffect(() => {
    if (info) {
      const admin: Array<Users> = info?.users.filter((user) => user.is_admin)!;
      admin?.forEach((user) => {
        if (Number(user.user_id) === Number(user_id)) {
          setGroupAdmin(true);
        } else {
          setGroupAdmin(false);
        }
      });
      setMuteChatActivity(info?.is_mute);
    }
  }, [info, user_id]);

  useEffect(() => {
    if (leaveGroupData?.leaveGroup?.status && !leaveGroupError && !leaveGroupLoading) {
      props?.onClose();
    } else {
      toast.info(leaveGroupData?.leaveGroup?.message, TOASTIFY_THEME);
      props?.onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaveGroupData, leaveGroupError, leaveGroupLoading]);

  useEffect(() => {
    if (removeMemberData?.removeUserFromGroup?.status && !removeMemberError && !removeMemberLoading) {
      props?.onClose();
      getConversationInfoQuery();
      toast.success(removeMemberData?.removeUserFromGroup?.msg, TOASTIFY_THEME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeMemberData, removeMemberError, removeMemberLoading]);

  useEffect(() => {
    if (muteConversationData?.muteConversation?.status && !muteConversationError && !muteConversationLoading) {
      props?.onClose();
      getConversationInfoQuery();
      toast.success(muteConversationData?.muteConversation?.msg, TOASTIFY_THEME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [muteConversationData, muteConversationError, muteConversationLoading]);

  const handleMuteChatActivity = (value: string) => {
    let muteUntil;
    const currentDate = new Date();
    dayjs.extend(utc);
    dayjs.extend(tz);
    const timeZone = dayjs.tz.guess();
    switch (value) {
      case "8 hours":
        muteUntil = dayjs.utc(currentDate).tz(timeZone).add(8, "hour").toISOString();
        break;
      case "1 week":
        muteUntil = dayjs.utc(currentDate).tz(timeZone).add(7, "day").toISOString();
        break;
    }
    muteConversation({
      variables: {
        conversationId: props.conversationId,
        muteUntil: muteUntil,
        isMute: true,
      },
    });
    setShowMuteActivityModal(false);
  };

  const handleSwitchChatActivity = () => {
    if (muteChatActivity) {
      muteConversation({
        variables: {
          conversationId: props.conversationId,
          isMute: false,
        },
      });
    } else {
      setShowMuteActivityModal(true);
    }
  };

  return (
    <Drawer {...props} sx={{ zIndex: theme.zIndex.drawer }} anchor={"right"} PaperProps={{ variant: "primary" }}>
      <Grid width="20rem" marginTop={5} display={"flex"} flexDirection={"column"} px={1.5} mb={4}>
        <Grid display={"flex"} alignItems="center" justifyContent="center" flexDirection={"column"} gap={1}>
          <Avatar alt="profilePic" src={info?.profile_url} sx={{ width: 70, height: 70 }} />
          <Typography fontSize={"18px"} fontWeight={700} variant="opacity95">
            {info?.name}
          </Typography>
        </Grid>
        <Grid display={"flex"} justifyContent={"space-between"} alignItems={"center"} mt={2}>
          <Grid>
            <PhotoIcon
              sx={{
                fontSize: "25px",
                color: "opacity70",
                mr: 1,
              }}
            />
            <Typography fontSize={"12px"} fontWeight={700} variant="opacity70">
              MEDIA
            </Typography>
          </Grid>
          <Button
            variant="text"
            style={{
              cursor: "pointer",
              fontSize: "12px",
              color: "#0B72F4",
            }}
          >
            SHOW ALL
          </Button>
        </Grid>
        <Grid display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={2}>
          <Grid>
            <FolderIcon
              sx={{
                fontSize: "25px",
                color: "opacity70",
                mr: 1,
              }}
            />
            <Typography fontSize={"12px"} fontWeight={700} variant="opacity70">
              FILE
            </Typography>
          </Grid>
          <Button
            variant="text"
            style={{
              cursor: "pointer",
              fontSize: "12px",
              color: "#0B72F4",
            }}
          >
            SHOW ALL
          </Button>
        </Grid>
        <Typography fontSize={"16px"} fontWeight={700} variant="opacity95">
          Notifications
        </Typography>
        <Grid display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Typography fontSize={"14px"} fontWeight={500} variant="opacity70">
            Mute messages
          </Typography>

          <Switch />
        </Grid>

        {info?.type === "GROUP" && (
          <Grid display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography fontSize={"14px"} fontWeight={500} variant="opacity70">
              Mute @mentions
            </Typography>

            <Switch />
          </Grid>
        )}

        {info?.type === "GROUP" && (
          <Grid display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography fontSize={"14px"} fontWeight={500} variant="opacity70">
              Mute chat activity notifications
            </Typography>

            <Switch checked={muteChatActivity} onChange={handleSwitchChatActivity} />
          </Grid>
        )}
        {info?.type === "GROUP" && (
          <>
            <Grid display={"flex"} justifyContent={"space-between"} alignItems={"center"} mt={2}>
              <Typography fontSize={"16px"} fontWeight={700} variant="opacity95">
                Members
              </Typography>
              <Grid
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                onClick={() => {
                  setShowInviteMemberModal(!showInviteMemberModal);
                }}
              >
                <AddIcon
                  sx={{
                    fontSize: "22px",
                    color: "#0061e0",
                    mr: 0.5,
                  }}
                />
                <Typography fontSize={"14px"} fontWeight={700} color={"#0061e0"}>
                  Add Member
                </Typography>
              </Grid>
            </Grid>
            {info?.users &&
              info.users?.map((member: any) => {
                return (
                  <Grid display={"flex"} justifyContent={"space-between"} alignItems={"center"} mt={2}>
                    <Grid display={"flex"} flexDirection={"row"} alignItems={"center"}>
                      <Avatar alt="profilePic" src={member?.user_profile_pic_url} sx={{ width: 30, height: 30 }} />
                      <Typography fontSize={"14px"} fontWeight={500} variant="opacity70" ml={0.5}>
                        {member?.user_name}
                      </Typography>
                    </Grid>
                    {member?.is_admin ? (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          padding: "4px 10px",
                          background: "rgba(0, 127, 0, 0.09) !important",
                          borderRadius: "8px",
                          color: "rgba(0, 127, 0, 1.0) !important",
                        }}
                      >
                        Group Admin
                      </Typography>
                    ) : null}
                    {!member?.is_admin && groupAdmin ? (
                      <Grid>
                        <IconButton
                          onClick={(e) => {
                            setAnchorEl(e.currentTarget);
                          }}
                        >
                          <MoreVertIcon sx={{ color: theme.palette.grey[700] }} />
                        </IconButton>
                        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                          <MenuItem
                            sx={{ background: "rgba(255,255,255,0.1)" }}
                            key={1}
                            onClick={() => {
                              handleRemoveMember(member);
                            }}
                          >
                            Remove from group
                          </MenuItem>
                          <MenuItem
                            sx={{ background: "rgba(255,255,255,0.1)" }}
                            key={2}
                            onClick={() => {
                              handleMakeAdmin(member);
                            }}
                          >
                            Make group admin
                          </MenuItem>
                        </Menu>
                      </Grid>
                    ) : null}
                  </Grid>
                );
              })}
          </>
        )}
        <Paper sx={{ padding: "0.5rem", display: "flex", alignItems: "center", my: 2 }} variant="secondary" onClick={handleLeaveGroup}>
          {info?.type === "GROUP" ? (
            <LogoutIcon
              sx={{
                fontSize: "25px",
                color: "#ED5945",
                mr: 1,
              }}
            />
          ) : (
            <BlockIcon
              sx={{
                fontSize: "25px",
                color: "#ED5945",
                mr: 1,
              }}
            />
          )}
          <Typography fontSize={"14px"} fontWeight={500} color="#ED5945">
            {info?.type === "GROUP" ? "Leave" : "Block"} {info?.name}
          </Typography>
        </Paper>

        <Paper sx={{ padding: "0.5rem", display: "flex", alignItems: "center" }} variant="secondary">
          {info?.type === "GROUP" ? (
            <BlockIcon
              sx={{
                fontSize: "25px",
                color: "#ED5945",
                mr: 1,
              }}
            />
          ) : (
            <FlagIcon
              sx={{
                fontSize: "25px",
                color: "#ED5945",
                mr: 1,
              }}
            />
          )}
          <Typography fontSize={"14px"} fontWeight={500} color="#ED5945">
            {info?.type === "GROUP" ? "Block" : "Report"} {info?.name}
          </Typography>
        </Paper>
      </Grid>

      <InviteGroupMemberDialog
        open={showInviteMemberModal}
        onClose={() => {
          setShowInviteMemberModal(false);
        }}
        groupId={props.conversationId}
        setShowInviteMemberModal={setShowInviteMemberModal}
        handleClose={props?.onClose}
      />
      <InboxMuteModal
        open={showMuteActivityModal}
        onClose={() => {
          setShowMuteActivityModal(false);
        }}
        rightButton="Mute Notifications"
        title={`Mute "${info?.name}" for ...`}
        data={muteChatActivityArray}
        handleActivity={handleMuteChatActivity}
        handleCancel={() => {
          setShowMuteActivityModal(false);
        }}
      />
    </Drawer>
  );
};

export default InboxInfoDrawer;
