import { createSlice, current } from "@reduxjs/toolkit";

type stateType = {
  data: any;
  isLoading: boolean;
  error: string;
};

const stateData: stateType = {
  data: null,
  isLoading: false,
  error: "",
};

export type formState = {
  allForms: stateType;
};

const initialState: formState = {
  allForms: stateData,
};

export const formSlice = createSlice({
  name: "forms",
  initialState: initialState,
  reducers: {
    addComponentToForm: (state, { payload }) => {
      let currentState: any = current(state);
      let allFormsCopy = [...currentState.allForms.data];
      let index: any = allFormsCopy?.findIndex(
        (form) => form.id === payload.id
      );
      let tempData: any = [];
      if (index > -1) {
        tempData = [...allFormsCopy[index].data];
      }

      tempData?.push(payload.data);
      allFormsCopy[index] = { ...allFormsCopy[index], data: tempData };

      state.allForms.data = [...allFormsCopy];
    },
    updateComponent: (state, { payload }) => {
      console.log("🚀 ~ file: formSlice.ts ~ line 46 ~ payload", payload);
      let currentState: any = current(state);
      let allFormsCopy = [...currentState.allForms.data];
      let index: any = allFormsCopy?.findIndex(
        (form) => form.id === payload.id
      );
      let tempData: any = [];
      if (index > -1) {
        tempData = [...allFormsCopy[index].data];
      }

      if (tempData[payload.index]) {
        console.log(
          "🚀 ~ file: formSlice.ts ~ line 58 ~ tempData[payload.index]",
          tempData[payload.index]
        );
        tempData[payload.index] = payload.updatedData;
        allFormsCopy[index] = { ...allFormsCopy[index], data: tempData };
      }

      state.allForms.data = [...allFormsCopy];
    },
    deleteComponentfromForm: (state, { payload }) => {
      let currentState: any = current(state);
      let allFormsCopy = [...currentState.allForms.data];
      let index: any = allFormsCopy?.findIndex(
        (form) => form.id === payload.id
      );
      let tempData: any = [];
      if (index > -1) {
        tempData = [...allFormsCopy[index].data];
      }

      if (tempData[payload.index]) {
        tempData.splice(payload.index, 1);
        allFormsCopy[index] = { ...allFormsCopy[index], data: tempData };
      }

      state.allForms.data = [...allFormsCopy];
    },
    formOrderChange: (state, { payload }) => {
      let currentState: any = current(state);
      let allFormsCopy = [...currentState.allForms.data];
      let index: any = allFormsCopy?.findIndex(
        (form) => form.id === payload.id
      );
      let tempData: any = [];
      if (index > -1) {
        tempData = [...allFormsCopy[index].data];
      }

      if (tempData[payload.index]) {
        tempData.splice(payload.index, 1);
        allFormsCopy[index] = { ...allFormsCopy[index], data: payload.data };
      }

      state.allForms.data = [...allFormsCopy];
    },
    clearData: (state) => {
      state.allForms = stateData;
    },
    saveFormData: (state, { payload }) => {
      let tempData: any = [];
      if (state.allForms.data) {
        tempData = [...state.allForms.data];
      }
      tempData.push(payload);

      state.allForms.data = tempData;
    },
  },
});
export const {
  addComponentToForm,
  deleteComponentfromForm,
  updateComponent,
  formOrderChange,
  clearData,
  saveFormData,
} = formSlice.actions;

export default formSlice.reducer;
