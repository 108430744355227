import { useReducer } from "react";
import { vmessengerReducer } from "../context-reducers";
import { createContext } from "../hooks";

let initialState: VMessenger.State = {
  active_account: { provider: "gmail", profile_pic: "", name: "Calvin Smilga", email: "calvin.smilga@gmail.com" },
  connected_accounts: {},
};

export const [useVMessengerContext, VMessengerProvider] = createContext<VMessenger.ContextType>();

export const VMessengerContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [vmessengerState, vmessengerDispatch] = useReducer(vmessengerReducer, initialState);

  return <VMessengerProvider value={{ vmessengerState, vmessengerDispatch }}>{children}</VMessengerProvider>;
};
