/* eslint-disable @typescript-eslint/no-unused-vars */
import jwtDecode from "jwt-decode";
import { Box, CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { useAuthContext, useProfileContext, useThemePreferenceContext } from "../../contexts";
import { GET_SSO_AUTH_KEY, GET_SSO_KEY_LOGIN } from "../../apollo/mutations";
import { authClient, authClientWCredentials, persistor, userClient } from "../../apollo/client";
import { REACT_APP_AUTH_PLATFORM_URL, THEME_MAP, REACT_APP_DOMAIN_ID, ENV } from "../../utilities/constants";
import { getEpochSeconds } from "../../utilities";
import ScaffoldSkeleton from "../Skeleton";
import LoginLoader from "../LoginLoader";
import { GET_USER_ONBOARDING_STATUS_QUERY, GET_USER_PROFILE, GET_USER_SETTINGS } from "../../apollo/queries";
import { getAccessToken } from "../../main";

const AuthInitializer = (props: { children: React.ReactNode }) => {
  const { authState, authDispatch } = useAuthContext();
  const { profileDispatch } = useProfileContext();
  const { themePreferenceDispatch } = useThemePreferenceContext();
  const { pathname } = useLocation();
  const firstRenderRef = useRef(true);
  const navigate = useNavigate();
  const theme = useTheme();
  const isXsSize = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState<boolean>(true);
  const [InitialLoad, setInitialLoad] = useState<boolean>(true);
  const [getSSOAccessToken, { data: ssoData, loading: ssoLoading, error: ssoError }] = useMutation(GET_SSO_KEY_LOGIN, {
    client: authClient,
    fetchPolicy: "network-only",
  });
  const [getSSOAuthKey, { data: ssoAuthData, loading: ssoAuthLoading, error: ssoAuthError }] = useMutation(GET_SSO_AUTH_KEY, {
    client: authClientWCredentials,
    fetchPolicy: "network-only",
  });
  const [getUserSettings, { data: userSettingsData, loading: userSettingsLoading, error: userSettingsError }] = useLazyQuery(GET_USER_SETTINGS, {
    client: userClient,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-and-network",
  });

  const [getUserProfileAPI, { data: userProfile, loading: userProfileLoading }] = useLazyQuery(GET_USER_PROFILE, {
    client: userClient,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-and-network",
  });

  const login = useCallback(async () => {
    await persistor.purge();
    if (/\/ssoauth\/[a-zA-Z0-9]{64}\/[0-9]+\/?$/.test(pathname)) {
      const [authKey, userId] = pathname.replace("/ssoauth/", "").split("/");
      if (authState.access_token && authState.auth_key && authState.user_id) {
        if (!authState.access_token_exp) {
          const { exp } = jwtDecode<Auth.JWTPaylod>(authState.access_token);
          if (getEpochSeconds() > exp) {
            window.location.replace(REACT_APP_AUTH_PLATFORM_URL!);
            return;
          }
          authDispatch({ type: "SET_ACCESS_TOKEN_EXP", payload: exp });
        } else {
          if (getEpochSeconds() > authState.access_token_exp) {
            window.location.replace(REACT_APP_AUTH_PLATFORM_URL!);
            return;
          }
        }
        if (+userId === authState.user_id) {
          setLoading(false);
          return;
        }
      }
      authDispatch({ type: "SET_USER_ID", payload: +userId });
      try {
        getSSOAccessToken({
          variables: {
            userId: +userId,
            authKey: authKey,
          },
        });
      } catch (error) {
        window.location.replace(REACT_APP_AUTH_PLATFORM_URL!);
      }

      return;
    }
    if (/\/ssoauth\/[a-zA-Z0-9]{64}\/[0-9]+\/[a-zA-Z-]+\/?$/.test(pathname)) {
      const [authKey, userId, menu] = pathname.replace("/ssoauth/", "").split("/");
      if (authState.access_token && authState.auth_key && authState.user_id) {
        if (!authState.access_token_exp) {
          const { exp } = jwtDecode<Auth.JWTPaylod>(authState.access_token);
          if (getEpochSeconds() > exp) {
            window.location.replace(REACT_APP_AUTH_PLATFORM_URL!);
            return;
          }
          authDispatch({ type: "SET_ACCESS_TOKEN_EXP", payload: exp });
        } else {
          if (getEpochSeconds() > authState.access_token_exp) {
            window.location.replace(REACT_APP_AUTH_PLATFORM_URL!);
            return;
          }
        }
        if (+userId === authState.user_id) {
          setLoading(false);
          return;
        }
      }
      authDispatch({ type: "SET_USER_ID", payload: +userId });
      authDispatch({ type: "SET_REDIRECT_SIDE_MENU", payload: `/${menu}` });
      try {
        getSSOAccessToken({
          variables: {
            userId: +userId,
            authKey: authKey,
          },
        });
      } catch (error) {
        window.location.replace(REACT_APP_AUTH_PLATFORM_URL!);
      }
      return;
    }
    if (/\/ssoauth\/[a-zA-Z0-9]{64}\/[0-9]+\/[a-zA-Z-]+\/[a-zA-Z-]+\/?$/.test(pathname)) {
      const [authKey, userId, menu, subMenu] = pathname.replace("/ssoauth/", "").split("/");
      if (authState.access_token && authState.auth_key && authState.user_id) {
        if (!authState.access_token_exp) {
          const { exp } = jwtDecode<Auth.JWTPaylod>(authState.access_token);
          if (getEpochSeconds() > exp) {
            window.location.replace(REACT_APP_AUTH_PLATFORM_URL!);
            return;
          }
          authDispatch({ type: "SET_ACCESS_TOKEN_EXP", payload: exp });
        } else {
          if (getEpochSeconds() > authState.access_token_exp) {
            window.location.replace(REACT_APP_AUTH_PLATFORM_URL!);
            return;
          }
        }
        if (+userId === authState.user_id) {
          setLoading(false);
          return;
        }
      }
      authDispatch({ type: "SET_USER_ID", payload: +userId });
      authDispatch({ type: "SET_REDIRECT_SIDE_MENU", payload: `/${menu}/${subMenu}` });
      try {
        getSSOAccessToken({
          variables: {
            userId: +userId,
            authKey: authKey,
          },
        });
      } catch (error) {
        window.location.replace(REACT_APP_AUTH_PLATFORM_URL!);
      }
      return;
    }
    if (REACT_APP_DOMAIN_ID === "7" && !authState.access_token && pathname.startsWith("/profile")) {
      setLoading(false);
      return;
    }
    window.location.replace(REACT_APP_AUTH_PLATFORM_URL!);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authDispatch, getSSOAccessToken, pathname, navigate, authState]);

  const authKeyForSSO = useCallback(() => {
    if (authState.access_token && authState.user_id) {
      getSSOAuthKey({ variables: { userId: authState.user_id } });
    }
  }, [authState.access_token, authState.user_id, getSSOAuthKey]);

  const getSettings = useCallback(() => {
    if (authState.access_token && authState.user_id) {
      getUserSettings({
        variables: {
          userId: authState.user_id,
        },
      });
    }
  }, [authState.access_token, authState.user_id, getUserSettings]);
  const getUserProfile = useCallback(() => {
    if (authState.access_token && authState.user_id) {
      getUserProfileAPI();
    }
  }, [authState.access_token, authState.user_id, getUserProfileAPI]);

  const token = getAccessToken();

  useEffect(() => {
    if (token) {
      const decoded: any = jwtDecode<Auth.JWTPaylod>(token);
      if (decoded?.roles[0] && decoded?.exp && decoded?.user_id) {
        authDispatch({ type: "SET_ACCESS_TOKEN", payload: token });
        authDispatch({ type: "SET_ACCESS_TOKEN_EXP", payload: decoded?.exp });
        authDispatch({ type: "SET_USER_ID", payload: decoded?.user_id });
        authDispatch({ type: "SET_ROLE_ID", payload: decoded?.roles[0] });
        AccessCheck(decoded?.roles[0]);
        setTimeout(
          () => {
            setInitialLoad(false);
          },
          REACT_APP_DOMAIN_ID === "1" && window.location.pathname === "/" ? 2000 : 0
        );
      } else {
        window.location.replace(REACT_APP_AUTH_PLATFORM_URL!);
      }
    } else {
      if (firstRenderRef.current) {
        firstRenderRef.current = false;
        if (pathname.startsWith("/ssoauth")) {
          setTimeout(() => {
            setInitialLoad(false);
            login();
          }, 2000);
        } else {
          setInitialLoad(false);
          login();
        }
      }
    } // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    if (authState.access_token) {
      if (!authState.access_token_exp) {
        const { exp } = jwtDecode<Auth.JWTPaylod>(authState.access_token);
        if (getEpochSeconds() < exp) {
          authDispatch({ type: "SET_ACCESS_TOKEN_EXP", payload: exp });
        } else {
          window.location.replace(REACT_APP_AUTH_PLATFORM_URL!);
          return;
        }
      }
      getSettings();
      getUserProfile();
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.access_token]);

  useEffect(() => {
    if (ssoData && !ssoLoading && !ssoError) {
      if (ssoData?.authSSO?.status && ssoData?.authSSO?.data?.access_token) {
        const decoded = jwtDecode<Auth.JWTPaylod>(ssoData?.authSSO?.data?.access_token);
        localStorage.setItem("access_token", ssoData?.authSSO?.data?.access_token);
        authDispatch({ type: "SET_ROLE_ID", payload: decoded?.roles[0] });
        authDispatch({ type: "SET_ACCESS_TOKEN", payload: ssoData?.authSSO?.data?.access_token });
        authDispatch({ type: "SET_ACCESS_TOKEN_EXP", payload: decoded.exp });
        AccessCheck(decoded?.roles[0]);
      } else {
        if (!ssoData?.authSSO && !ssoError) {
          window.location.replace(REACT_APP_AUTH_PLATFORM_URL!);
        }
        if (!ssoData?.authSSO?.status) {
          window.location.replace(REACT_APP_AUTH_PLATFORM_URL!);
        }
      }
    } else if (ssoError && !ssoLoading) {
      window.location.replace(REACT_APP_AUTH_PLATFORM_URL!);
    } // eslint-disable-next-line
  }, [ssoData, ssoLoading, ssoError, authDispatch]);

  useEffect(() => {
    if (authState.access_token && authState.user_id && !authState.auth_key) {
      authKeyForSSO();
    }
    if (authState.access_token && authState.user_id && typeof authState.last_active_workspace_id !== "number") {
      getSettings();
    }
    if (authState.access_token && authState.user_id && !!localStorage.getItem("auth_key_used")) {
      localStorage.removeItem("auth_key_used");
      authKeyForSSO();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authKeyForSSO, getSettings]);

  useEffect(() => {
    if (ssoAuthData?.authKeyForSSO?.status && ssoAuthData?.authKeyForSSO?.data?.access_token) {
      authDispatch({ type: "SET_AUTH_KEY", payload: ssoAuthData?.authKeyForSSO?.data?.access_token });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssoAuthData, ssoAuthLoading, ssoAuthError]);

  useEffect(() => {
    if (userSettingsData && !userSettingsLoading && !userSettingsError) {
      if (userSettingsData?.getUserSettings?.data?.last_active_workspace_id) {
        authDispatch({ type: "SET_LAST_ACTIVE_WORKSPACE_ID", payload: userSettingsData?.getUserSettings?.data?.last_active_workspace_id });
      }
      if (userSettingsData?.getUserSettings?.data?.hasOwnProperty("is_pinned")) {
        authDispatch({ type: "SET_WORKSPACE_PINNED_STATUS", payload: isXsSize ? true : userSettingsData?.getUserSettings?.data?.is_pinned });
      }
      if (userSettingsData?.getUserSettings?.data?.hasOwnProperty("profile_pic_url")) {
        authDispatch({ type: "SET_PROFILE_PICTURE_URL", payload: userSettingsData?.getUserSettings?.data?.profile_pic_url });
      }
      if (userSettingsData?.getUserSettings?.data?.theme_id) {
        const { theme_id } = userSettingsData?.getUserSettings?.data;
        if (typeof theme_id === "number" && (theme_id === 1 || theme_id === 2)) {
          themePreferenceDispatch({ type: "SET_THEME_PREFERENCE", payload: THEME_MAP[theme_id] });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettingsData, userSettingsLoading, userSettingsError, authDispatch]);

  const [intercomInitialized, setIntercomInitialized] = useState<any>(false);
  useEffect(() => {
    if (userProfile?.getUserProfile?.data?.id) {
      if (!intercomInitialized && ENV === "prod" && token) {
        // InterCom Chat needs user_name & email - Only initialize on load and these variables are removed on intercom load from session storage
        const name = userProfile?.getUserProfile?.data?.first_name + userProfile?.getUserProfile?.data?.last_name;
        sessionStorage.setItem("user_name", name ? name : "there");
        if (userProfile?.getUserProfile?.data?.user_emails && userProfile?.getUserProfile?.data?.user_emails.length > 0) {
          sessionStorage.setItem("email", userProfile?.getUserProfile?.data?.user_emails[0].email);
        }
        setIntercomInitialized(true);
      }
      profileDispatch({
        first_name: userProfile.getUserProfile.data.first_name,
        last_name: userProfile.getUserProfile.data.last_name,
        profile_pic_url: userProfile.getUserProfile.data.profile_pic_url,
        year_of_birth: userProfile.getUserProfile.data.year_of_birth,
        email: userProfile.getUserProfile.data.user_emails[0].email,
      });
    } // eslint-disable-next-line
  }, [userProfile, token]);

  const [getUserOnboardingStatus, { loading: getUserOnboardingStatusLoading }] = useLazyQuery(GET_USER_ONBOARDING_STATUS_QUERY, {
    client: userClient,
    fetchPolicy: "no-cache",
  });

  async function AccessCheck(roleID: Number) {
    if (REACT_APP_DOMAIN_ID === "4" && roleID !== 5) {
      window.location.replace(REACT_APP_AUTH_PLATFORM_URL!);
    }

    try {
      const { data } = await getUserOnboardingStatus();
      const result = data?.getOnboardingStatus?.data;
      if (result) {
        if (!result?.is_plan_selected) {
          window.location.replace(REACT_APP_AUTH_PLATFORM_URL! + "/chooseaccounttype");
          return "/chooseaccounttype";
        } else if (!result?.is_payment_completed) {
          window.location.replace(REACT_APP_AUTH_PLATFORM_URL! + "/selectPlan");
        } else {
        }
      } else {
        window.location.replace(REACT_APP_AUTH_PLATFORM_URL!);
      }
    } catch (error) {
      window.location.replace(REACT_APP_AUTH_PLATFORM_URL!);
    }
  }

  useEffect(() => {
    if (!loading && !ssoLoading && !ssoAuthLoading && !userSettingsLoading && !userProfileLoading && !getUserOnboardingStatusLoading) {
      if (pathname.startsWith("/ssoauth")) {
        const [authKey, userId, menu, subMenu] = pathname.replace("/ssoauth/", "").split("/");
        if (menu && subMenu) {
          navigate("/" + menu + "/" + subMenu, { replace: true });
        } else if (menu) {
          navigate("/" + menu, { replace: true });
        } else {
          navigate("/", { replace: true });
        }
      }
    }
  }, [navigate, pathname, loading, ssoLoading, ssoAuthLoading, userSettingsLoading, userProfileLoading, getUserOnboardingStatusLoading]);

  return InitialLoad ? (
    <LoginLoader />
  ) : loading || pathname.startsWith("/ssoauth") ? (
    REACT_APP_DOMAIN_ID === "7" ? (
      <Box width={"100vw"} height={"100vh"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <CircularProgress disableShrink />
      </Box>
    ) : (
      <ScaffoldSkeleton />
    )
  ) : (
    <Box component={"div"}>{props.children}</Box>
  );
};

export default AuthInitializer;
