import { Grid } from "@mui/material";

const SecureLogo = (props: any) => {
  return (
    <Grid container alignItems="center">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 867.05 177.73" width={props?.size ?? 110} height="100%">
      <g id="c">
        <g>
          <path
            fill="#1484D8"
            d="M333.03,122.1h16.03c.4,17.21,7.32,24.93,17.21,24.93s16.03-7.52,16.03-17.81c0-31.26-46.29-47.09-46.29-82.89,0-18.2,11.87-29.87,29.28-29.87,19.39,0,30.86,12.07,31.26,35.61l-15.63,.2c-.59-16.03-5.93-22.75-15.04-22.75-8.51,0-14.24,5.93-14.24,16.03,0,30.07,46.69,44.51,46.69,82.7,0,19.58-12.27,32.05-32.05,32.05s-33.04-13.25-33.24-38.18Z"
          />
          <path fill="#1484D8" d="M481.41,32.08h-39.37v47.88h32.25v13.85h-32.25v50.65h39.37v14.05h-54.8V18.03h54.8v14.04Z" />
          <path
            fill="#1484D8"
            d="M503.38,88.27c0-41.94,17.21-71.62,46.49-71.62,7.72,0,14.84,2.18,18.79,4.75v14.24c-3.36-2.97-9.69-5.54-16.82-5.54-21.37,0-33.04,24.34-33.04,58.16s11.67,58.17,33.04,58.17c7.12,0,13.45-2.57,16.82-5.54v14.24c-3.96,2.57-11.08,4.75-18.79,4.75-29.28,0-46.49-29.67-46.49-71.62Z"
          />
          <path
            fill="#1484D8"
            d="M596.56,120.71V18.03h15.23V120.91c0,16.42,7.91,25.52,20.18,25.52s19.98-9.1,19.98-25.52V18.03h15.43V120.71c0,25.13-13.45,39.77-35.41,39.77s-35.41-14.64-35.41-39.77Z"
          />
          <path
            fill="#1484D8"
            d="M730.7,95.59c4.35,6.73,12.07,21.96,32.84,62.91h-17.21l-32.25-66.27c.59,13.65,.79,27.89,.79,66.27h-15.43V18.03h25.52c24.33,0,38.58,15.23,38.58,39.37,0,21.96-13.06,37.19-32.84,38.18Zm-7.32-63.51h-8.51v53.42h8.51c15.83,0,24.73-10.88,24.73-26.51,0-16.42-8.9-26.91-24.73-26.91Z"
          />
          <path fill="#1484D8" d="M846.63,32.08h-39.37v47.88h32.25v13.85h-32.25v50.65h39.37v14.05h-54.8V18.03h54.8v14.04Z" />
        </g>
        <g>
          <path
            fill={props?.color || "white"}
            d="M83.03,22.6v2.6h-17.86l38.51,100.83L142.93,25.21h-16.74v-2.6h35.53v2.6h-16l-52.46,135.06h-5.39L36.52,25.21h-15.81v-2.6h62.32Z"
          />
          <path
            fill={props?.color || "white"}
            d="M221.75,22.6l61.95,88.55h.37V25.21h-17.11v-2.6h37.21v2.6h-17.11V155.06h-5.02L198.31,35.81h-.37v116.64h17.11v2.6h-37.21v-2.6h17.12V25.21h-17.12v-2.6h43.9Z"
          />
        </g>
      </g>
    </svg>
    </Grid>
  );
};

export default SecureLogo;
