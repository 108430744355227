/* eslint-disable */
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import ChatHeader from "../ChatHeader";
import ChatMessages from "../ChatMessages";
import ChatResponse from "../ChatResponse";
import { useStyles } from "./styles";
import { SEND_MESSAGE } from "../../../../apollo/mutations";
import { useMutation } from "@apollo/client";
import { subscriptionClient } from "../../../../apollo/client";
import axios from "axios";
import { getAccessToken } from "../../../../main";
import { REACT_APP_MEDIA_UPLOAD_API_URL } from "../../../../utilities/constants";

const CHAT_IMAGE_UPLOAD_API = `${REACT_APP_MEDIA_UPLOAD_API_URL}user/upload/images`;
const CHAT_MEDIA_UPLOAD_API = `${REACT_APP_MEDIA_UPLOAD_API_URL}user/upload/media`;

type MessageType = {
  conversationId: string;
  senderId: string;
  senderName: string;
  messageType: string;
  msg?: string;
  url?: any;
  createdAt: string;
};

type MediaType = {
  url: string;
  type: string;
};

const InboxRoom = (props: any) => {
  const classes = useStyles();
  const [msgToSend, setMsgToSend] = useState<string>("");
  const [messages, setMessages] = useState<MessageType[]>([]);
  const conversationId = props?.chatRoomData?.id;
  const messageType = props?.chatRoomData?.conversationType;
  const [mediaURL, setMediaURL] = useState<MediaType>({ url: "", type: "" });
  // const [loader, setLoader] = useState(false);

  const [sendMessage, { data: sendMessageData, error: sendMessageError, loading: sendMessageLoading }] = useMutation(SEND_MESSAGE, {
    client: subscriptionClient,
  });

  const handleSendMessage = (type: string, msg: string) => {
    if (type === "TEXT" && msg !== "" && msg !== null) {
      sendMessage({
        variables: {
          conversationId: conversationId,
          message: msg,
          messageType: type,
        },
      });
    } else if (type === "IMAGE" || type === "VIDEO") {
      sendMessage({
        variables: {
          conversationId: conversationId,
          message: "",
          url: msg,
          messageType: type,
        },
      });
    }
  };

  useEffect(() => {
    if (sendMessageData?.sendMessage?.data && !sendMessageError && !sendMessageLoading) {
      setMessages((prev: MessageType[]) => {
        let msgSeq = prev;
        msgSeq = [
          ...msgSeq,
          {
            conversationId: prev.length.toString(),
            senderId: sendMessageData?.sendMessage?.data?.row?.senderId,
            senderName: sendMessageData?.sendMessage?.data?.row?.senderName,
            messageType: sendMessageData?.sendMessage?.data?.row?.messageType,
            msg: sendMessageData?.sendMessage?.data?.row?.msg,
            url: sendMessageData?.sendMessage?.data?.row?.url,
            createdAt: sendMessageData?.data?.row?.createdAt,
          },
        ];
        return msgSeq;
      });
    }
  }, [sendMessageData, sendMessageError, sendMessageLoading]);

  const handleUpload = async (e: any) => {
    // setLoader(true);
    e.preventDefault();
    e.stopPropagation();
    let [file] = e.target.files;
    let formData = new FormData();
    formData.append("file", file);
    let URL: string = "";
    if (file && file.type.includes("image")) {
      URL = CHAT_IMAGE_UPLOAD_API;
    } else if (file && file.type.includes("video")) {
      URL = CHAT_MEDIA_UPLOAD_API;
    }
    const token = getAccessToken();
    try {
      const { data } = await axios.post(URL, formData, {
        headers: {
          Authorization: token,
        },
      });
      if (file.type?.includes("image")) {
        setMediaURL({
          url: data?.data?.url,
          type: "IMAGE",
        });
      } else if (file.type?.includes("video")) {
        setMediaURL({
          url: data?.data?.url,
          type: "VIDEO",
        });
      }
      // setLoader(false);
    } catch (error: any) {
      throw error;
    }
  };

  useEffect(() => {
    if (mediaURL?.url) {
      const { url, type } = mediaURL;
      if (url) handleSendMessage(type, url);
    }
  }, [mediaURL]);

  return (
    // <>
    <Grid container flexDirection={"column"} width={"100%"}>
      <Grid item className={classes.chatHeaderContainer}>
        <ChatHeader chatRoomData={props?.chatRoomData} />
      </Grid>
      <Grid item sx={{ overflowY: "auto", minHeight: "calc(100vh - 240px)", maxHeight: "calc(100vh - 240px)", background: "rgba(255,255,255,0.7)" }}>
        <ChatMessages
          message={msgToSend}
          messages={props.messages}
          myId={props.myId}
          // loader={loader}
        />
      </Grid>
      <Grid item className={classes.chatResponseContainer}>
        <ChatResponse
          msgToSend={msgToSend}
          setMsgToSend={setMsgToSend}
          setMessages={setMessages}
          myId={props.myId}
          handleSendMessage={handleSendMessage}
          handleUpload={handleUpload}
        />
      </Grid>
    </Grid>
  );
};

export default InboxRoom;
