import { Star, Lock } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Grid, List, ListItem, ListItemButton, ListItemText, Typography, Drawer, DrawerProps, Paper, useTheme } from "@mui/material";

import { useAuthContext } from "../../contexts";
import FeedIcon from "../../assets/icon-components/FeedIcon";
import { REACT_APP_VN_PLATFORM_URL, REACT_APP_VN_SECURE_URL, REACT_APP_VN_TALENT_URL, REACT_APP_DOMAIN_ID } from "../../utilities/constants";

const ProductsDrawer = (props: DrawerProps) => {
  const {
    authState: { auth_key, user_id },
  } = useAuthContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const productsList = [
    {
      title: "Plus",
      domain_id: "2",
      icon: <FeedIcon />,
      route: `${REACT_APP_VN_PLATFORM_URL}/${auth_key}/${user_id}`,
    },
    {
      title: "Secure",
      domain_id: "1",
      icon: <Lock />,
      route: `${REACT_APP_VN_SECURE_URL}/${auth_key}/${user_id}`,
    },
    {
      title: "Talent",
      domain_id: "3",
      icon: <Star />,
      route: `${REACT_APP_VN_TALENT_URL}/${auth_key}/${user_id}`,
    },
  ];

  const servicesList = [
    {
      title: "Talent Solutions",
      description: "Find, attract and recruit talent",
    },
    {
      title: "Sales Solutions",
      description: "Unlock sales opportunities",
    },
    {
      title: "Post a job for free",
      description: "Get your job in front of quality candidates",
    },
    {
      title: "Marketing Solutions",
      description: "Acquire customers and grow your business",
    },
    {
      title: "Learning Solutions",
      description: "Develop talent accross your organization",
    },
  ];

  return (
    <Drawer {...props} sx={{ zIndex: theme.zIndex.drawer + 2, ...props.sx }} anchor={"right"} PaperProps={{ variant: "primary" }}>
      <Grid width="320px">
        <Grid alignItems="center" justifyContent="center" margin={2}>
          <Typography fontWeight="bold" padding={1} marginBottom={1} borderRadius="4px">
            Visit more Viral Nation products
          </Typography>
          <Grid container gap={1.5} alignItems={"center"} justifyContent={"space-evenly"} flexDirection={"row"} flexWrap={"wrap"}>
            {productsList.map((product, index) => (
              <Paper variant="secondary" key={index}>
                <Grid
                  container
                  sx={{ cursor: "pointer", "&:hover": { backgroundColor: "rgba(0,0,0,0.2)", boxShadow: 3 } }}
                  onClick={() => {
                    if (product.domain_id === REACT_APP_DOMAIN_ID!) {
                      navigate("/");
                      props.onClose!({}, "escapeKeyDown");
                    } else {
                      localStorage.setItem("auth_key_used", "used");
                      window.location.replace(product.route);
                    }
                  }}
                  height="78px"
                  width={"85px"}
                  border={"1px solid gray"}
                  borderRadius={"5px"}
                  p={1}
                  item
                  alignItems={"center"}
                  gap={1}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  key={index}
                >
                  {product.icon}
                  <Typography variant="opacity70">{product.title}</Typography>
                </Grid>
              </Paper>
            ))}
          </Grid>
        </Grid>
        <Grid alignItems="center" justifyContent="center" margin={2}>
          <Typography fontWeight="bold" padding={1} marginBottom={1} borderRadius="4px">
            Viral Nation Business Services
          </Typography>
          <List sx={{ display: "flex", gap: "8px", flexDirection: "column" }}>
            {servicesList.map((service, index) => (
              <ListItem disableGutters sx={{ paddingBottom: 0, paddingTop: 0 }} key={index}>
                <Paper variant="secondary" sx={{ display: "flex", width: "100%" }}>
                  <ListItemButton sx={{ borderRadius: "5px", border: "1px solid #5f6368", "&:hover": { backgroundColor: "rgba(0,0,0,0.2)", boxShadow: 3 } }}>
                    <ListItemText primary={service.title} secondary={service.description} />
                  </ListItemButton>
                </Paper>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default ProductsDrawer;
