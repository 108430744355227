import { createContext } from "../hooks";
import React, { useState } from "react";

let initialState: CreatorPlus.State = {
  isCreatorPlus: false,
};

export const [useCreatorPlusContext, CreatorPlusProvider] = createContext<CreatorPlus.ContextType>();

export const CreatorPlusContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [creatorPlusState, creatorPlusDispatch] = useState<CreatorPlus.State>(initialState);

  return <CreatorPlusProvider value={{ creatorPlusState, creatorPlusDispatch }}>{children}</CreatorPlusProvider>;
};
