import { Chip, useTheme } from "@mui/material";
import { camelize } from "../../utilities/validationLibrary";

const ReportStatusChip = (props: any) => {
    const { size, width, status } = props;
    const theme = useTheme();
    return (
        <Chip
            size={size ? size :"medium"}
            sx={{
                width: width ? width: "auto",
                color:
                    status === "Processing"
                        ? theme.palette.primary.main
                        : status === "failed"
                        ? theme.palette.error.main
                        : status === "Stopped"
                        ? theme.palette.error.main
                        : status === "Completed"
                        ? theme.palette.success.main
                        : status === "Dropped"
                        ? theme.palette.success.main
                        : status === "Paused"
                        ? theme.palette.warning.main
                        : status === "Extending"
                        ? theme.palette.warning.main
                        : "transparent",
                backgroundColor:
                    status === "Processing"
                        ? theme.palette.primary.light
                        : status === "failed"
                        ? theme.palette.error.light
                        : status === "Stopped"
                        ? theme.palette.error.light
                        : status === "Completed"
                        ? theme.palette.success.light
                        : status === "Dropped"
                        ? theme.palette.success.light
                        : status === "Paused"
                        ? theme.palette.warning.light
                        : status === "Extending"
                        ? theme.palette.warning.light
                        : "transparent",
            }}
            label={camelize(status)}
        ></Chip>
    );
};

export default ReportStatusChip;
