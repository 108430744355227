/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState } from "react";
import { Dialog, DialogProps, Grid, Typography, Radio, FormControlLabel, RadioGroup, FormControl, Button } from "@mui/material";

const InboxMuteModal = (
  props: Omit<DialogProps, "onClose" | "children"> & {
    onClose: Exclude<DialogProps["onClose"], undefined>;
    children?: never;
    rightButton: string;
    handleActivity: Function;
    data: {
      key: number;
      labelName: string;
    }[];
    title: string;
    handleCancel: () => void;
  }
) => {
  const { rightButton, title, handleActivity, data, ...dialogProps } = props;
  const [value, setValue] = useState<string>(data[data?.length - 1].labelName);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <Dialog {...dialogProps} PaperProps={{ variant: "primary-p1rem" }}>
      <Grid container maxWidth={"28rem"} minWidth={"22rem"} flexDirection={"column"}>
        <Typography fontWeight={600} variant="opacity70-20px">
          {title}
        </Typography>
        {data?.map((ele) => {
          return (
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={data[data?.length - 1].labelName}
                name="radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel value={ele?.labelName} control={<Radio />} label={ele.labelName} />
              </RadioGroup>
            </FormControl>
          );
        })}

        <Grid item xs={12} mt={2}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item mr={1}>
              <Button variant="text" onClick={() => props.handleCancel()}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{ cursor: "pointer" }}
                variant="contained"
                onClick={() => {
                  handleActivity(value);
                }}
              >
                {rightButton}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default InboxMuteModal;
