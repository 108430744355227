import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import { useLocation } from "react-router";
import { EQuestionTypes } from "../CreateForm";
import { useStyles } from "./styles";

const PreviewForm = () => {
  const {
    state: { headingData, questionData },
  } = useLocation();

  const defaultFormState = questionData.map((que) => {
    if (que.questionType === EQuestionTypes.CHECKBOXES) {
      const isReq = que.isRequired;
      return {
        questionText: que.questionText,
        [EQuestionTypes.CHECKBOXES]: isReq
          ? [que.questionOptions[0].optionsText]
          : [],
      };
    }
    return {
      questionText: que.questionText,
      [que.questionType]: "",
    };
  });

  const [formState, setFormState] = useState(defaultFormState);
  const [errorState, setErrorState] = useState(() => {
    const temp = {};
    questionData
      .filter((que) => que.isRequired)
      .forEach((que) => {
        temp[que.questionId] = {
          error: "",
        };
      });
    return temp;
  });

  const formStateChangeHandler = (value, type, index) => {
    const answerArr = [...formState];
    answerArr[index][type] = value;
    setFormState(answerArr);
  };

  const handleCheckboxChange = (value, label, index) => {
    const answerArr = [...formState];
    if (value) {
      answerArr[index][EQuestionTypes.CHECKBOXES] = [
        ...answerArr[index][EQuestionTypes.CHECKBOXES],
        label,
      ];
    } else {
      const temp = answerArr[index][EQuestionTypes.CHECKBOXES].filter(
        (optText) => optText === label
      );
      answerArr[index][EQuestionTypes.CHECKBOXES] = temp;
    }
    setFormState(answerArr);
  };

  const handleSubmit = () => {
    console.log(formState);
  };
  const handleClear = () => {
    setFormState(defaultFormState);
  };

  const styles = useStyles();

  return (
    <Grid container xs={12} p={2} justifyContent="center">
      <Grid container item xs={12} justifyContent="center">
        <Typography variant="h4">Preview</Typography>
      </Grid>
      <Grid container item xs={12} sm={12} md={10} lg={8} p={2}>
        <Stack direction="column" width="100%" spacing={2}>
          <Stack className={styles.commonView} component={Paper}>
            <Typography variant="h4" color="black" borderBottom={1} pb={1}>
              {headingData.title.value || "Untitled Form"}
            </Typography>
            {headingData.description.value && (
              <Typography variant="subtitle1" color="black" mt={2}>
                {headingData.description.value}
              </Typography>
            )}
          </Stack>
          {questionData.map((que, index) => (
            <Stack
              className={clsx(
                styles.commonQuestionView,
                Boolean(errorState[que.questionId]?.error) && styles.errorView
              )}
              component={Paper}
              key={index}
            >
              <Typography variant="body1">
                {que.questionText || "Question"}&nbsp;
                {que.isRequired && (
                  <Typography sx={{ color: "red", display: "inline-block" }}>
                    *
                  </Typography>
                )}
              </Typography>
              {que.questionType === EQuestionTypes.SHORT_ANSWER && (
                <FormControl sx={{ width: "50%" }}>
                  <TextField
                    label={null}
                    placeholder="Your answer"
                    variant="standard"
                    margin="normal"
                    size="small"
                    value={formState[index][EQuestionTypes.SHORT_ANSWER]}
                    onChange={(e) => {
                      setErrorState({
                        ...errorState,
                        [que.questionId]: {
                          error: "",
                        },
                      });
                      formStateChangeHandler(
                        e.target.value,
                        EQuestionTypes.SHORT_ANSWER,
                        index
                      );
                    }}
                    error={
                      errorState[que.questionId]?.error
                        ? Boolean(errorState[que.questionId].error)
                        : false
                    }
                    helperText={
                      errorState[que.questionId]?.error
                        ? "Field is required"
                        : ""
                    }
                    onBlur={() => {
                      if (
                        que.isRequired &&
                        formState[index][EQuestionTypes.SHORT_ANSWER].trim() ===
                          ""
                      ) {
                        setErrorState({
                          ...errorState,
                          [que.questionId]: {
                            error: true,
                          },
                        });
                      }
                    }}
                  />
                </FormControl>
              )}
              {que.questionType === EQuestionTypes.PARAGRAPH && (
                <FormControl>
                  <TextField
                    label={null}
                    placeholder="Your answer"
                    variant="standard"
                    margin="normal"
                    size="small"
                    multiline
                    value={formState[index][EQuestionTypes.PARAGRAPH]}
                    onChange={(e) => {
                      setErrorState({
                        ...errorState,
                        [que.questionId]: {
                          error: "",
                        },
                      });
                      formStateChangeHandler(
                        e.target.value,
                        EQuestionTypes.PARAGRAPH,
                        index
                      );
                    }}
                    error={
                      errorState[que.questionId]?.error
                        ? Boolean(errorState[que.questionId].error)
                        : false
                    }
                    helperText={
                      errorState[que.questionId]?.error
                        ? "Field is required"
                        : ""
                    }
                    onBlur={() => {
                      if (
                        que.isRequired &&
                        formState[index][EQuestionTypes.PARAGRAPH].trim() === ""
                      ) {
                        setErrorState({
                          ...errorState,
                          [que.questionId]: {
                            error: true,
                          },
                        });
                      }
                    }}
                  />
                </FormControl>
              )}
              {que.questionType === EQuestionTypes.MULTIPLE_CHOICE && (
                <RadioGroup
                  defaultValue={
                    que.isRequired ? que.questionOptions[0].optionsText : ""
                  }
                  name={`${que.questionText}_${que.questionType}`}
                  sx={{ mt: 2 }}
                  value={formState[index][EQuestionTypes.MULTIPLE_CHOICE]}
                  onChange={(e) =>
                    formStateChangeHandler(
                      e.target.value,
                      EQuestionTypes.MULTIPLE_CHOICE,
                      index
                    )
                  }
                >
                  {que.questionOptions.map((opt, i) => (
                    <FormControlLabel
                      value={opt.optionsText}
                      control={<Radio size="small" />}
                      label={opt.optionsText}
                    />
                  ))}
                </RadioGroup>
              )}
              {que.questionType === EQuestionTypes.CHECKBOXES && (
                <FormGroup sx={{ mt: 2 }}>
                  {que.questionOptions.map((opt, i) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={formState[index][
                            EQuestionTypes.CHECKBOXES
                          ].includes(opt.optionsText)}
                          onChange={(e) =>
                            handleCheckboxChange(
                              e.target.checked,
                              opt.optionsText,
                              index
                            )
                          }
                        />
                      }
                      label={opt.optionsText}
                    />
                  ))}
                </FormGroup>
              )}
              {que.questionType === EQuestionTypes.DROPDOWN && (
                <FormControl sx={{ width: "30%" }}>
                  <TextField
                    size="small"
                    select
                    label={null}
                    variant="outlined"
                    margin="normal"
                    defaultValue="Choose Options"
                    value={formState[index][EQuestionTypes.DROPDOWN]}
                    onChange={(e) => {
                      setErrorState({
                        ...errorState,
                        [que.questionId]: {
                          error: "",
                        },
                      });
                      formStateChangeHandler(
                        e.target.value,
                        EQuestionTypes.DROPDOWN,
                        index
                      );
                    }}
                    error={
                      errorState[que.questionId]?.error
                        ? Boolean(errorState[que.questionId].error)
                        : false
                    }
                    helperText={
                      errorState[que.questionId]?.error
                        ? "Please Choose a option"
                        : ""
                    }
                    onBlur={() => {
                      if (
                        que.isRequired &&
                        formState[index][EQuestionTypes.DROPDOWN].trim() ===
                          "Choose Options"
                      ) {
                        setErrorState({
                          ...errorState,
                          [que.questionId]: {
                            error: true,
                          },
                        });
                      }
                    }}
                  >
                    <MenuItem value={"Choose Options"} sx={{ opacity: 0.6 }}>
                      Choose Options
                    </MenuItem>
                    {que.questionOptions.map((opt, i) => (
                      <MenuItem key={i} value={opt.optionsText}>
                        {opt.optionsText}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              )}
            </Stack>
          ))}
        </Stack>
      </Grid>
      <Grid container item xs={12} sm={12} md={10} lg={8} p={2}>
        <Stack direction={"row"} width="100%" justifyContent={"space-between"}>
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
          <Button variant="outlined" onClick={handleClear}>
            Clear
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default PreviewForm;
