const workspacesReducer = (state: Workspace.State, action: Workspace.Action): Workspace.State => {
  const copy = { ...state };
  switch (action.type) {
    case "SET_ACTIVE_WORKSPACE":
      copy.active_workspace = action.payload as object;
      return copy;
    case "SET_WORKSPACES":
      copy.list = action.payload as any[];
      return copy;
    case "SET_USER_LEGAL_COMPANIES":
      copy.user_legal_company = action.payload as any[];
      return copy;
    case "SET_USER_ORGANIZATIONS":
      copy.user_organizations = action.payload as any[];
      return copy;
    case "SET_WORKSPACE_TEAM_MEMBERS":
      copy.workspace_team_members = action.payload as any[];
      return copy;
    case "SET_WORKSPACE_MEMBERS":
      copy.workspace_members = action.payload as any[];
      return copy;
    case "SET_WORKSPACE_OWNER":
      copy.is_workspace_owner = action.payload as boolean;
      return copy;
    case "SET_WORKSPACE_ADMIN":
      copy.is_workspace_admin = action.payload as boolean;
      return copy;
    default:
      return state;
  }
};

export default workspacesReducer;
