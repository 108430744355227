import { makeStyles } from "@mui/styles";

export const useStyles: any = makeStyles(() => ({
  commonView: {
    background: "white",
    borderRadius: "10px",
    width: "100%",
    padding: "24px",
    borderTop: "8px solid lightblue",
  },
  commonQuestionView: {
    background: "white",
    borderRadius: "10px",
    width: "100%",
    padding: "24px",
    borderLeft: "8px solid lightblue",
    flex: 1,
  },
  commonQuestionViewNotOpen: {
    background: "white",
    borderRadius: "10px",
    width: "100%",
    padding: "24px",
    flex: 1,
  },
}));
