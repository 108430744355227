import { Box, Skeleton } from "@mui/material";

const WorkspacesPreferencesSkeleton = () => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={2} width={"100%"}>
      <Box display={"flex"} gap={2.5} alignItems={"center"}>
        <Skeleton variant="rounded" animation={"wave"} sx={{ width: "35px", height: "35px" }} />
        <Skeleton variant="rounded" animation={"wave"} sx={{ height: "30px", width: "200px" }} />
      </Box>
      <Box display={"flex"} gap={1.5} flexDirection={"column"}>
        <Box display={"flex"} gap={2}>
          <Skeleton variant={"rounded"} animation={"wave"} sx={{ width: "92px", height: "48px" }} />
          <Skeleton variant={"rounded"} animation={"wave"} sx={{ width: "92px", height: "48px" }} />
          <Skeleton variant={"rounded"} animation={"wave"} sx={{ width: "92px", height: "48px" }} />
        </Box>
        <Box display={"flex"} gap={2} flexDirection={"column"}>
          <Box display={"flex"} gap={0.5} flexDirection={"column"} alignItems={"flex-start"}>
            <Skeleton variant="text" animation={"wave"} sx={{ height: "24px", width: "170px" }} />
            <Box display={"flex"} gap={1} justifyContent={"center"} alignItems={"center"}>
              <Skeleton variant="circular" animation={"wave"} sx={{ height: "40px", width: "40px" }} />
              <Box display={"flex"} flexDirection={"column"}>
                <Skeleton variant="text" animation={"wave"} sx={{ height: "21px", width: "170px" }} />
                <Skeleton variant="text" animation={"wave"} sx={{ height: "21px", width: "170px" }} />
                <Skeleton variant="text" animation={"wave"} sx={{ height: "21px", width: "170px" }} />
              </Box>
            </Box>
          </Box>
          <Box gap={1.8} sx={{ border: "1px solid rgba(255, 255, 255, 0.12)", borderRadius: "4px", padding: "5px", width: "100%", height: "500px" }} display={"flex"} flexDirection={"column"}>
            <Box display={"flex"} gap={1} mb={1.5}>
              <Skeleton variant="rounded" animation={"wave"} sx={{ height: "32.5px", width: "132px" }}></Skeleton>
              <Skeleton variant="rounded" animation={"wave"} sx={{ height: "32.5px", width: "132px" }}></Skeleton>
            </Box>
            <Box display={"flex"} gap={10}>
              <Skeleton variant={"rounded"} animation={"wave"} sx={{ height: "35px", width: "130px" }} />
              <Skeleton variant={"rounded"} animation={"wave"} sx={{ height: "35px", width: "130px" }} />
              <Skeleton variant={"rounded"} animation={"wave"} sx={{ height: "35px", width: "130px" }} />
              <Skeleton variant={"rounded"} animation={"wave"} sx={{ height: "35px", width: "130px" }} />
              <Skeleton variant={"rounded"} animation={"wave"} sx={{ height: "35px", width: "130px" }} />
              <Skeleton variant={"rounded"} animation={"wave"} sx={{ height: "35px", width: "130px" }} />
              <Skeleton variant={"rounded"} animation={"wave"} sx={{ height: "35px", width: "130px" }} />
            </Box>
            <Box>
              <Skeleton variant="text" animation={"wave"} sx={{ height: "48px", width: "100%" }} />
              <Skeleton variant="text" animation={"wave"} sx={{ height: "48px", width: "100%" }} />
              <Skeleton variant="text" animation={"wave"} sx={{ height: "48px", width: "100%" }} />
              <Skeleton variant="text" animation={"wave"} sx={{ height: "48px", width: "100%" }} />
              <Skeleton variant="text" animation={"wave"} sx={{ height: "48px", width: "100%" }} />
              <Skeleton variant="text" animation={"wave"} sx={{ height: "48px", width: "100%" }} />
              <Skeleton variant="text" animation={"wave"} sx={{ height: "48px", width: "100%" }} />
              <Skeleton variant="text" animation={"wave"} sx={{ height: "48px", width: "100%" }} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WorkspacesPreferencesSkeleton;
