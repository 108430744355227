import { Grid, Typography, Avatar, List, Divider, ListItem, ListItemText, ListItemAvatar, Box } from "@mui/material";
import { useStyles } from "./styles";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import ChatBubbleIcon from "../../../../assets/icon-components/ChatBubbleIcon";

const ListChats = (props: any) => {
  const classes = useStyles();

  return (
    <Grid className={classes.chatListContainer}>
      <ChatBubbleIcon />
      <Typography variant="opacity70-12px" ml={0.5}>
        All Messages
      </Typography>
      {props.chatList?.map((item: any) => {
        return (
          <List className={classes.listContainer} key={item.id}>
            <ListItem
              onClick={() => {
                props.handleChat(item);
              }}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ListItemAvatar>
                <Avatar alt={item?.name} src={item?.profileUrl} />
              </ListItemAvatar>
              <ListItemText
                sx={{
                  width: "100%",
                }}
                primary={<Typography variant="opacity70">{item?.name}</Typography>}
                secondary={
                  <Box className={classes.messageStyle}>
                    {item?.lastMessage?.messageType === "TEXT" ? (
                      <Typography component="span" variant="caption" sx={{ color: "opacity75", overflow: "hidden" }}>
                        {item?.lastMessage?.msg}
                        {/* {_.truncate(item?.lastMessage?.msg)} */}
                      </Typography>
                    ) : item?.lastMessage?.messageType === "IMAGE" ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <InsertPhotoIcon sx={{ fontSize: "20px", color: "opacity75" }} />
                        <Typography
                          component="span"
                          variant="caption"
                          sx={{
                            color: "opacity75",
                            marginTop: 0.5,
                            paddingLeft: 0.5,
                          }}
                        >
                          Image
                        </Typography>
                      </Box>
                    ) : item?.lastMessage?.messageType === "VIDEO" ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <VideoCameraBackIcon sx={{ fontSize: "20px", color: "opacity75" }} />
                        <Typography
                          component="span"
                          variant="caption"
                          sx={{
                            color: "opacity75",
                            marginTop: 0.5,
                            paddingLeft: 0.5,
                          }}
                        >
                          Video
                        </Typography>
                      </Box>
                    ) : null}
                  </Box>
                }
              />
              <ListItemText
                sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}
                primary={
                  <Typography variant="caption" sx={{ color: "opacity75" }}>
                    {item?.timestamp}
                  </Typography>
                }
                secondary={
                  item?.unreadCount > 0 && (
                    <Box className={classes.unreadMsz}>
                      <Typography color="#fff">{item?.unreadCount}</Typography>
                    </Box>
                  )
                }
              />
            </ListItem>
            <Divider light />
          </List>
        );
      })}
    </Grid>
  );
};

export default ListChats;
