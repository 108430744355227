import { SvgIcon, SvgIconProps } from "@mui/material";

const FeedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 14" xmlns="http://www.w3.org/2000/svg">
      <rect width={8.88889} height={2.66667} rx={0.5} />
      <rect x={10.6665} width={5.33333} height={2.66667} rx={0.5} />
      <rect y={4.44446} width={3.55556} height={2.66667} rx={0.5} />
      <rect x={5.3335} y={4.44446} width={10.6667} height={2.66667} rx={0.5} />
      <rect y={8.88892} width={8} height={2.66667} rx={0.5} />
    </SvgIcon>
  );
};

export default FeedIcon;
