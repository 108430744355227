import * as React from "react";
import { SvgIconProps } from "@mui/material";
const ChatBubbleIcon = (props: SvgIconProps) => (
  <svg width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13 7C13 10.3137 10.3137 13 7 13C5.81929 13 4.71824 12.659 3.78998 12.07L1 13L1.92999 10.21C1.34104 9.28176 1 8.18071 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7Z"
      fill="#494A50"
      stroke="#494A50"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ChatBubbleIcon;
