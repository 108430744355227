import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  messageBox: {
    // width: "90%",
    // backgroundColor: "rgba(0, 0, 0, 0.2)",
    // borderRadius: 6,
    // color: "opacity75 !important",
    "& label.Mui-focused": {
      color: "rgba(255, 255, 255, 0.9) !important",
    },
    "& .MuiInput-underline:after": {
      // borderColor: "rgba(255, 255, 255, 0.2)",
      border: "none",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
  },
  sendBtnContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: {
    color: "opacity75 !important",
  },
  sendIcon: {
    padding: "4px 6px",
    background: "rgb(61,172,255)",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
  },
});
